import Promise from 'bluebird';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import {
  Chip,
} from '@material-ui/core';

import {
  BackIcon,
  NextIcon,
  PlayIcon,
  PrevIcon,
  StopIcon,
} from './CommonIcons';
import TouchableIconButton from './TouchableIconButton';

const cutDecimalPlaces = (v, placesLeft = 5) => {
  if (!v) return v;
  const s = `${v}`;
  if (s.indexOf('.') < 0) return v;
  return +`${s.split('.')[0]}.${s.split('.')[1].substr(0, placesLeft)}`;
};

const VideoPlayback = props => {
  const { frames, fps, media } = props;
  const [image, setImage] = useState(frames[0]);
  const [runSeq, setRunSeq] = useState(0);
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (!frames[runSeq]) return;

    setImage(frames[runSeq]);

    if (run) {
      // check if next frame is available
      if (!frames[runSeq + 1]) {
        setRun(false);
        return;
      }

      setTimeout(() => {
        setRunSeq(s => s + 1);
      }, ~~(1000 / fps)); // eslint-disable-line no-bitwise
    }
  }, [runSeq, run]);

  const handleClickPlay = () => {
    setRun(true);
  };

  const handleClickStop = () => {
    setRun(false);
  };

  const handleClickBack = () => {
    setRun(false);
    setRunSeq(0);
  };

  const handleClickPrev = () => {
    setRun(false);
    setRunSeq(s => s - 1);
  };

  const handleClickNext = () => {
    setRun(false);
    setRunSeq(s => s + 1);
  };

  return <>
    <img src={image} style={{ width: '100%' }} />
    <br />
    <TouchableIconButton onClick={handleClickBack}>
      <BackIcon />
    </TouchableIconButton>
    <TouchableIconButton onClick={handleClickPrev} disabled={runSeq === 0}>
      <PrevIcon />
    </TouchableIconButton>
    {!run && <TouchableIconButton onClick={handleClickPlay}>
      <PlayIcon />
    </TouchableIconButton>}
    {run && <TouchableIconButton onClick={handleClickStop}>
      <StopIcon />
    </TouchableIconButton>}
    {<TouchableIconButton onClick={handleClickNext} disabled={!frames[runSeq + 1]}>
      <NextIcon />
    </TouchableIconButton>}
    <Chip label={`Frame #${runSeq}`} />&nbsp;
    {media.minimumConfidences && media.maximumConfidences
      ? <Chip label={`Confidence [${cutDecimalPlaces(media.minimumConfidences[runSeq])}, ${cutDecimalPlaces(media.maximumConfidences[runSeq])}]`} />
      : false}
  </>;
};

export default VideoPlayback;
