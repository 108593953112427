export default (state = [], action) => {
  let modified;
  let message;

  // console.debug('elementReducer');
  switch (action.type) {
    case 'elements/set':
      // console.debug('element set');
      return [...action.elements];

    case 'elements/setMetadata':
      modified = state.find(r => r.id === action.id); // clone as well
      modified.metadata = { ...action.metadata };
      // debugger; // eslint-disable-line no-debugger

      // TODO: something wrong with the ReactFlow re-rendering using the same key
      return state;

      // return [
      //   ...state.filter(r => r.id !== action.receipientId),
      //   modified,
      // ];

    case 'elements/add':
      // console.debug('element add');
      return [...state, action.element];

    case 'elements/remove':
      // console.debug('element remove');
      // debugger; // eslint-disable-line no-debugger
      return [...state.filter(r => !action.elementsToRemove.map(x => x.id).includes(r.id))];

    case 'elements/message':
      // console.debug('element message');
      // debugger; // eslint-disable-line no-debugger
      modified = { ...state.find(r => r.id === action.receipientId) }; // clone as well
      message = {
        id: action.id,
        senderId: action.senderId,
        data: action.data,
      };

      modified.messages = [
        ...[modified.messages || []],
        message,
      ];

      return [
        ...state.filter(r => r.id !== action.receipientId),
        modified,
      ];
    default:
      return state;
  }
};
