export default (state = [], action) => {
  // console.debug('workerReducer');
  switch (action.type) {
    case 'workers/set':
      // console.debug('worker set', action.workers);
      return [...action.workers];
    default:
      return state;
  }
};
