/* eslint no-console:0 no-debugger:0 */

import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Checkbox,
  CssBaseline,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { getAuthDataAsync } from '../Blocks/LocalStorage';

const styles = theme => ({
  root: {
    // display: 'flex',
    // height: '100%',
    flexGrow: 1,
  },
  formControl: {
    width: '100%',
    // margin: theme.spacing(1),
  },
  formControlLabel: {
    margin: theme.spacing(-1) / 2,
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      color: theme.hrefLink.textColor,
      textDecoration: 'underline',
    },
    '&:visited': {
      color: theme.hrefLink.textColor,
      textDecoration: 'underline',
    },
  },
});

const RegisterForm = props => {
  const { classes, onChange } = props;
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [department, setDepartment] = useState('');
  const [contact, setContact] = useState('');
  const [author, setAuthor] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');

  useEffect(() => {
    (async () => {
      const user = await getAuthDataAsync();
      setFamilyName(user.familyName);
      setGivenName(user.givenName);
      setEmail(user.email);
    })();
  }, []);

  useEffect(() => {
    onChange({
      familyName,
      givenName,
      organization,
      department,
      contact,
      author,
      agreed,
      secondaryEmail,
    });
  }, [familyName, givenName, organization, department, contact, author, agreed, secondaryEmail]);

  const setAuthorWithValidation = newAuthorName => {
    newAuthorName = newAuthorName.replace(/[^A-Za-z0-9]/ig, '').toLowerCase();
    setAuthor(newAuthorName);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <Typography>Welcome to EdgeAI Studio. To start with the Community (Free) version, please sign up.</Typography>
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="First name*"
              variant="outlined"
              value={givenName}
              onChange={e => setGivenName(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Last name*"
              variant="outlined"
              value={familyName}
              onChange={e => setFamilyName(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              disabled
              label="Email*"
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value)} />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Secondary email"
              variant="outlined"
              value={secondaryEmail}
              onChange={e => setSecondaryEmail(e.target.value)} />
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Organization name*"
              variant="outlined"
              value={organization}
              onChange={e => setOrganization(e.target.value)} />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Department"
              variant="outlined"
              value={department}
              onChange={e => setDepartment(e.target.value)} />
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Contact number*"
              variant="outlined"
              value={contact}
              onChange={e => setContact(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Author name* (without spaces and special characters)"
              variant="outlined"
              value={author}
              onChange={e => setAuthorWithValidation(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={agreed}
              onChange={e => setAgreed(e.target.checked)}
            />}
            label={<>
              I agree with&nbsp;
              <Link className={classes.link} color="textPrimary" href={'//docs.cnr.ai/legal/terms-of-service.html'} target="_blank">
                Terms of Service
              </Link> and&nbsp;
              <Link className={classes.link} color="textPrimary" href={'//docs.cnr.ai/legal/acceptable-use-policy.html'} target="_blank">
                Acceptable Use Policy
              </Link>.
            </>}
            className={classes.formControlLabel}
          />
        </Grid>
      </Grid>
      <Grid container>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(RegisterForm);
