import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// @material-ui/icons
import WifiIcon from '@material-ui/icons/Wifi';
import HistoryIcon from '@material-ui/icons/History';
import SimCardIcon from '@material-ui/icons/SimCard';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import StorageIcon from '@material-ui/icons/Storage';
// core components
import GridContainer from './dashboard/Grid/GridContainer';
// Dashboard Components
import Grids from './dashboard/Grids';
import Graph from './dashboard/Graph';
// Styling
import dashboardStyle from './dashboard/assets/jss/material-dashboard-react/views/dashboardStyle';

// Config
const dataPoints = 120;
const updateIntervalInMs = 1000;

const localState = {
  statistics: {
    allIpAddress: { wifiAddress: [], lanAddress: [], cellularAddress: [] },
    cpuUsage: '',
    memoryUsage: '',
    timeExecuted: '',
    diskUsage: '',
    version: '',
  },
  cpuData: {
    labels: Array(dataPoints).fill(''),
    series: [Array(dataPoints).fill(0)],
  },
  memoryData: {
    labels: Array(dataPoints).fill(''),
    series: [Array(dataPoints).fill(0)],
  },
};

const styles = theme => ({
  content: {
  },
  ...dashboardStyle,
});

const clone = json => JSON.parse(JSON.stringify(json));

class StatForm extends React.Component {
  constructor(props) {
    super();

    this.state = clone(localState);
  }

  // TODO: getStatInfo does not run in background
  // TODO: when the app is restarted, the statistics are gone
  async getStatInfo() {
    try {
      const url = 'http://127.0.0.1:8090/api/v1/statistics';
      const res = await fetch(url, { method: 'GET' });
      const json = await res.json();
      const cpuData = this.renderGraphData('cpuData', ~~this.state.statistics.cpuUsage); // eslint-disable-line no-bitwise
      const memoryData = this.renderGraphData('memoryData', this.roundData(this.state.statistics.memoryUsage));

      localState.statistics = json;
      localState.cpuData = cpuData;
      localState.memoryData = memoryData;

      this.setState(clone(localState));
    }
    catch (e) {
      console.error(e);
      // we just skip if fail
    }
  }

  renderGraphData = (label, newData) => ({
    series: [
      [...this.state[label].series[0], newData].reverse().slice(0, dataPoints).reverse(),
    ],
  });

  componentDidMount() {
    this.interval = setInterval(async () => {
      try {
        await this.getStatInfo();
      }
      catch (e) {
        console.error(e);
      }
    }, updateIntervalInMs);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  roundData = (data, decimal = 2) => {
    if (data === 0) return 0;
    if (typeof data === 'string' && data.includes('MB')) data = Number(data.slice(0, -2));
    // Round figure to nearest 2 dp
    return decimal === 2 ? Math.round(data * 100) / 100 : Math.round(data);
  };

  handleIpAddress = ipAddresses => {
    if (ipAddresses.length === 1) {
      return ipAddresses;
    }
    if (ipAddresses.length === 0) {
      return ' - ';
    }

    return ipAddresses.join(' / ');
  };

  roundDiskUsage = mb => {
    if (mb === 0) return 0;
    const t = Math.floor(mb / (1024 * 1024));
    const g = Math.floor(mb % (1024 * 1024) / 1024);
    // Rather rounding up than rounding down
    const m = Math.ceil(mb % 1024);
    const tDisplay = t > 0 ? `${t} TB ` : '';
    const gDisplay = g > 0 ? `${g} GB ` : '';
    const mDisplay = m > 0 ? `${m} MB` : '';
    return tDisplay + gDisplay + mDisplay;
  }

  secondsToDhms = seconds => {
    if (seconds === 0) return 0;
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? `${d}d ` : '';
    const hDisplay = h > 0 ? `${h}h ` : '';
    const mDisplay = m > 0 ? `${m}m ` : '';
    const sDisplay = s > 0 ? `${s}s` : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };

  render() {
    const { classes } = this.props;
    const { statistics, cpuData, memoryData } = this.state;

    return (
      <div className={classes.content}>
        <GridContainer>
          <Grids
            md={4}
            color="warning"
            label="Wi-Fi IP Address"
            classes={classes}
            data={this.handleIpAddress(statistics.allIpAddress.wifiAddress)}
          >
            <WifiIcon />
          </Grids>
          <Grids
            md={4}
            color="success"
            label="Ethernet IP Address"
            classes={classes}
            data={this.handleIpAddress(statistics.allIpAddress.lanAddress)}
          >
            <SettingsEthernetIcon />
          </Grids>
          <Grids
            md={4}
            color="gray"
            label="Cellular IP Address"
            classes={classes}
            data={this.handleIpAddress(statistics.allIpAddress.cellularAddress)}
          >
            <SimCardIcon />
          </Grids>
        </GridContainer>
        <GridContainer>
          <Grids
            md={6}
            color="info"
            label="Disk Usage"
            classes={classes}
            data={this.roundDiskUsage(statistics.diskUsage)}
          >
            <StorageIcon />
          </Grids>
          <Grids
            md={6}
            color="danger"
            label="Time Executed"
            classes={classes}
            data={this.secondsToDhms(statistics.timeExecuted)}
          >
            <HistoryIcon />
          </Grids>
        </GridContainer>
        <GridContainer>
          <Graph
            md={6}
            color="success"
            classes={classes}
            data={cpuData}
            label="CPU (%)"
          />
          <Graph
            md={6}
            color="warning"
            classes={classes}
            data={memoryData}
            label="Memory (MB)"
          />
        </GridContainer>
      </div>
    );
  }
}

StatForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatForm);
