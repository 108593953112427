/* eslint no-console:0 no-debugger:0 */

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import UnfavoriteIcon from '@material-ui/icons/FavoriteBorder';
import TickIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Star1Icon from '@material-ui/icons/Star';
import Star0Icon from '@material-ui/icons/StarBorder';
import Star05Icon from '@material-ui/icons/StarHalf';
import ApiIcon from '@material-ui/icons/ThumbUp';
import SizeIcon from '@material-ui/icons/SdCard';
import PriceIcon from '@material-ui/icons/MonetizationOn';

import ModelLoader from '../Blocks/ModelLoader';
import { getModelsAsync } from './EdgeAiAppInterface';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100%',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'left',
    backgroundColor: '#303030',
    padding: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const modelType = {
  'object-detector': <>
    Object detector
  </>,
  ocr: 'OCR',
  pose: 'Pose',
};

const Model2Form = props => {
  const { confirm, alert } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [topModelNames, setTopModelNames] = React.useState(['object', 'ocr', 'pose']);
  const [models, setModels] = React.useState([]);
  const defaultModels = [
    {
      name: 'Default',
      type: 'object-detector',
      description: <>
        <Typography>
          This model detects 64 different objects and returns bounding boxes with confidence level.
        </Typography>
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star05Icon /></>} label="4.8" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='250 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'1M - 10M'} />
      </>,
      format: 'CoreML',
      url: null,
      developer: 'C&R Wise AI',
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
        size: '250 MB',
      },
      favorite: true,
      added: true,
      imagePath: '/static/images/default.jpeg',
    },
    {
      name: 'Chinese OCR',
      type: 'ocr',
      description: <>
        This model recognizes text strings and returns bounding boxes with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star1Icon /></>} label="5.0" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='250 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'10M - 100M'} />
      </>,
      format: 'CoreML',
      url: null,
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
      },
      favorite: false,
      added: false,
      parameters: {
        language: 'Support multiple languages',
      },
      imagePath: '/static/images/ocr.jpg',
    },
    {
      name: 'Pose',
      type: 'pose',
      description: <>
        This model recognizes 17 keypoints in multiple human poses with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star1Icon /></>} label="5.0" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='100 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'10M - 100M'} />
      </>,
      format: 'CoreML',
      url: null,
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
      },
      favorite: false,
      added: false,
      parameters: {
        language: 'Support multiple languages',
      },
      imagePath: '/static/images/pose.jpg',
    },
  ];

  useEffect(() => {
    (async () => {
      // setModels(await getModelsAsync());
      setModels(defaultModels);
    })();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderCard = model => (
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {model.name}
          </Avatar>
        }
        action={<></>
          // <IconButton aria-label="settings">
          //   <MoreVertIcon />
          // </IconButton>
        }
        title={model.name}
        subheader={modelType[model.type]}
      />
      <CardMedia
        className={classes.media}
        image={model.imagePath}
        title="model"
      />
      <CardContent>
        {model.description}
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          {model.favorite ? <FavoriteIcon /> : <UnfavoriteIcon />}
        </IconButton>
        <IconButton aria-label="share">
          {model.added ? <TickIcon /> : <AddIcon />}
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card >
  );

  const renderSearchBar = () => (
    <Autocomplete
      freeSolo
      disableClearable
      options={topModelNames}
      renderInput={params => (
        <TextField
          {...params}
          label="Search keywords"
          margin="normal"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  );

  const renderUpload = () => (
    <ModelLoader color="red" confirm={confirm} alert={alert} />
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Models</Typography>
        </Grid>
      </Grid>

      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderSearchBar()}
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        {models.map((model, i) => (
          <Grid key={`model-${i}`} item xs={3}>
            {renderCard(model)}
          </Grid>
        ))}
        <Grid key='new' item xs={3}>
          {renderUpload()}
        </Grid>
      </Grid>

    </div>
  );
};

export default Model2Form;
