import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import IntroductionForm from './IntroductionForm';

const styles = theme => ({
  content: {
    width: '100%',
    height: '100%',
  },
});

const IntroductionMain = props => {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={clsx(classes.content)}>
        <IntroductionForm />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(IntroductionMain);
