function isIOS() {
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent) || navigator.maxTouchPoints > 1;
}

function isChrome() {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

export {
  isIOS,
  isChrome,
};
