/* eslint no-console:0 no-debugger:0 */

import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import fetch from 'node-fetch';

import {
  ExpandMoreIcon,
  SaveIcon,
} from '../Blocks/CommonIcons';
import { getAuthDataAsync, setAuthDataAsync } from '../Blocks/LocalStorage';
import { efetch } from './ApiServerInterface';

const styles = theme => ({
  root: {
    // display: 'flex',
    height: '100%',
    // width: `calc(100% - ${theme.spacing(2)}px)`,
    // padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '20%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  gridRoot: {
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.info.contrastText,
  },
});

const AccountForm = props => {
  const { classes, alert, confirm } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [email, setEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [department, setDepartment] = useState('');
  const [contact, setContact] = useState('');
  const [author, setAuthor] = useState('');
  const [key, setKey] = useState('');
  const [isGuest, setIsGuest] = useState(false);
  const [plan, setPlan] = useState('free');

  useEffect(() => {
    (async () => {
      const authData = await getAuthDataAsync();
      console.debug(JSON.stringify(authData, null, 2));
      setEmail(authData.email);
      setFamilyName(authData.familyName);
      setGivenName(authData.givenName);
      setOrganization(authData.organization);
      setDepartment(authData.department);
      setSecondaryEmail(authData.secondaryEmail);
      setContact(authData.contact);
      setAuthor(authData.author);
      setKey(authData.key);
      setIsGuest(authData.isGuest);
      setPlan(authData.plan);
    })();
  }, []);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickSave = async () => {
    if (!assertRegistration()) return;

    const res = await efetch('/api/v1/register', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email, familyName, givenName, organization, department, secondaryEmail, contact,
      }),
    });

    const r = await res.json();

    if (r.success) {
      alert('Saved successfully.', 'success');
      let authData = await getAuthDataAsync();
      authData = {
        ...authData,
        familyName,
        givenName,
        organization,
        department,
        secondaryEmail,
        contact,
      };
      await setAuthDataAsync(authData);
      return;
    }

    alert('Settings cannot be saved successfully.  Please check error logs.', 'error');
  };

  const assertRegistration = () => {
    if (!givenName || givenName.trim().length === 0) {
      alert('Please input first name.');
      return false;
    }
    if (!familyName || familyName.trim().length === 0) {
      alert('Please input last name.');
      return false;
    }
    if (!organization || organization.trim().length === 0) {
      alert('Please input organization name.');
      return false;
    }
    if (!contact || contact.trim().length === 0) {
      alert('Please input contact number.');
      return false;
    }
    if (!author || author.trim().length === 0) {
      alert('Please input author name.');
      return false;
    }
    if (!/^[a-zA-Z0-9]+$/.test(author)) {
      alert('Author name can only comprise of alphabets and numbers only. Please try another one.');
      return false;
    }

    return true;
  };

  const renderAppBar = () => (
    <AppBar position="static" >
      <Toolbar style={{ paddingLeft: 8 }}>
        {
          isGuest
            ? <IconButton disabled>
                <SaveIcon color="disable"/>
              </IconButton>
            : <IconButton onClick={handleClickSave}>
                <SaveIcon className={classes.icon}/>
              </IconButton>
        }
      </Toolbar>
    </AppBar >
  );

  const getPlanDescription = () => {
    let output = plan;
    switch (plan) {
      case 'business':
        output = 'Studio Business';
        break;
      case 'free':
        output = 'Studio Free';
        break;
      default:
        break;
    }
    return output;
  };

  return (
    <div className={classes.root} style={{ textAlign: 'left' }}>
      {renderAppBar()}
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{
            flexGrow: 1,
          }}>
            {renderAppBar()}
          </div>
        </Grid>
      </Grid> */}

      <Grid className={classes.gridRoot} container >
        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Name</Typography>
              <Typography className={classes.secondaryHeading}>{givenName} {familyName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.heading}>&nbsp;</Typography>
              <Typography className={classes.secondaryHeading}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled={isGuest}
                        label="First name*"
                        variant="outlined"
                        value={givenName}
                        onChange={e => setGivenName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled={isGuest}
                        label="Last name*"
                        variant="outlined"
                        value={familyName}
                        onChange={e => setFamilyName(e.target.value)} />
                    </FormControl>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Organization</Typography>
              <Typography className={classes.secondaryHeading}>{[department, organization].filter(r => !!r).join(', ')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.heading}>&nbsp;</Typography>
              <Typography className={classes.secondaryHeading}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled={isGuest}
                        label="Organization name*"
                        variant="outlined"
                        value={organization}
                        onChange={e => setOrganization(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled={isGuest}
                        label="Department"
                        variant="outlined"
                        value={department}
                        onChange={e => setDepartment(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled={isGuest}
                        label="Contact number*"
                        variant="outlined"
                        value={contact}
                        onChange={e => setContact(e.target.value)} />
                    </FormControl>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className={classes.heading}>Account settings</Typography>
              <Typography className={classes.secondaryHeading}>{email}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.heading}>&nbsp;</Typography>
              <Typography className={classes.secondaryHeading}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled
                        label="Email"
                        variant="outlined"
                        value={email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled={isGuest}
                        label="Secondary email"
                        variant="outlined"
                        value={secondaryEmail}
                        onChange={e => setSecondaryEmail(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled
                        label="Author name"
                        variant="outlined"
                        value={author}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>Subscription</Typography>
              <Typography className={classes.secondaryHeading}>{getPlanDescription()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.heading}>&nbsp;</Typography>
              <Typography className={classes.secondaryHeading}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        disabled
                        label="API Key"
                        variant="outlined"
                        value={key}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <br />
                Please contact sales@cnr.ai if you want to upgrade.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AccountForm);
