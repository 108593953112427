import uuid from 'uuid';

export const addElement = element => dispatch => {
  dispatch({
    type: 'elements/add',
    element,
  });
};

export const removeElements = elementsToRemove => dispatch => {
  dispatch({
    type: 'elements/remove',
    elementsToRemove,
  });
};

export const setElements = (elements = []) => dispatch => {
  dispatch({
    type: 'elements/set',
    elements,
  });
};

export const messageElement = (myId, targetId, data) => dispatch => {
  dispatch({
    type: 'elements/message',
    id: uuid.v4(),
    senderId: myId,
    receipientId: targetId,
    data,
  });
};

export const setElementMetadata = (id, metadata) => dispatch => {
  dispatch({
    type: 'elements/setMetadata',
    id,
    metadata,
  });
};
