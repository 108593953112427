import React, {
  useState,
  useRef,
  useEffect,
  Suspense,
} from 'react';
import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import fetch from 'node-fetch';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Backdrop,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Input,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import uuid from 'uuid';
import {
  BarChartIcon,
  Brightness4Icon,
  Brightness7Icon,
  ChevronLeftIcon,
  CrownIcon,
  DashboardIcon,
  DiscordBlackIcon,
  DiscordWhiteIcon,
  DocIcon,
  EventIcon,
  HelpIcon,
  LiveViewIcon,
  MapIcon,
  MediaIcon,
  MenuIcon,
  PipelinesIcon,
  ScreenOffIcon,
  SettingsIcon,
  SupervisorAccountIcon,
  TerminalIcon,
  WorkspacesIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
} from '../Blocks/CommonIcons';

import StatMain from './StatMain';
import Model2Main from './Model2Main';
import SettingsMain from './SettingsMain';
import EventMain from './EventMain';
import StudioDialog from './StudioDialog';
import WorkspaceMain from './WorkspaceMain';
import MediaMain from './MediaMain';
import LiveViewMain from './LiveViewMain';
import {
  getAuthDataAsync,
  setAuthDataAsync,
  loadEnvironment,
} from '../Blocks/LocalStorage';
import Alert from './Alert';
import RegisterMain from './RegisterMain';
import AccountMain from './AccountMain';
import { getEndpointUrl } from './ApiServerInterface';
import TouchableIconButton from '../Blocks/TouchableIconButton';

import { enUS, zhCN, zhTW } from './Language';

const PipelineMain = React.lazy(() => import('./PipelineMain'));
const MapMain = React.lazy(() => import('../custom/mtr-adm-peoplecounting-fe/MapMain'));
const DashboardMain = React.lazy(() => import('../custom/mtr-adm-peoplecounting-fe/DashboardMain'));
const DashboardForTstMain = React.lazy(() => import('../custom/mtr-adm-peoplecounting-fe/DashboardForTstMain'));
const DetailChartMain = React.lazy(() => import('../custom/mtr-adm-peoplecounting-fe/DetailChartMain'));

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  statusBarCover: {
    position: 'fixed',
    top: -120, // TODO: Use actual statusBar height rather than a guesstimate
    height: 120,
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
  },
  appBar: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.appBarSpacing,
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonIcon: {
    // color: theme.palette.background.default,
  },
  menuButton: {
    marginRight: +theme.spacing(1.5),
    marginLeft: -theme.spacing(1.5),
    color: theme.palette.info.contrastText,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    height: theme.appBarSpacing,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    // padding: theme.spacing(3),
  },
  menuItem: {
    paddingLeft: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  typography: {
    color: theme.palette.themeColour,
  },
  pageToolBar: {
    backgroundColor: theme.palette.primary.main,
    height: `calc((100% - ${theme.appBarSpacing}) / 2)`,
  },
  crownIcon: {
    width: '16px',
    marginRight: '6px',
  },
  discordIcon: {
    width: '22px',
    height: '24px',
    color: 'black',
  },
  dropDownContainer: {
    color: theme.typography.themeColour,
    backgroundColor: theme.palette.primary.main,
  },
  track: {
    backgroundColor: theme.palette.primary.tertiary,
  },
});

function MiniDrawer(props) {
  const {
    classes,
    registered,
    onRegisterSuccess,
    onThemeChange,
  } = props;
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(null);
  const [notification, setNotification] = useState('');
  const pipelineIdRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarOnCloseCallbackRef = useRef(null);
  const [dialog, setDialog] = useState({
    title: null,
    message: null,
    type: 'yesno',
    onClick: () => { },
  });
  const registerProfileRef = useRef(null);
  const [name, setName] = useState(null);
  const [picture, setPicture] = useState(null);
  const [newlyLoaded, setNewlyLoaded] = useState(false);
  const [fileHandle, setFileHandle] = useState('studio.cnr.ai');
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('free');
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [currentMenuName, setCurrentMenuName] = useState(null);
  const [nameLetter, setNameLetter] = useState('');
  const history = useHistory();
  const [showViews, setShowViews] = useState([]);
  const [whiteLabel, setWhiteLabel] = useState('EdgeAI Studio');
  // alert message history in minidrawer
  const ackedAlertsRef = useRef([]);
  const [language, setLanguage] = useState('en-US');
  const [strings, setStrings] = useState(enUS);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const languageMap = {
      'en-US': enUS,
      'zh-CN': zhCN,
      'zh-TW': zhTW,
    };

    setStrings(languageMap[language]);
  }, [language]);

  useEffect(() => {
    if (!registered) {
      doRegister();
      return;
    }

    (async () => {
      let defaultView = 'discover';
      const { authData } = await loadEnvironment();

      setName(`${authData.givenName} ${authData.familyName}`.trim());
      setPicture(authData.picture);
      setNameLetter(authData.familyName.charAt(0).toUpperCase());
      setIsGuest(authData.isGuest);
      setSubscriptionPlan(authData.plan || 'free');
      const defaultViews = [
        'discover',
        'create',
        'media',
        'event',
        'settings',
      ];
      if (authData.key === 'ffc1960d-7c90-4705-8819-238f919449eb'
        || authData.key === '680bd2bf-399b-4c13-afbd-c06096544787') {
        setShowViews([...defaultViews, 'liveview']);
      }
      else if (authData.key === 'ffc1ef57-2220-45ab-991b-a808771d1d03') {
        const Helper = await import('../custom/mtr-adm-peoplecounting-fe/Helper');
        setWhiteLabel('EdgeAI Studio - ADM Passenger & Crowd Movement Monitoring System');
        const un = await Helper.default.getUsername();
        setUsername(un);
        const { ADMIN } = await Helper.default.getSettings();
        if (!ADMIN) {
          setShowViews([
            'discover',
            'map',
            'dashboard',
            'chart',
            'cloudflare',
            'repl',
            'settings',
          ]);
        }
        else {
          const isAdmin = ADMIN.includes(un);
          if (isAdmin) {
            setShowViews([
              'discover',
              'map',
              'dashboard',
              'chart',
              'cloudflare',
              'repl',
              'settings',
            ]);
          }
          else {
            setShowViews([
              'map',
              'dashboard',
              'chart',
            ]);
          }
        }
        defaultView = 'map';
        onThemeChange('light');
      }
      else if (authData.key === 'a17160c3-2bd5-49c8-a838-5a58a0403f55') {
        const Helper = await import('../custom/mtr-adm-peoplecounting-fe/Helper');
        setWhiteLabel('EdgeAI Studio - TST Passenger & Crowd Movement Monitoring System');
        const un = await Helper.default.getUsername();
        setUsername(un);
        const { ADMIN } = await Helper.default.getSettings();
        if (!ADMIN) {
          setShowViews([
            'discover-for-tst',
            'map',
            'map-zone-1',
            'map-zone-2',
            'map-zone-3',
            'dashboard-for-tst',
            'chart',
            'cloudflare',
            'repl',
            'settings',
          ]);
        }
        else {
          const isAdmin = ADMIN.includes(un);
          if (isAdmin) {
            setShowViews([
              'discover-for-tst',
              'map',
              'map-zone-1',
              'map-zone-2',
              'map-zone-3',
              'dashboard-for-tst',
              'chart',
              'cloudflare',
              'repl',
              'settings',
            ]);
          }
          else {
            setShowViews([
              'discover-for-tst',
              'map',
              'map-zone-1',
              'map-zone-2',
              'map-zone-3',
              'dashboard-for-tst',
              'chart',
              'cloudflare',
              'repl',
            ]);
          }
        }
        defaultView = 'map';
        onThemeChange('light');
      }
      else {
        setShowViews(defaultViews);
      }

      let id;
      if (document.location.pathname !== '/') {
        const [, path1, ...rest] = document.location.pathname.split('/');
        /* eslint-disable no-case-declarations */
        switch (path1) {
          case 'discover':
            setView('discover');
            break;
          case 'settings':
            setView('settings');
            break;
          case 'account':
            setView('account');
            break;
          case 'create':
            // http://localhost:3000/create
            // http://localhost:3000/create/13ca0ecd-443e-485f-95e4-dbd3d44f7ba6
            // http://localhost:3000/create/something-not-exists
            [id] = rest;
            if (id) {
              // Check for UUID format
              if (!(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id))) {
                // Invalid UUID
                id = uuid.v4();
              }
            } else {
              // UUID not specified, assign a new UUID
              id = uuid.v4();
            }
            handleSelectPipeline(id);
            setView(path1);
            break;
          case 'u':
            // http://localhost:3000/u/raymondkpwong/redact-v2--13ca0ecd-443e-485f-95e4-dbd3d44f7ba6
            const [author, slug] = rest;
            let pipelineName;
            [pipelineName, id] = slug.split('--');
            handleSelectPipeline(id);
            break;
          default:
            setView(defaultView);
        }
        /* eslint-enable no-case-declarations */
      }
      else {
        // history.replace('/discover');
        history.replace(`/${defaultView}`);
        setView(defaultView);
      }
    })();
  }, []);

  const handleRegisterChange = v => {
    registerProfileRef.current = v;
  };

  const cancel = () => confirm('Warning', <>
    <Typography>Are you sure to cancel registration? You will not be able to use the system.</Typography>
  </>, 'yesno', async res2 => {
    if (res2 === 'yes') {
      await setAuthDataAsync(null);
      window.location.href = '/';
    }
    if (res2 === 'no') {
      doRegister();
    }
    return false;
  });

  const handleMenuOpen = event => {
    setCurrentMenuName(event.currentTarget.id);
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuClosed = () => {
    setCurrentMenuName(null);
    setAnchorMenuEl(null);
  };

  const doRegister = () => {
    confirm('Sign up now', <RegisterMain
      onChange={handleRegisterChange}
    />, 'okcancel', async res => {
      if (res === 'close') {
        process.nextTick(() => cancel());
        return false; // do not close the dialog
      }
      if (res === 'cancel') {
        cancel();
        return false;
      }

      return registerInServer();
    });
  };

  const assertRegistration = registration => {
    if (registration === null) {
      return false;
    }

    if (!registration.givenName || registration.givenName.trim().length === 0) {
      alert('Please input first name.');
      return false;
    }
    if (!registration.familyName || registration.familyName.trim().length === 0) {
      alert('Please input last name.');
      return false;
    }
    if (!registration.organization || registration.organization.trim().length === 0) {
      alert('Please input organization name.');
      return false;
    }
    if (!registration.contact || registration.contact.trim().length === 0) {
      alert('Please input contact number.');
      return false;
    }
    if (!registration.author || registration.author.trim().length === 0) {
      alert('Please input author name.');
      return false;
    }
    if (!/^[a-zA-Z0-9]+$/.test(registration.author)) {
      alert('Author name can only comprise of alphabets and numbers only. Please try another one.');
      return false;
    }
    if (!registration.agreed) {
      alert('Please accept Terms & Conditions.');
      return false;
    }

    return true;
  };

  const registerInServer = async () => {
    if (!assertRegistration(registerProfileRef.current)) return false;

    alert();

    // TODO: request apiKey
    const oldProfile = await getAuthDataAsync();
    const url = getEndpointUrl();
    const res = await fetch(`${url}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        profile: oldProfile,
        registerProfile: registerProfileRef.current,
      }),
    });

    const newProfile = await res.json();
    if (newProfile.key) {
      await setAuthDataAsync(newProfile);
      console.debug(newProfile.key);
    }
    else {
      if (newProfile.error.code === 'bad-words') {
        alert('Author name contains reserved word(s)', 'error');
        return false;
      }

      if (newProfile.error.code === 'author-exists') {
        alert('Author name has been taken. Please try another one.', 'error');
        return false;
      }
      if (newProfile.error.code === 'invalid-firstname') {
        alert('First name is invalid. Please try another one.', 'error');
        return false;
      }
      if (newProfile.error.code === 'invalid-lastname') {
        alert('First name is invalid. Please try another one.', 'error');
        return false;
      }
      if (newProfile.error.code === 'invalid-organization') {
        alert('Organization name is invalid. Please try another one.', 'error');
        return false;
      }
      if (newProfile.error.code === 'invalid-contact') {
        alert('Contact number is invalid. Please try another one.', 'error');
        return false;
      }
      if (newProfile.error.code === 'invalid-author') {
        alert('Author name is invalid. Please try another one.', 'error');
        return false;
      }

      alert('API key cannot be generated. Please try again.', 'error');
      return false;
    }

    onRegisterSuccess();
    return false;
  };

  const alert = (
    message = '',
    severity = 'error',
    onClose = () => { },
    options = {
      canAck: false,
      alertHandler: () => { },
      ackHandler: () => { },
    },
  ) => {
    let shouldAlert = true;

    if (options.canAck) {
      shouldAlert = !ackedAlertsRef.current.find(m => m.message === message);
    }

    if (shouldAlert) {
      if (options.alertHandler && message && message.trim() !== '') options.alertHandler();
      setNotification({ message, severity });
      setOpenSnackbar(true);
      snackbarOnCloseCallbackRef.current = reason => {
        if (reason === 'clickaway') {
          return false;
        }
        if (options.canAck) {
          if ([undefined, 'escapeKeyDown'].includes(reason)) {
            ackedAlertsRef.current.push({
              message,
              ackTs: Date.now(),
            });
            ackedAlertsRef.current = ackedAlertsRef.current.slice(-1);
            options.ackHandler();
          }
        }
        if (typeof onClose === 'function') {
          onClose();
        }
        return true;
      };
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    console.log(classes.drawer);
    console.log(clsx(classes.drawer, {
      [classes.drawerOpen]: open,
      [classes.drawerClose]: !open,
    }));
    console.log({
      paper: clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      }),
    });
  };

  const handleMenuItemClick = (destination, override = false) => () => {
    if (view === destination) return;

    if (view === 'create' && !override) {
      confirm(
        'Exit without saving',
        'Are you sure to leave the designer without saving? Some changes will be discarded.',
        'yesno',
        res => {
          if (res === 'yes') {
            process.nextTick(() => handleMenuItemClick(destination, true)());
          }
        },
      );

      return;
    }

    if (destination === 'events') {
      upgrade();
      return;
    }

    if (destination === 'reload') {
      window.location.reload();
      return;
    }

    if (destination === 'screenOff') {
      confirm(
        'Sign out',
        'Are you sure to sign out? Your changes will be discarded.',
        'yesno',
        async res => {
          if (res === 'yes') {
            await setAuthDataAsync(null);
            if (localStorage.getItem('cnr-edgeai-studio-app-key').startsWith('WljOjKdWoher')) {
              window.location.href = '/cdn-cgi/access/logout';
            }
            else if (localStorage.getItem('cnr-edgeai-studio-app-key').startsWith('eTZxWJhvTUEg')) {
              window.location.href = '/cdn-cgi/access/logout';
            }
            else {
              window.location.href = '/';
            }
          }
        },
      );

      return;
    }

    if (destination === 'discover-for-tst') {
      window.open('https://mtr-adm-peoplecounting-3000.cnr.ai/create/3453dee1-f1ff-4ddb-9cb6-05ce32117d51');
      return;
    }

    if (destination === 'cloudflare') {
      window.open('https://one.dash.cloudflare.com/3b0bb71a35c0bdccacac63cf91308ee4/logs/access/');
      return;
    }

    if (destination === 'repl') {
      window.open('https://mtr-adm-peoplecounting-ssh.cnr.ai/');
      return;
    }

    history.replace(`/${destination}`);
    setView(destination);
  };

  const upgrade = (message = 'You can upgrade to Studio Business or Enterprise to unlock this feature.') => confirm(
    'Upgrade',
    message,
    'ok',
    () => { },
  );

  const confirm = (title = 'Confirmation', message, type = 'yesno', onClick) => {
    setDialog({
      title,
      message,
      type,
      onClick,
      onClose: () => {
        if (onClick('close') === false) { return; }
        setOpenDialog(false);
      },
    });
    setOpenDialog(true);
  };

  const backdrop = show => {
    setBackdropOpen(!!show);
  };

  const handleSelectPipeline = (id, fh = null) => {
    pipelineIdRef.current = id;
    setNewlyLoaded(true);
    setFileHandle(fh);
    setTimeout(() => {
      setView('create');
      history.replace(`/create/${id}`);
    }, 100);
  };

  const handleClickDiscord = () => {
    window.open('https://discord.com/invite/FsX5aAn5bz', '_blank');
  };

  const handleClickDocumentations = () => {
    window.open('https://docs.cnr.ai/', '_blank');
  };

  const snackBarOnClose = (e, reason) => {
    if (typeof snackbarOnCloseCallbackRef.current === 'function') {
      if (!snackbarOnCloseCallbackRef.current(reason)) {
        return;
      }
      snackbarOnCloseCallbackRef.current = null;
    }
    setOpenSnackbar(false);
  };

  const facilities = {
    alert,
    confirm,
    backdrop,
    upgrade,
    subscriptionPlan,
    onThemeChange,
    language,
    strings,
  };

  // TODO: Add clickaway listener https://material-ui.com/components/click-away-listener/

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.typographygraphy} variant="h5" noWrap>
            {whiteLabel}
          </Typography>
          <TouchableIconButton
            id='help'
            style={{ marginLeft: 'auto' }}
            className={classes.typography}
            onClick={handleMenuOpen}>
            <HelpIcon style={{ fontSize: 28 }} />
          </TouchableIconButton>

          {/* language */}
          <Select
            value={language}
            onChange={e => {
              setLanguage(e.target.value);
            }}
            input={<Input />}
          >
            <MenuItem value={'en-US'}>Eng</MenuItem>
            <MenuItem value={'zh-TW'}>繁體</MenuItem>
            {/* <MenuItem value={'zh-CN'}>简体</MenuItem> */}
          </Select>

          <IconButton id='avatar' onClick={handleMenuOpen}>
            <Avatar
              sx={{ width: 50 }}
              src={picture}
            >
              {nameLetter}
            </Avatar>
          </IconButton>
          {subscriptionPlan !== 'free'
            ? <img alt='Paid Crown' src={CrownIcon} className={classes.crownIcon} />
            : false
          }
          {name
            ? <Typography
              className={classes.typography}
              variant="caption"
              noWrap
              style={{
                marginRight: 16,
                fontSize: '14px', // TODO: theme.spacing(2),
              }}>
              {username ?? name}
            </Typography>
            : false}
          <Menu
            classes={{ list: classes.dropDownContainer }}
            anchorEl={anchorMenuEl}
            keepMounted={false}
            open={currentMenuName === 'avatar'}
            onClose={handleMenuClosed}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem onClick={handleMenuItemClick('screenOff')}>Sign Out</MenuItem>
          </Menu>
          <Menu
            classes={{ list: classes.dropDownContainer }}
            anchorEl={anchorMenuEl}
            keepMounted={false}
            open={currentMenuName === 'help'}
            onClose={handleMenuClosed}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem onClick={handleClickDiscord}>
              <ListItemIcon>
                <img alt='Discord Icon'
                  src={
                    props.theme ? DiscordWhiteIcon : DiscordBlackIcon
                  }
                  className={classes.discordIcon} />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Join Discord Community
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleClickDocumentations}>
              <ListItemIcon>
                <DocIcon />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                EdgeAI Studio Docs
              </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        elevation={5}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon className={classes.typography} />
          </IconButton>
        </div>
        <Divider />
        <List className={classes.pageToolBar}>
          {/* <MenuItem className={classes.menuItem} button
            key="home" selected={view === 'home'} onClick={handleMenuItemClick('home')}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </MenuItem> */}

          {/* discover */}
          {showViews.includes('discover')
            ? <Tooltip title={open ? '' : 'Discover'}>
              <MenuItem className={classes.menuItem} button
                key="discover" selected={view === 'discover'}
                onClick={handleMenuItemClick('discover')}>
                <ListItemIcon><WorkspacesIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Discover" />
              </MenuItem>
            </Tooltip>
            : false}


          {/* discover-for-tst */}
          {showViews.includes('discover-for-tst')
            ? <Tooltip title={open ? '' : 'Discover'}>
              <MenuItem className={classes.menuItem} button
                key="discover" selected={view === 'discover-for-tst'}
                onClick={handleMenuItemClick('discover-for-tst')}>
                <ListItemIcon><WorkspacesIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Discover" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* create */}
          {showViews.includes('create')
            ? <Tooltip title={open ? '' : 'Create'}>
              <MenuItem className={classes.menuItem} button
                key="create" selected={view === 'create'}
                onClick={handleMenuItemClick('create')}
                disabled={isGuest}>
                <ListItemIcon><PipelinesIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Create" />
              </MenuItem>
            </Tooltip>
            : false}


          {/* <MenuItem className={classes.menuItem} button
            key="models" selected={view === 'models'} onClick={handleMenuItemClick('models')}>
            <ListItemIcon><ModelsIcon /></ListItemIcon>
            <ListItemText primary="Models" />
          </MenuItem> */}

          {/* media */}
          {showViews.includes('media')
            ? <Tooltip title={open ? '' : 'Media'}>
              <MenuItem className={classes.menuItem} button
                key="media" selected={view === 'media'}
                onClick={handleMenuItemClick('media')}
                disabled={isGuest}>
                <ListItemIcon><MediaIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Media" />
              </MenuItem>
            </Tooltip>
            : false}

          {showViews.includes('liveview')
            ? <Tooltip title={open ? '' : 'LiveView'}>
              <MenuItem className={classes.menuItem} button
                key="liveview" selected={view === 'liveview'}
                onClick={handleMenuItemClick('liveview')}
              // disabled={isGuest}
              >
                <ListItemIcon><LiveViewIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="LiveView" />
              </MenuItem>
            </Tooltip>
            : false
          }

          {showViews.includes('analyze')
            ? <Tooltip title={open ? '' : 'Analyze'}>
              <MenuItem className={classes.menuItem} button
                key="events" selected={view === 'events'}
                onClick={handleMenuItemClick('events')}
                disabled={false}>
                <ListItemIcon><EventIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Analyze" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* map */}
          {showViews.includes('map')
            ? <Tooltip title={open ? '' : 'Map'}>
              <MenuItem className={classes.menuItem} button
                key="map" selected={view === 'map'}
                onClick={handleMenuItemClick('map')}
                disabled={false}>
                <ListItemIcon><MapIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Map" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* map-zone-1 */}
          {showViews.includes('map-zone-1')
            ? <Tooltip title={open ? '' : 'Zone 1'}>
              <MenuItem className={classes.menuItem} button
                key="map-zone-1" selected={view === 'map-zone-1'}
                onClick={handleMenuItemClick('map-zone-1')}
                disabled={false}>
                <ListItemIcon><OneIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Zone 1" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* map-zone-2 */}
          {showViews.includes('map-zone-2')
            ? <Tooltip title={open ? '' : 'Zone 2'}>
              <MenuItem className={classes.menuItem} button
                key="map-zone-2" selected={view === 'map-zone-2'}
                onClick={handleMenuItemClick('map-zone-2')}
                disabled={false}>
                <ListItemIcon><TwoIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Zone 2" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* map-zone-3 */}
          {showViews.includes('map-zone-3')
            ? <Tooltip title={open ? '' : 'Zone 3'}>
              <MenuItem className={classes.menuItem} button
                key="map-zone-3" selected={view === 'map-zone-3'}
                onClick={handleMenuItemClick('map-zone-3')}
                disabled={false}>
                <ListItemIcon><ThreeIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Zone 3" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* dashboard */}
          {showViews.includes('dashboard')
            ? <Tooltip title={open ? '' : 'Dashboard'}>
              <MenuItem className={classes.menuItem} button
                key="dashboard" selected={view === 'dashboard'}
                onClick={handleMenuItemClick('dashboard')}
                disabled={false}>
                <ListItemIcon><DashboardIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Dashboard" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* dashboard-for-tst */}
          {showViews.includes('dashboard-for-tst')
            ? <Tooltip title={open ? '' : 'Dashboard'}>
              <MenuItem className={classes.menuItem} button
                key="dashboard" selected={view === 'dashboard-for-tst'}
                onClick={handleMenuItemClick('dashboard-for-tst')}
                disabled={false}>
                <ListItemIcon><DashboardIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Dashboard" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* chart */}
          {showViews.includes('chart')
            ? <Tooltip title={open ? '' : 'Chart'}>
              <MenuItem className={classes.menuItem} button
                key="chart" selected={view === 'chart'}
                onClick={handleMenuItemClick('chart')}
                disabled={false}>
                <ListItemIcon><BarChartIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Chart" />
              </MenuItem>
            </Tooltip>
            : false}

          {/* <MenuItem className={classes.menuItem} button
            key="files" selected={view === 'files'} onClick={handleMenuItemClick('files')}>
            <ListItemIcon><FilesIcon /></ListItemIcon>
            <ListItemText primary="Files" />
          </MenuItem>
          <MenuItem className={classes.menuItem} button
            key="api" selected={view === 'api'} onClick={handleMenuItemClick('api')}>
            <ListItemIcon><ApiIcon /></ListItemIcon>
            <ListItemText primary="API" />
          </MenuItem> */}

          {showViews.includes('cloudflare')
            ? <Tooltip title={open ? '' : 'Access dashboard'}>
              <MenuItem className={classes.menuItem} button
                key="cloudflare" selected={view === 'cloudflare'} onClick={handleMenuItemClick('cloudflare')}>
                <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                <ListItemText primary="Access dashboard" />
              </MenuItem>
            </Tooltip>
            : false}

          {showViews.includes('repl')
            ? <Tooltip title={open ? '' : 'Command line control'}>
              <MenuItem className={classes.menuItem} button
                key="repl" selected={view === 'repl'} onClick={handleMenuItemClick('repl')}>
                <ListItemIcon><TerminalIcon /></ListItemIcon>
                <ListItemText primary="Command line control" />
              </MenuItem>
            </Tooltip>
            : false}
        </List>
        <Divider />
        <List className={classes.pageToolBar}>
          {showViews.includes('settings')
            ? <Tooltip title={open ? '' : 'Settings'}>
              <MenuItem className={classes.menuItem} button
                key="settings" selected={view === 'settings'}
                onClick={handleMenuItemClick('settings')}
                disabled={isGuest}
              >
                <ListItemIcon><SettingsIcon className={classes.typography} /></ListItemIcon>
                <ListItemText className={classes.typography} primary="Settings" />
              </MenuItem>
            </Tooltip>
            : false}
          {/* <Tooltip title={open ? '' : 'Account'}>
            <MenuItem className={classes.menuItem} button
              key="account" selected={view === 'account'} onClick={handleMenuItemClick('account')}>
              <ListItemIcon><AccountCircleIcon className={classes.typography} /></ListItemIcon>
              <ListItemText className={classes.typography} primary="Account" />
            </MenuItem>
          </Tooltip> */}
          {/* <MenuItem className={classes.menuItem} button
            key="reload" selected={view === 'reload'} onClick={handleMenuItemClick('reload')}>
            <ListItemIcon><ResetIcon /></ListItemIcon>
            <ListItemText primary="Reload" />
          </MenuItem> */}
          <Tooltip title={open ? '' : 'Sign out'}>
            <MenuItem className={classes.menuItem} button
              key="screenOff" selected={view === 'screenOff'} onClick={handleMenuItemClick('screenOff')}>
              <ListItemIcon><ScreenOffIcon className={classes.typography} /></ListItemIcon>
              <ListItemText className={classes.typography} primary="Sign out" />
            </MenuItem>
          </Tooltip>
        </List>
      </Drawer>

      {/* This is for covering the part which is below the iOS status bar */}
      <div className={classes.statusBarCover} />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {view === 'home' ? <StatMain black={false} /> : false}
        {view === 'models' ? <Model2Main {...facilities} /> : false}
        {view === 'create' ? <Suspense fallback={<div>Loading...</div>}>
          <PipelineMain {...facilities}
            id={pipelineIdRef.current}
            onLoaded={newId => {
              pipelineIdRef.current = newId;
            }}
            newlyLoaded={newlyLoaded}
            onNewlyLoaded={v => setNewlyLoaded(v)}
            fileHandle={fileHandle}
          />
        </Suspense> : false}
        {view === 'settings' ? <SettingsMain {...facilities} /> : false}
        {view === 'events' ? <EventMain {...facilities} /> : false}
        {view === 'map' ? <Suspense fallback={<div>Loading...</div>}>
          <MapMain {...facilities} />
        </Suspense> : false}
        {view === 'map-zone-1' ? <Suspense fallback={<div>Loading...</div>}>
          <MapMain {...facilities} zone={'1'} />
        </Suspense> : false}
        {view === 'map-zone-2' ? <Suspense fallback={<div>Loading...</div>}>
          <MapMain {...facilities} zone={'2'} />
        </Suspense> : false}
        {view === 'map-zone-3' ? <Suspense fallback={<div>Loading...</div>}>
          <MapMain {...facilities} zone={'3'} />
        </Suspense> : false}
        {view === 'dashboard' ? <Suspense fallback={<div>Loading...</div>}>
          <DashboardMain {...facilities} />
        </Suspense> : false}
        {view === 'dashboard-for-tst' ? <Suspense fallback={<div>Loading...</div>}>
          <DashboardForTstMain {...facilities} />
        </Suspense> : false}
        {view === 'chart' ? <Suspense fallback={<div>Loading...</div>}>
          <DetailChartMain {...facilities} />
        </Suspense> : false}
        {view === 'cloudflare' ? <Suspense fallback={<div>Loading...</div>}>
          <DetailChartMain {...facilities} />
        </Suspense> : false}
        {view === 'repl' ? <Suspense fallback={<div>Loading...</div>}>
          <DetailChartMain {...facilities} />
        </Suspense> : false}
        {view === 'discover-for-tst' ? <Suspense fallback={<div>Loading...</div>}>
          <DetailChartMain {...facilities} />
        </Suspense> : false}
        {view === 'discover' ? <WorkspaceMain
          {...facilities}
          onSelect={handleSelectPipeline}
          theme={props.theme}
        /> : false}
        {view === 'media' ? <MediaMain {...facilities} /> : false}
        {view === 'liveview' ? <LiveViewMain {...facilities} /> : false}
        {view === 'account' ? <AccountMain {...facilities} /> : false}
      </main>

      {
        notification.message && notification.message !== '' && (
          <Snackbar open={openSnackbar} onClose={snackBarOnClose}>
            <Alert onClose={snackBarOnClose} severity={notification.severity || 'error'}>
              <Typography>
                {notification.message}
              </Typography>
            </Alert>
          </Snackbar>
        )
      }

      <StudioDialog
        open={openDialog}
        title={dialog.title}
        message={dialog.message}
        type={dialog.type}
        onClick={dialog.onClick}
        onClose={dialog.onClose}
      />

      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default connect()(
  withStyles(styles)(MiniDrawer),
);
