import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import StatForm from './StatForm';
import adminStyle from './dashboard/assets/jss/material-dashboard-react/layouts/adminStyle';

const styles = theme => ({
  content: {
    padding: theme.spacing(3),
    // TODO: change 64px to theme.mixins.toolbar...height
    minHeight: 'calc(100vh - 64px)',
    backgroundColor: '#eeeeee',
  },
  contentBlack: {
    backgroundColor: '#000000',
  },
  ...adminStyle,
});

const StatMain = props => {
  const { classes, black } = props;

  return (
    <React.Fragment>
      <div
        className={clsx(classes.content, {
          [classes.contentBlack]: black,
        })}>
        {!black && <div className={classes.container}>
          <StatForm />
        </div>}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(StatMain);
