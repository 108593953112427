import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import SettingsForm2 from './SettingsForm2';

const styles = theme => ({
  content: {
    // padding: theme.spacing(3),
    height: `calc(100% - ${theme.appBarSpacing})`,
    backgroundColor: theme.palette.primary.main,
    borderTop: theme.palette.component.borderOutline,
    width: '100%',
  },
});

const SettingsMain = props => {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={clsx(classes.content)}>
        <SettingsForm2 {...props}/>
      </div>
    </React.Fragment>
  );
};

export default connect()(
  withStyles(styles)(SettingsMain),
);
