const enUS = {
  map: 'Map',
  zone1: 'Zone 1',
  zone2: 'Zone 2',
  zone3: 'Zone 3',
  last1minute: 'Last 1 minute',
  last5minutes: 'Last 5 minutes',
  last15minutes: 'Last 15 minutes',
  last30minutes: 'Last 30 minutes',
  last1hour: 'Last 1 hour',
  lastupdated: 'Last updated',
  today: 'Today',
  dashboard: 'Dashboard',
  ealplatformtol2: 'EAL Platform to L2',
  ealplatformtol3: 'EAL Platform to L3',
  totalpassengersnow: 'Total passengers now',
  totaltraffictoday: 'Total traffic today',
  trafficforthelast1hour: 'Traffic for the last 1 hour',
  ealplatform: 'EAL Platform',
  chw: 'CHW',
  cen: 'CEN',
  ket: 'KET',
  tsw: 'TSW',
  liftqueueinglength: 'Lift queueing length',
  queuelength: 'Queue length',
  waitingtime: 'Waiting time',
  xactqueuelength: 'Txn length',
  xactwaitingtime: 'Txn time',
  l1concourse: 'L1 Concourse',
  l2platformisltwl: 'L2 Platform - ISL / TWL',
  l3platformtwlisl: 'L3 Platform - TWL / ISL',
  l5platformeal: 'L5 Platform - EAL',
  liftl03queuinglength: 'Lift L03 queueing length',
  liftl08queuinglength: 'Lift L08 queueing length',
  l6platformsil: 'L6 Platform - SIL',
  concoursecuc: 'Concourse - CUC',
  cucpaidareaqueueinglength: 'CUC Paid queueing length',
  cucnonpaidareaqueueinglength: 'CUC Non-Paid queueing length',
  people: 'ppl',
  seconds: 'sec',
  chart: 'Chart',
  correspondinghourlytrafficheatmap: 'Corresponding Hourly Traffic Heatmap',
  correspondinghourlytrafficheatmapinout: 'Corresponding Hourly Traffic Heatmap (In/Out)',
  correspondingtrafficflow: 'Corresponding Traffic Flow',
  week: 'Week',
  liftsescalators: 'Lifts/Escalators',
  search: 'Search',
  searching: 'Searching',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  peoplecount: 'People Count',
  timedaily: 'Time (Daily)',
  exportxlsx: 'Export XLSX',
  exportcsv: 'Export CSV',
  passengersinconcourse: 'Passengers in Concourse',
  cucpaid: 'CUC PAID',
  cucnonpaid: 'CUC NON-PAID',
  in: 'In',
  out: 'Out',
  entrancea: 'Entrance A',
  entranceb: 'Entrance B',
  entrancec: 'Entrance C',
  entranced: 'Entrance D',
  entrancee: 'Entrance E',
  entrancef: 'Entrance F',
  entranceg: 'Entrance G',
  entranceh: 'Entrance H',
  entrancer: 'Entrance R',
  entrances: 'Entrances',
  gates: 'Gates',
  concoursepassengers: 'Concourse Passengers',
  legend: 'Legend',
  green: 'Green',
  yellow: 'Yellow',
  red: 'Red',
  percentageofcapacity: '% of capacity',
};

const zhTW = {
  // 繁體中文
  map: '地圖',
  zone1: 'Zone 1',
  zone2: 'Zone 2',
  zone3: 'Zone 3',
  last1minute: '最近 1 分鐘',
  last5minutes: '最近 5 分鐘',
  last15minutes: '最近 15 分鐘',
  last30minutes: '最近 30 分鐘',
  last1hour: '最近 1 小時',
  today: '今天',
  dashboard: '儀表板',
  ealplatformtol2: '東鐵綫月台往 L2月台',
  ealplatformtol3: '東鐵綫月台往 L3月台',
  totalpassengersnow: '現時總人流',
  totaltraffictoday: '今日總人流',
  trafficforthelast1hour: '過去一小時總人流',
  ealplatform: '東鐵綫月台',
  chw: '柴灣',
  cen: '中環',
  ket: '堅尼地城',
  tsw: '荃灣',
  liftqueueinglength: '升降機等候人數',
  queuelength: '等候人數',
  waitingtime: '等候時間',
  xactqueuelength: '處理人數',
  xactwaitingtime: '處理時間',
  l1concourse: 'L1 大堂',
  l2platformisltwl: 'L2月台 – 港島綫/荃灣綫',
  l3platformtwlisl: 'L3月台 – 荃灣綫/港島綫',
  l5platformeal: 'L5月台 – 東鐵綫',
  liftl03queuinglength: '升降機 L03 等候人數',
  liftl08queuinglength: '升降機 L08 等候人數',
  l6platformsil: 'L6月台 – 南港島綫',
  concoursecuc: '大堂 – CUC',
  cucpaidareaqueueinglength: 'CUC 付費區等候人數',
  cucnonpaidareaqueueinglength: 'CUC 非付費區等候人數',
  people: '人',
  seconds: '秒',
  chart: '圖表',
  correspondinghourlytrafficheatmap: '每小時流量熱點圖',
  correspondinghourlytrafficheatmapinout: '每小時流量熱點圖 (入/出)',
  correspondingtrafficflow: '對應流量人次',
  week: '星期',
  liftsescalators: '升降機/扶手電梯',
  search: '搜尋',
  searching: '搜尋中',
  sunday: '星期日',
  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  saturday: '星期六',
  peoplecount: '人流',
  timedaily: '時間 (每日)',
  exportxlsx: '滙出 XLSX',
  exportcsv: '滙出 CSV',
  lastupdated: '最後更新',
  passengersinconcourse: '大堂人流',
  cucpaid: 'CUC 付費區',
  cucnonpaid: 'CUC 非付費區',
  in: '入',
  out: '出',
  entrancea: '出入口 A',
  entranceb: '出入口 B',
  entrancec: '出入口 C',
  entranced: '出入口 D',
  entrancee: '出入口 E',
  entrancef: '出入口 F',
  entranceg: '出入口 G',
  entranceh: '出入口 H',
  entrancer: '出入口 R',
  entrances: '出入口',
  gates: '閘口',
  concoursepassengers: '大堂人流',
  legend: '圖例',
  green: '綠色',
  yellow: '黃色',
  red: '紅色',
  percentageofcapacity: '人流百分比',
};

const zhCN = {
  map: '地圖',
  zone1: 'Zone 1',
  zone2: 'Zone 2',
  zone3: 'Zone 3',
  last1minute: '過去 1 分鐘',
  last5minutes: '過去 5 分鐘',
  last15minutes: '過去 15 分鐘',
  last30minutes: '過去 30 分鐘',
  last1hour: '過去 1 小時',
  today: '今天',
  lastupdated: '最後更新',
  passengersinconcourse: '大堂人流',
  cucpaid: 'CUC 付費區',
  cucnonpaid: 'CUC 非付費區',
  in: '入',
  out: '出',
  entrancea: '出入口 A',
  entranceb: '出入口 B',
  entrancec: '出入口 C',
  entranced: '出入口 D',
  entrancee: '出入口 E',
  entrancef: '出入口 F',
  entranceg: '出入口 G',
  entranceh: '出入口 H',
  entrancer: '出入口 R',
  entrances: '出入口',
  gates: '闸口',
  concoursepassengers: '大堂人流',
  legend: '图例',
  green: '绿色',
  yellow: '黄色',
  red: '红色',
  percentageofcapacity: '人流百分比',
};

export { enUS, zhTW, zhCN };
