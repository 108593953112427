const configMinimal = require('./config-minimal');

module.exports = {
  // EDGEAI_HOSTNAME: '192.168.0.154',
  EDGEAI_HOSTNAME: window.document.location.hostname,
  MAX_CONCURRENCY: 15,
  OBJECT_REPO_HOSTNAME: '192.168.0.172',
  OBJECT_REPO_PORT: 8091,
  BLACKOUT_IN_MS: 60000,
  GA_MEASUREMENT_ID: 'G-RE6NW747WM',
  DEFAULTS: {
    default: {
      objects: 'person',
      showSubmodel: true,
      showFaceParameters: true,
    },
    mask: {
      objects: 'HeadBox,HeadBoxMask',
      showSubmodel: true,
      showFaceParameters: true,
    },
    'pipe-v3': {
      confidence: 50,
      objects: 'Tee',
      showSubmodel: true,
      showFaceParameters: false,
    },
    ocr: {
      showSubmodel: false,
      showFaceParameters: false,
    },
    receipt: {
      showSubmodel: false,
      showFaceParameters: false,
    },
    bankslip: {
      showSubmodel: false,
      showFaceParameters: false,
    },
  },
  APP: {
    QUERY_KEY: 'app-key',
    PROFILES: {
      configMinimal,
      Ju5pVxHP9C: {
        LOGIN_BUTTON_TEXT: 'Continue as Guest',
        API_KEY: '',
        AUTHDATA: {
          author: 'guest',
          contact: '',
          department: 'Dev',
          email: 'guest@cnr.ai',
          familyName: 'CNR',
          givenName: 'GUEST',
          key: '30d0edcb-e746-46d2-9b63-a9a5664ed6c5',
          organization: 'CNR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'guest2@cnr.ai',
          settings: `version: 1
api:
  ip: studio.cnr.ai
  port: 443
  protocol: https
neurals:
  - ip: 127.0.0.1
    port: 8090
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: true,
          plan: 'business',
        },
      },
      HgtX8C5A9X: {
        LOGIN_BUTTON_TEXT: 'Continue as Enterprise',
        API_KEY: '',
        AUTHDATA: {
          author: 'enterprise',
          contact: '',
          department: 'Dev',
          email: 'enterprise@cnr.ai',
          familyName: 'CNR',
          givenName: 'ENTERPRISE',
          key: '99c0da61-3563-4e73-a1d4-ea4d0f37c549',
          organization: 'CNR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'enterprise2@cnr.ai',
          settings: `version: 1
api:
  ip: 127.0.0.1
  port: 8888
  protocol: http
neurals:
  - ip: 127.0.0.1
    port: 8090
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          plan: 'enterprise',
          isGuest: false,
        },
      },

      TIf9mrg4mb: {
        LOGIN_BUTTON_TEXT: 'Continue as LRV workstation',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'mtr-lrv1@cnr.ai',
          familyName: 'MTR',
          givenName: 'LRV1',
          key: 'ffc1960d-7c90-4705-8819-238f919449eb',
          organization: 'MTR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'mtr-lrv@cnr.ai',
          settings: `version: 1
api:
  ip: 127.0.0.1
  port: 3000
  protocol: http
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      kx0rPROPzX: {
        LOGIN_BUTTON_TEXT: 'Continue as LRV1',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'mtr-lrv1@cnr.ai',
          familyName: 'MTR',
          givenName: 'LRV1',
          key: 'ffc1960d-7c90-4705-8819-238f919449eb',
          organization: 'MTR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'mtr-lrv@cnr.ai',
          settings: `version: 1
api:
  ip: 127.0.0.1
  port: 3001
  protocol: http
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      sUUx9CwN3r: {
        LOGIN_BUTTON_TEXT: 'Continue as LRV2',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'mtr-lrv2@cnr.ai',
          familyName: 'MTR',
          givenName: 'LRV2',
          key: '680bd2bf-399b-4c13-afbd-c06096544787',
          organization: 'MTR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'mtr-lrv@cnr.ai',
          settings: `version: 1
api:
  ip: 127.0.0.1
  port: 3002
  protocol: http
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      nr2UT9wpze: {
        LOGIN_BUTTON_TEXT: 'Continue as Maxims',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'maxims-edgeai01@cnr.ai',
          familyName: 'Maxims',
          givenName: 'EdgeAI 01',
          key: '5181aaf6-f606-4d6d-8e00-a8b021954604',
          organization: 'Maxims',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'maxims-edgeai01@cnr.ai',
          settings: `version: 1
api:
  ip: 127.0.0.1
  port: 3000
  protocol: http
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      vNG7BtT3AZ: {
        LOGIN_BUTTON_TEXT: 'Continue as VTC',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'vtc-edgeai01@cnr.ai',
          familyName: 'VTC',
          givenName: 'EdgeAI 01',
          key: 'e03f455b-3c0c-4c4c-b82f-2eba1677b032',
          organization: 'Maxims',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'vtc-edgeai01@cnr.ai',
          settings: `version: 1
api:
  ip: 127.0.0.1
  port: 3000
  protocol: http
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      'vNG7BtT3AZ-debug': {
        LOGIN_BUTTON_TEXT: 'Continue as vtc-edgea01',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'vtc-edgeai01@cnr.ai',
          familyName: 'VTC',
          givenName: 'EdgeAI 01',
          key: 'e03f455b-3c0c-4c4c-b82f-2eba1677b032',
          organization: 'Maxims',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'vtc-edgeai01@cnr.ai',
          settings: `version: 1
api:
  ip: vtc-edgeai01-3000.cnr.ai
  port: 443
  protocol: https
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      'cPCUEQtWc9-debug': {
        LOGIN_BUTTON_TEXT: 'Continue as vtc-edgeai02',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'vtc-edgeai01@cnr.ai',
          familyName: 'VTC',
          givenName: 'EdgeAI 01',
          key: 'e03f455b-3c0c-4c4c-b82f-2eba1677b032',
          organization: 'Maxims',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'vtc-edgeai01@cnr.ai',
          settings: `version: 1
api:
  ip: vtc-edgeai02-3000.cnr.ai
  port: 443
  protocol: https
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      WljOjKdWoher: {
        LOGIN_BUTTON_TEXT: 'Continue as ADM Passenger & Crowd Movement Monitoring System',
        API_KEY: '',
        AUTHDATA: {
          theme: 'light',
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'mtr-adm-peoplecounting@cnr.ai',
          familyName: 'MTR',
          givenName: 'EdgeAI 01',
          key: 'ffc1ef57-2220-45ab-991b-a808771d1d03',
          organization: 'MTR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'mtr-adm-peoplecounting@cnr.ai',
          settings: `version: 1
api:
  ip: mtr-adm-peoplecounting-3000.cnr.ai
  port: 443
  protocol: https
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      'WljOjKdWoher-443': {
        LOGIN_BUTTON_TEXT: 'Continue as ADM Passenger & Crowd Movement Monitoring System',
        API_KEY: '',
        AUTHDATA: {
          theme: 'light',
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'mtr-adm-peoplecounting@cnr.ai',
          familyName: 'MTR',
          givenName: 'EdgeAI 01',
          key: 'ffc1ef57-2220-45ab-991b-a808771d1d03',
          organization: 'MTR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'mtr-adm-peoplecounting@cnr.ai',
          settings: `version: 1
api:
  ip: mtr-adm-peoplecounting-3000.cnr.ai
  port: 443
  protocol: https
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      eTZxWJhvTUEg: {
        LOGIN_BUTTON_TEXT: 'Continue as TST Passenger & Crowd Movement Monitoring System',
        API_KEY: '',
        AUTHDATA: {
          theme: 'light',
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'mtr-tst-peoplecounting@cnr.ai',
          familyName: 'MTR',
          givenName: 'EdgeAI 01',
          key: 'a17160c3-2bd5-49c8-a838-5a58a0403f55',
          organization: 'MTR',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'mtr-tst-peoplecounting@cnr.ai',
          settings: `version: 1
api:
  ip: mtr-adm-peoplecounting-3000.cnr.ai
  port: 443
  protocol: https
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      'ogcio-pK1awKyRqm': {
        LOGIN_BUTTON_TEXT: 'Continue as OGCIO',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'ogcio-edgeai01@cnr.ai',
          familyName: 'OGCIO',
          givenName: 'EdgeAI 01',
          key: '32b7895d-b281-4cc1-a163-0c2ae6c2bb55',
          organization: 'OGCIO',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'ogcio-edgeai01@cnr.ai',
          settings: `version: 1
api:
  ip: ogcio-imagecomparison-3000-p1.cnr.ai
  port: 8443
  protocol: https
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
      'ogcio-pK1awKyRqm-dev': {
        LOGIN_BUTTON_TEXT: 'Continue as OGCIO Dev',
        API_KEY: '',
        AUTHDATA: {
          author: 'raymondkpwong',
          contact: '35824232',
          department: 'Dev',
          email: 'ogcio-edgeai01@cnr.ai',
          familyName: 'OGCIO',
          givenName: 'EdgeAI 01',
          key: '32b7895d-b281-4cc1-a163-0c2ae6c2bb55',
          organization: 'OGCIO',
          picture: 'https://lh3.googleusercontent.com/a/default-user=s96-c',
          provider: 'google',
          secondaryEmail: 'ogcio-edgeai01@cnr.ai',
          settings: `version: 1
api:
  ip: studio-dev.cnr.ai
  port: 3000
  protocol: http
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
          _id: '61692e3a4ed21d0dc27f7a3c',
          isGuest: false,
          plan: 'business',
        },
      },
    },
  },
  MTR_LRV: {
    SHOW_LIVEVIEW: true,
    CAMERA: {
      CNR01: {
        ID: 0,
        NAME: 'Depot (L)',
        STREAM: '/lrv1',
        ROTATION: 'rotate(-90deg)',
      },
      CNR02: {
        ID: 1,
        NAME: 'Depot (R)',
        STREAM: '/lrv2',
        ROTATION: 'rotate(-90deg)',
      },
      CNR03: {
        ID: 3,
        NAME: 'Roof',
        STREAM: '/lrv3',
      },
    },
  },
};
