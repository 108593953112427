export default (state = [], action) => {
  console.log('modelReducer');
  switch (action.type) {
    case 'ADD_MODEL':
      return [...state, action.model];
    case 'SET_MODELS':
      return [...action.models, 'receipt', 'bankslip'];
    case 'REMOVE_MODEL':
      return state.filter(r => r !== action.modelName);
    default:
      return state;
  }
};
