import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import LoginForm from './LoginForm';

const styles = theme => ({
  content: {
    // padding: theme.spacing(3),
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
});

const LoginMain = props => {
  const {
    classes,
    confirm,
    alert,
    onSuccess,
    handleSignInWithAppKey,
    handleSignInWithMicrosoft,
    useCase,
  } = props;

  return (
    <React.Fragment>
      <div className={clsx(classes.content)}>
        <LoginForm
           confirm={confirm}
           alert={alert}
           onSuccess={onSuccess}
           handleSignInWithAppKey={handleSignInWithAppKey}
           handleSignInWithMicrosoft={handleSignInWithMicrosoft}
           useCase={useCase}
        />
      </div>
    </React.Fragment>
  );
};

export default connect()(
  withStyles(styles)(LoginMain),
);
