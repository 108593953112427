// * Source: https://stackoverflow.com/questions/44143981/is-there-an-api-to-list-all-azure-regions
const AzureRegionList = [
  {
    displayName: 'Unknown',
    name: 'unknown',
    regionalDisplayName: 'unknown',
  },
  {
    displayName: 'East US',
    name: 'eastus',
    regionalDisplayName: '(US) East US',
  },
  {
    displayName: 'East US 2',
    name: 'eastus2',
    regionalDisplayName: '(US) East US 2',
  },
  {
    displayName: 'South Central US',
    name: 'southcentralus',
    regionalDisplayName: '(US) South Central US',
  },
  {
    displayName: 'West US 2',
    name: 'westus2',
    regionalDisplayName: '(US) West US 2',
  },
  {
    displayName: 'Australia East',
    name: 'australiaeast',
    regionalDisplayName: '(Asia Pacific) Australia East',
  },
  {
    displayName: 'Southeast Asia',
    name: 'southeastasia',
    regionalDisplayName: '(Asia Pacific) Southeast Asia',
  },
  {
    displayName: 'North Europe',
    name: 'northeurope',
    regionalDisplayName: '(Europe) North Europe',
  },
  {
    displayName: 'UK South',
    name: 'uksouth',
    regionalDisplayName: '(Europe) UK South',
  },
  {
    displayName: 'West Europe',
    name: 'westeurope',
    regionalDisplayName: '(Europe) West Europe',
  },
  {
    displayName: 'Central US',
    name: 'centralus',
    regionalDisplayName: '(US) Central US',
  },
  {
    displayName: 'North Central US',
    name: 'northcentralus',
    regionalDisplayName: '(US) North Central US',
  },
  {
    displayName: 'West US',
    name: 'westus',
    regionalDisplayName: '(US) West US',
  },
  {
    displayName: 'South Africa North',
    name: 'southafricanorth',
    regionalDisplayName: '(Africa) South Africa North',
  },
  {
    displayName: 'Central India',
    name: 'centralindia',
    regionalDisplayName: '(Asia Pacific) Central India',
  },
  {
    displayName: 'East Asia',
    name: 'eastasia',
    regionalDisplayName: '(Asia Pacific) East Asia',
  },
  {
    displayName: 'Japan East',
    name: 'japaneast',
    regionalDisplayName: '(Asia Pacific) Japan East',
  },
  {
    displayName: 'JIO India West',
    name: 'jioindiawest',
    regionalDisplayName: '(Asia Pacific) JIO India West',
  },
  {
    displayName: 'Korea Central',
    name: 'koreacentral',
    regionalDisplayName: '(Asia Pacific) Korea Central',
  },
  {
    displayName: 'Canada Central',
    name: 'canadacentral',
    regionalDisplayName: '(Canada) Canada Central',
  },
  {
    displayName: 'France Central',
    name: 'francecentral',
    regionalDisplayName: '(Europe) France Central',
  },
  {
    displayName: 'Germany West Central',
    name: 'germanywestcentral',
    regionalDisplayName: '(Europe) Germany West Central',
  },
  {
    displayName: 'Norway East',
    name: 'norwayeast',
    regionalDisplayName: '(Europe) Norway East',
  },
  {
    displayName: 'Switzerland North',
    name: 'switzerlandnorth',
    regionalDisplayName: '(Europe) Switzerland North',
  },
  {
    displayName: 'UAE North',
    name: 'uaenorth',
    regionalDisplayName: '(Middle East) UAE North',
  },
  {
    displayName: 'Brazil South',
    name: 'brazilsouth',
    regionalDisplayName: '(South America) Brazil South',
  },
  {
    displayName: 'Central US (Stage)',
    name: 'centralusstage',
    regionalDisplayName: '(US) Central US (Stage)',
  },
  {
    displayName: 'East US (Stage)',
    name: 'eastusstage',
    regionalDisplayName: '(US) East US (Stage)',
  },
  {
    displayName: 'East US 2 (Stage)',
    name: 'eastus2stage',
    regionalDisplayName: '(US) East US 2 (Stage)',
  },
  {
    displayName: 'North Central US (Stage)',
    name: 'northcentralusstage',
    regionalDisplayName: '(US) North Central US (Stage)',
  },
  {
    displayName: 'South Central US (Stage)',
    name: 'southcentralusstage',
    regionalDisplayName: '(US) South Central US (Stage)',
  },
  {
    displayName: 'West US (Stage)',
    name: 'westusstage',
    regionalDisplayName: '(US) West US (Stage)',
  },
  {
    displayName: 'West US 2 (Stage)',
    name: 'westus2stage',
    regionalDisplayName: '(US) West US 2 (Stage)',
  },
  {
    displayName: 'Asia',
    name: 'asia',
    regionalDisplayName: 'Asia',
  },
  {
    displayName: 'Asia Pacific',
    name: 'asiapacific',
    regionalDisplayName: 'Asia Pacific',
  },
  {
    displayName: 'Australia',
    name: 'australia',
    regionalDisplayName: 'Australia',
  },
  {
    displayName: 'Brazil',
    name: 'brazil',
    regionalDisplayName: 'Brazil',
  },
  {
    displayName: 'Canada',
    name: 'canada',
    regionalDisplayName: 'Canada',
  },
  {
    displayName: 'Europe',
    name: 'europe',
    regionalDisplayName: 'Europe',
  },
  {
    displayName: 'Global',
    name: 'global',
    regionalDisplayName: 'Global',
  },
  {
    displayName: 'India',
    name: 'india',
    regionalDisplayName: 'India',
  },
  {
    displayName: 'Japan',
    name: 'japan',
    regionalDisplayName: 'Japan',
  },
  {
    displayName: 'United Kingdom',
    name: 'uk',
    regionalDisplayName: 'United Kingdom',
  },
  {
    displayName: 'United States',
    name: 'unitedstates',
    regionalDisplayName: 'United States',
  },
  {
    displayName: 'East Asia (Stage)',
    name: 'eastasiastage',
    regionalDisplayName: '(Asia Pacific) East Asia (Stage)',
  },
  {
    displayName: 'Southeast Asia (Stage)',
    name: 'southeastasiastage',
    regionalDisplayName: '(Asia Pacific) Southeast Asia (Stage)',
  },
  {
    displayName: 'Central US EUAP',
    name: 'centraluseuap',
    regionalDisplayName: '(US) Central US EUAP',
  },
  {
    displayName: 'East US 2 EUAP',
    name: 'eastus2euap',
    regionalDisplayName: '(US) East US 2 EUAP',
  },
  {
    displayName: 'West Central US',
    name: 'westcentralus',
    regionalDisplayName: '(US) West Central US',
  },
  {
    displayName: 'West US 3',
    name: 'westus3',
    regionalDisplayName: '(US) West US 3',
  },
  {
    displayName: 'South Africa West',
    name: 'southafricawest',
    regionalDisplayName: '(Africa) South Africa West',
  },
  {
    displayName: 'Australia Central',
    name: 'australiacentral',
    regionalDisplayName: '(Asia Pacific) Australia Central',
  },
  {
    displayName: 'Australia Central 2',
    name: 'australiacentral2',
    regionalDisplayName: '(Asia Pacific) Australia Central 2',
  },
  {
    displayName: 'Australia Southeast',
    name: 'australiasoutheast',
    regionalDisplayName: '(Asia Pacific) Australia Southeast',
  },
  {
    displayName: 'Japan West',
    name: 'japanwest',
    regionalDisplayName: '(Asia Pacific) Japan West',
  },
  {
    displayName: 'Korea South',
    name: 'koreasouth',
    regionalDisplayName: '(Asia Pacific) Korea South',
  },
  {
    displayName: 'South India',
    name: 'southindia',
    regionalDisplayName: '(Asia Pacific) South India',
  },
  {
    displayName: 'West India',
    name: 'westindia',
    regionalDisplayName: '(Asia Pacific) West India',
  },
  {
    displayName: 'Canada East',
    name: 'canadaeast',
    regionalDisplayName: '(Canada) Canada East',
  },
  {
    displayName: 'France South',
    name: 'francesouth',
    regionalDisplayName: '(Europe) France South',
  },
  {
    displayName: 'Germany North',
    name: 'germanynorth',
    regionalDisplayName: '(Europe) Germany North',
  },
  {
    displayName: 'Norway West',
    name: 'norwaywest',
    regionalDisplayName: '(Europe) Norway West',
  },
  {
    displayName: 'Switzerland West',
    name: 'switzerlandwest',
    regionalDisplayName: '(Europe) Switzerland West',
  },
  {
    displayName: 'UK West',
    name: 'ukwest',
    regionalDisplayName: '(Europe) UK West',
  },
  {
    displayName: 'UAE Central',
    name: 'uaecentral',
    regionalDisplayName: '(Middle East) UAE Central',
  },
  {
    displayName: 'Brazil Southeast',
    name: 'brazilsoutheast',
    regionalDisplayName: '(South America) Brazil Southeast',
  },
];

export default AzureRegionList;
