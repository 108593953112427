const getPipelineSlug = (uuid, name = '') => {
  let slug = name
    .toLowerCase()
    .replace(/[^a-z0-9/-\s]/g, ' ')
    .trim()
    .replace(/-/g, ' ')
    .replace('  ', ' ')
    .replace(/\s+/g, '-');
  slug = `${slug}--${uuid}`;

  return slug;
};

const pipelineObjectToJsonString = pipeline => JSON.stringify(pipeline);

// // TODO: An experimental function to improve JSON.stringify's processing length
// const pipelineObjectToJsonString2 = pipeline => {
//   const buffer = [];

//   buffer.push('{');
//   Object.keys(pipeline).forEach(k => {
//     if (k === 'elements') return;
//     if (pipeline[k] === undefined) return;

//     buffer.push(`"${k}":`);
//     buffer.push(JSON.stringify(pipeline[k]));
//     buffer.push(',');
//   });
//   buffer.push('"elements":[');
//   buffer.push(pipeline.elements.map(r => JSON.stringify(r)).join(','));
//   buffer.push(']}');

//   return buffer.join('');
// };

const jsonToPipelineObject = jsonString => JSON.parse(jsonString);

module.exports = {
  getPipelineSlug,
  pipelineObjectToJsonString,
  jsonToPipelineObject,
};
