import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import RegisterForm from './RegisterForm';

const styles = theme => ({
  content: {
  },
});

const RegisterMain = props => {
  const { classes, onChange } = props;

  return (
    <React.Fragment>
      <div className={clsx(classes.content)}>
        <RegisterForm onChange={onChange} />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(RegisterMain);
