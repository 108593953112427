/* eslint no-console:0 no-debugger:0 */

import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from '@material-ui/core';
import Demo2 from './demo2.gif';
import Demo3 from './demo3.gif';
import Demo4 from './demo4.gif';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    margin: 0,
    flexDirection: 'row',
    width: '100%',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardRoot: {
    height: 'fit-content',
    width: '100%',
  },
  cardImg: {
    height: '250px',
    width: '100%',
  },
  cardBody: {
    width: '100%',
    padding: theme.spacing(3),
  },
}));

const IntroductionForm = props => {
  const classes = useStyles();

  const cardContent = [
    {
      title: 'Quick and easy 👌',
      description: 'Simple drag & drop interface, quick to test & deploy ML models and AI solutions.',
      imagePath: Demo2,
      alt: 'EdgeAI studio - drag & drop',
    },
    {
      title: 'State of the art 🌟',
      description: 'Discover and share state of the art ML models and AI solutions around the world.',
      imagePath: Demo3,
      alt: 'EdgeAI studio - model gallery',
    },
    {
      title: 'All-in-one platform 😍',
      description: 'Create your own AI solution pipelines using pre-trained models or train your own models!',
      imagePath: Demo4,
      alt: 'EdgeAI studio - pipeline blocks',
    },
  ];

  const renderCard = content => (
    <Fade in={true} timeout={1000}>
      <Card className={classes.cardRoot} elevation={0}>
        {content.imagePath ? <CardMedia
          alt={content.alt}
          component="img"
          image={content.imagePath}
          className={classes.cardImg}
        /> : <div></div>}
        <CardContent className={classes.cardBody}>
          <Typography gutterBottom variant="h5" component="h2">
            {content.title}
          </Typography>
          <Typography variant="body" color="textSecondary" component="p">
            {content.description}
          </Typography>
        </CardContent>
      </Card>
    </Fade>
  );

  return (
    <Grid container spacing={8} className={classes.root}>
      {cardContent.map(content => (
        <Grid item xs={12} sm={12} md={4} xl={4} className={classes.cardContainer}>
          {renderCard(content)}
        </Grid>
      ))}
    </Grid>
  );
};

export default IntroductionForm;
