import Promise from 'bluebird';
import YAML from 'yamljs';
import localforage from 'localforage';

const key = {
  EDGEAI_STUDIO_YAML: 'edgeai-studio',
  EDGEAI_STUDIO_JS: 'edgeai-studio-js',
  EDGEAI_AUTH_DATA: 'edgeai-auth-data',
};

const getItemAsync = k => localforage.getItem(k);

const setItemAsync = (k, v) => localforage.setItem(k, v);

const getEdgeAiStudioYaml = async () => getItemAsync(key.EDGEAI_STUDIO_YAML);

const setEdgeAiStudioYaml = async value => {
  let json = null;

  try {
    json = YAML.parse(value);
    window.edgeAiStudioConfig = json;
    return await Promise.all([
      setItemAsync(key.EDGEAI_STUDIO_YAML, value),
      setItemAsync(key.EDGEAI_STUDIO_JS, json),
    ]);
  } catch (e) {
    console.error(e);
    throw new Error('setEdgeAiStudioYaml error');
  }
};

const loadEnvironment = async () => {
  const profile = await getEdgeAiStudioJs();
  const authData = await getAuthDataAsync();
  window.edgeAiStudioConfig = profile;
  window.edgeAiStudioApiKey = authData.key;
  return { profile, authData, apiKey: authData.key };
};

// const setEdgeAiStudioJs = async json => {
//   window.edgeAiStudioConfig = json;
//   const yaml = YAML.stringify(json);
//   await Promise.all([
//     setItemAsync(key.EDGEAI_STUDIO_YAML, yaml),
//     setItemAsync(key.EDGEAI_STUDIO_JS, json),
//   ]);
// };

const getEdgeAiStudioJs = async () => getItemAsync(key.EDGEAI_STUDIO_JS);

const getEngineAzureCv = async () => (await getItemAsync(key.EDGEAI_STUDIO_JS))?.engine?.azureCv;

const setAuthDataAsync = async data => setItemAsync(key.EDGEAI_AUTH_DATA, data);
const getAuthDataAsync = async () => getItemAsync(key.EDGEAI_AUTH_DATA);

export {
  key,
  getItemAsync,
  setItemAsync,
  getEdgeAiStudioYaml,
  setEdgeAiStudioYaml,
  getEdgeAiStudioJs,
  getEngineAzureCv,
  getAuthDataAsync,
  setAuthDataAsync,
  loadEnvironment,
};
