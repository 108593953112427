import Promise from 'bluebird';
import fetch from 'node-fetch';

const efetch = (...args) => {
  args[1] = args[1] ?? {};
  args[1].headers = args[1].headers ?? {};
  args[1].headers['x-edgeai-key'] = window.edgeAiStudioApiKey;
  return fetch(...args);
};

const saveToApiServerAsync = async (id, properties, codes, thumbnail, endpointUrl = null) => {
  const url = endpointUrl || `${getEndpointUrl()}/pipeline/${id}`;
  const res = await efetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      properties,
      codes,
      thumbnail,
    }),
  });

  if (res.status !== 200) {
    const error = await res.json();
    throw new Error(error.code);
  }

  const r = await res.json();

  if (!r.success) {
    throw new Error(r.error.code);
  }
};

const getEndpointProtocol = () => {
  let protocol = 'https'; // *  Default https
  protocol = window.edgeAiStudioConfig?.protocol || 'https'; // * We have protocol settings of custom API
  const { ip } = window.edgeAiStudioConfig.api;
  if (ip === 'localhost' || ip === '127.0.0.1' || ip === 'studio-dev.cnr.ai') protocol = 'http';
  if (protocol !== 'http' && protocol !== 'https') protocol = 'https';
  return protocol;
};

const getEndpointUrl = () => {
  // this exception is for LRV
  // if (window.edgeAiStudioApiKey === 'ffc1960d-7c90-4705-8819-238f919449eb'
  //   || window.edgeAiStudioApiKey === '680bd2bf-399b-4c13-afbd-c06096544787') {
  //   return '/api/v1';
  // }

  if (window.edgeAiStudioConfig?.api) {
    const { ip, port } = window.edgeAiStudioConfig.api;
    if (getEndpointProtocol() === 'https' && port === 443) {
      return `${getEndpointProtocol()}://${ip}/api/v1`;
    }
    return `${getEndpointProtocol()}://${ip}:${port}/api/v1`;
  }
  return '/api/v1';
};

const getEndpointRootUrl = () => {
  const { ip, port } = window.edgeAiStudioConfig.api;
  return `${getEndpointProtocol()}://${ip}:${port}`;
};

const getEndpointHost = () => {
  const { ip, port } = window.edgeAiStudioConfig.api;
  return `${ip}:${port}`;
};

const getPipelinesAsync = async () => {
  try {
    const ep = getEndpointUrl();
    const res = await efetch(`${ep}/pipeline`);
    return res.json();
  } catch (e) {
    // TODO May need unified BE error object and message to improve UX
    throw new Error('non-200');
  }
};

const removePipelineAsync = async uuid => (await efetch(`${getEndpointUrl()}/pipeline/${uuid}`, {
  method: 'DELETE',
})).json();

const pingAsync = async () => {
  const res = await efetch(`${getEndpointUrl()}/ping`);
  return res.json();
};

const findPipelineAsync = async (author, name) => {
  const res = await efetch(`${getEndpointUrl()}/pipeline/u/${author}/${name}`);
  return res.json();
};

export {
  saveToApiServerAsync,
  pingAsync,
  getPipelinesAsync,
  getEndpointUrl,
  getEndpointProtocol,
  getEndpointHost,
  getEndpointRootUrl,
  removePipelineAsync,
  findPipelineAsync,
  efetch,
};
