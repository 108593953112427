module.exports = {
  API_KEY: '',
  AUTHDATA: {
    settings: `version: 1
api:
  ip: studio.cnr.ai
  port: 443
  protocol: https
neurals:
  - ip: 127.0.0.1
    port: 8090
engine:
  azureCv: 
    region: unknown
    trainingKey: unknown
`,
  },
};
