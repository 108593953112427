import React, { useState } from 'react';
import {
  Tooltip,
  IconButton,
  Zoom,
} from '@material-ui/core';

export default function TouchableIconButton(props) {
  const [isHover, setIsHover] = useState(false);

  const handleClick = e => {
    setIsHover(false);
    if (props.onClick) props.onClick(e);
  };

  if (props.tooltip) {
    return <Tooltip
        title={props.tooltip}
        disableHoverListener
        disableFocusListener
        open={isHover}
        TransitionComponent={Zoom}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
      <IconButton {...props}
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {props.children}
      </IconButton>
    </Tooltip>;
  }

  return <IconButton {...props}
      onTouchStart={handleClick}
      onClick={handleClick}
    >
    {props.children}
  </IconButton>;
}
