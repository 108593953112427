import React from 'react';
import Update from '@material-ui/icons/Update';
import ChartistGraph from 'react-chartist';
import { Typography } from '@material-ui/core';

import GridItem from './Grid/GridItem';
import Card from './Card/Card';
import CardBody from './Card/CardBody';
import CardHeader from './Card/CardHeader';
import CardFooter from './Card/CardFooter';

import { chartConfiguration } from './variables/charts';

const Graph = ({
  md, classes, label, data, color, children, ...rest
}) => (
  <GridItem xs={12} sm={12} md={6}>
    <Card
    // style={{ marginBottom: 0 }}
    chart>
      <CardHeader color={color}>
        <ChartistGraph
          className="ct-chart"
          data={data}
          type="Line"
          options={chartConfiguration.options}
          {...rest}
        />
      </CardHeader>
      <CardBody>
        <Typography className={classes.cardTitle}
        style={{
          color: '#999', // TODO should refer to the palette
        }}
        >{label}</Typography>
      </CardBody>
      <CardFooter chart>
        <div className={classes.stats}>
          <Update />
          Just updated
        </div>
      </CardFooter>
    </Card>
  </GridItem>
);

export default Graph;
