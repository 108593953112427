/* eslint no-console:0 no-debugger:0 */
import Promise from 'bluebird';
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import uuid from 'uuid';

import {
  FavoriteIcon,
  UnfavoriteIcon,
  TickIcon,
  AddIcon,
  ShareIcon,
  ExpandMoreIcon,
  MoreVertIcon,
  IconButton,
  Star1Icon,
  Star0Icon,
  Star05Icon,
  ApiIcon,
  SizeIcon,
  PriceIcon,
  NewIcon,
  ForkIcon,
  TrashIcon,
  FilesIcon,
} from '../Blocks/CommonIcons';

import TouchableIconButton from '../Blocks/TouchableIconButton';
import ModelLoader from '../Blocks/ModelLoader';
import {
  getPipelinesAsync,
  removePipelineAsync,
  getEndpointUrl,
} from './ApiServerInterface';
import { getAuthDataAsync, setAuthDataAsync } from '../Blocks/LocalStorage';
import ShareDialog from './ShareDialog';
import { getPipelineSlug } from '../utils/pipelineUtil';
import rainbowImage from '../images/rainbow.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100%',
    textAlign: 'left',
    padding: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  media: {
    height: 180,
    // paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardStyle: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
    backgroundColor: theme.palette.primary.main,
    border: theme.palette.component.borderOutline,
  },
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2, // lines to show
    '-webkit-box-orient': 'vertical',
    height: 44,
  },
  singleLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1, // lines to show
    '-webkit-box-orient': 'vertical',
    height: 22,
  },
}));

const modelType = {
  'object-detector': <>
    Object detector
  </>,
  ocr: 'OCR',
  pose: 'Pose',
};

const WorkspaceForm = props => {
  const {
    confirm,
    alert,
    backdrop,
    onSelect,
    theme: topTheme,
  } = props;
  const readOnly = window.edgeAiStudioApiKey === '32b7895d-b281-4cc1-a163-0c2ae6c2bb55';
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  // const [topKeywords, setTopKeywords] = React.useState(['object', 'ocr', 'pose']);
  const [topKeywords, setTopKeywords] = React.useState([]);
  const [pipelines, setPipelines] = React.useState([]);
  const [filteredPipelines, setFilteredPipelines] = React.useState([]);
  const [keywords, setKeywords] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentPipelineId, setCurrentPipelineId] = React.useState(null);
  const [author, setAuthor] = useState(null);
  const [isGuest, setIsGuest] = useState(false);
  const workspaceLocationRef = useRef('studio.cnr.ai');
  const dirHandleRef = useRef(null);
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const location = useLocation();
  const filterTimeout = useRef();
  const defaultModels = [
    {
      name: 'Default',
      type: 'object-detector',
      description: <>
        This model detects 64 different objects and returns bounding boxes with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star05Icon /></>} label="4.8" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='250 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'1M - 10M'} />
      </>,
      format: 'CoreML',
      url: null,
      developer: 'C&R Wise AI',
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
        size: '250 MB',
      },
      favorite: true,
      added: true,
      imagePath: '/static/images/default.jpeg',
    },
    {
      name: 'Chinese OCR',
      type: 'ocr',
      description: <>
        This model recognizes text strings and returns bounding boxes with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star1Icon /></>} label="5.0" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='250 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'10M - 100M'} />
      </>,
      format: 'CoreML',
      url: null,
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
      },
      favorite: false,
      added: false,
      parameters: {
        language: 'Support multiple languages',
      },
      imagePath: '/static/images/ocr.jpg',
    },
    {
      name: 'Pose',
      type: 'pose',
      description: <>
        This model recognizes 17 keypoints in multiple human poses with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star1Icon /></>} label="5.0" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='100 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'10M - 100M'} />
      </>,
      format: 'CoreML',
      url: null,
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
      },
      favorite: false,
      added: false,
      parameters: {
        language: 'Support multiple languages',
      },
      imagePath: '/static/images/pose.jpg',
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        backdrop(true);
        const authData = await getAuthDataAsync();
        setAuthor(authData.author);
        setIsGuest(authData.isGuest);
        await loadPipelines();
        backdrop(false);
      }
      catch (e) {
        confirm(
          'Server unavailable',
          'Server is unavailable now. Please try again later.',
          'ok',
          async () => {
            await setAuthDataAsync(null);
            window.location.href = '/';
          },
        );
      }
    })();
  }, []);

  useEffect(() => {
    const searchQuery = query.get('search');
    setKeywords(searchQuery);
    debounceSetFilterPipeline(searchQuery);
  }, [pipelines, topTheme]);

  const handleClickNew = async event => {
    const newPipelineId = uuid.v4();
    if (workspaceLocationRef.current === 'file') {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: `${newPipelineId}.json`,
      });
      const writable = await fileHandle.createWritable();
      await writable.write(JSON.stringify({
        pipelineId: newPipelineId,
        properties: {},
      }));
      await writable.close();
      onSelect(newPipelineId, fileHandle);
    }
    else {
      onSelect(newPipelineId);
    }
  };

  const handleClickOpenFolder = async event => {
    if (typeof window.showDirectoryPicker !== 'function') {
      alert('File access permission has not been granted. Please grant or use another browser.');
      return;
    }

    dirHandleRef.current = await window.showDirectoryPicker();

    await loadPipelinesFromFile();
  };

  const loadPipelinesFromFile = async () => {
    if (!dirHandleRef.current) return;

    const localPipelines = [];

    for await (const [filename, fileHandle] of dirHandleRef.current.entries()) {
      if (!['manifest.json', 'package.json'].includes(filename) && filename.endsWith('.json')) {
        const fileData = await fileHandle.getFile();
        const thePipeline = JSON.parse(await fileData.text());
        const file = await fileHandle.getFile();
        localPipelines.push({
          ...thePipeline.properties,
          id: thePipeline.pipelineId,
          size: file.size,
          storageLocation: 'file', // if not specified, we assume on the cloud
          fileHandle,
          filename,
          ts: file.lastModified,
        });
        localPipelines.sort((a, b) => (a.ts > b.ts ? -1 : 1));
      }
    }

    workspaceLocationRef.current = 'file';
    setFilteredPipelines(
      localPipelines.map((model, i) => (
        <Grid key={`model-${i}`} item xs={12} sm={6} md={4} lg={3}>
          {renderCard(model)}
        </Grid>
      )),
    );
  };

  const handleClickAdd = pipeline => async () => {
    onSelect(pipeline.id);
  };

  const handleClickOpen = pipeline => () => {
    onSelect(pipeline.id, pipeline.fileHandle);
  };

  const handleClickShare = pipeline => () => {
    const slug = getPipelineSlug(pipeline.id, pipeline.name);
    const shareUrl = `https://studio.cnr.ai/u/${pipeline.author}/${slug}`;
    confirm(
      'Share this pipeline',
      <ShareDialog shareUrl={shareUrl} />,
      'ok',
      res => {
        if (res === 'ok') {
          // onElementsChanged();
        }
      },
    );
  };

  const filterPipelineMatched = (defaultPipelines, searchString) => {
    if (searchString && pipelines.length > 0) {
      let containsIsPrivate = false;
      let containsIsPublic = false;
      if (searchString.includes('is:private')) {
        searchString = searchString.replace('is:private', '');
        containsIsPrivate = true;
      }
      if (searchString.includes('is:public')) {
        searchString = searchString.replace('is:public', '');
        containsIsPublic = true;
      }

      const searchTerms = searchString.toLowerCase().split(' ').filter(term => term.trim() !== '');
      const filtered = defaultPipelines.filter(pipeline => {
        const pipelineContentsString = `
          ${(pipeline.name || '').toString().toLowerCase()}
          ${(pipeline.description || '').toString().toLowerCase()}
          ${(pipeline.author || '').toString().toLowerCase()}
        `.replace(/\n/g, ' ');
        const searchRegex = new RegExp(searchRegexBuilder(searchTerms, 'm'));
        return searchRegex.test(pipelineContentsString)
          && (containsIsPrivate ? pipeline.private === true : true)
          && (containsIsPublic ? pipeline.private === false : true);
      });
      return filtered;
    }
    return defaultPipelines;
  };

  const searchRegexBuilder = terms => {
    const prefix = '^';
    const suffix = '.*$';
    const termsRegex = terms.map(term => `(?=.*${term})`).join('');
    return `${prefix}${termsRegex}${suffix}`;
  };

  const loadPipelines = async (retries = 5) => {
    try {
      const res = await getPipelinesAsync();
      if (res.error) {
        throw new Error(res.error);
      }
      setPipelines(res);
      setFilteredPipelines(
        res.map((model, i) => (
          <Grid key={`model-${i}`} item xs={12} sm={6} md={4} lg={3}>
            {renderCard(model)}
          </Grid>
        )),
      );
    } catch (e) {
      if (retries > 0) {
        await Promise.delay(1500);
        await loadPipelines(retries - 1);
      } else {
        confirm(
          'Server unavailable',
          'Server is unavailable now. Please try again later.',
          'ok',
          async () => {
            await setAuthDataAsync(null);
            window.location.href = '/';
          },
        );
      }
    }
  };

  const handleClickRemove = pipeline => async event => {
    const { id: pipelineId } = pipeline;
    setCurrentPipelineId(pipelineId);
    confirm(
      'Confirmation',
      'Are you sure that you want to remove this pipeline?',
      'yesno',
      async res => {
        if (res === 'close') return true;
        if (res === 'no') return true;
        if (workspaceLocationRef.current === 'studio.cnr.ai') {
          const r = await removePipelineAsync(pipelineId);
          if (!r.success) {
            if (r.error.code === 'author-not-matched') {
              alert('You cannot remove the pipeline owned by other.', 'error');
              handleClose(event);
              return true;
            }
            alert('Unable to remove the pipeline. Please check error logs.', 'error');
          }

          // remove thumbnail from blob
          try {
            const key = window.edgeAiStudioApiKey;
            await fetch(`${getEndpointUrl()}/blob/${key}/${pipelineId}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'X-EdgeAI-Key': window.edgeAiStudioApiKey,
              },
            });
          }
          catch (e) {
            console.error(e);
          }
        }
        else {
          await dirHandleRef.current.removeEntry(pipeline.filename);
        }
        handleClose(event);
        if (workspaceLocationRef.current === 'studio.cnr.ai') {
          await loadPipelines(5);
        }
        else {
          await loadPipelinesFromFile();
        }
        alert(`Deleted your Pipeline : ${pipeline.name || 'untitled pipeline'}`, 'success');
        return true;
      },
    );
  };

  const handleClose = event => {
    // event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = pipelineId => event => {
    // event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentPipelineId(pipelineId);
  };

  const handleSearchChange = e => {
    setKeywords(e.target.value);
    if (e.target.value) {
      const params = new URLSearchParams({ search: e.target.value });
      history.replace({
        pathName: location.pathname,
        search: params.toString(),
      });
    } else {
      const params = new URLSearchParams({});
      history.replace({
        pathName: location.pathname,
        search: params.toString(),
      });
    }
    debounceSetFilterPipeline(e.target.value);
  };

  const debounceSetFilterPipeline = searchTerm => {
    clearTimeout(filterTimeout.current);
    if (!searchTerm) {
      setFilteredPipelines(
        pipelines.map((model, i) => (
          <Grid key={`model-${i}`} item xs={12} sm={6} md={4} lg={3}>
            {renderCard(model)}
          </Grid>
        )),
      );
    } else {
      filterTimeout.current = setTimeout(() => {
        setFilteredPipelines(
          filterPipelineMatched(pipelines, searchTerm).map((model, i) => (
            <Grid key={`model-${i}`} item xs={12} sm={6} md={4} lg={3}>
              {renderCard(model)}
            </Grid>
          )),
        );
      }, 400);
    }
  };

  const renderCard = pipeline => (
    <Card className={classes.cardStyle} elevation={0}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {pipeline.author.substr(0, 3)}
          </Avatar>
        }
        action={
          <>
            {/* <TouchableIconButton className={classes.button} aria-label="settings"
              onClick={handleClick(pipeline.id)}>
              <MoreVertIcon />
            </TouchableIconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
            </Menu> */}

            {pipeline.author === author && !readOnly
              ? <IconButton
                aria-label="delete"
                onClick={handleClickRemove(pipeline)}
                disabled={isGuest}
              >
                <TrashIcon />
              </IconButton>
              : false
            }
          </>
        }
        title={<div className={classes.singleLineEllipsis}>{pipeline.name}</div>}
        subheader={pipeline.author || 'cnr'}
      />
      {pipeline.thumbnail
        ? [
          <CardMedia
            className={classes.media}
            image={pipeline.thumbnail}
          />,
          <CardContent>
            <Typography
              className={classes.multiLineEllipsis}
              variant="body2"
              color="textSecondary"
              component="p">
              {pipeline.description}&nbsp;{pipeline.slug}
            </Typography>
          </CardContent>,
        ]
        : [
          <div style={{ position: 'relative' }}>
            <CardMedia
              className={classes.media}
              image={pipeline.thumbnail ?? rainbowImage}
            />
            <Typography
              className={classes.multiLineEllipsis}
              variant="body1"
              color="textSecondary"
              component="div"
              style={{
                marginTop: -180,
                display: 'table',
                width: '100%',
                position: 'absolute',
              }}>
              <Box
                sx={{ textAlign: 'center', m: 1 }}
                style={{
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  height: 180,
                  color: 'black',
                }}>
                {pipeline.name}
              </Box>
            </Typography>
          </div>,
          <CardContent>
            <Typography
              className={classes.multiLineEllipsis}
              variant="body2"
              color="textSecondary"
              component="p">
              {pipeline.description}&nbsp;{pipeline.slug}
            </Typography>
          </CardContent>,
        ]}
      <CardActions disableSpacing>
        <Chip
          className={classes.chip}
          icon={<SizeIcon />}
          label={pipeline.size ? `(${Math.round(pipeline.size / 1024 / 1024 * 10) / 10} MB) ` : false} />
        {workspaceLocationRef.current === 'studio.cnr.ai'
          ? <IconButton
            aria-label="share"
            style={{ marginLeft: 'auto' }}
            onClick={handleClickShare(pipeline)}
          >
            <ShareIcon />
          </IconButton> : false}
        <IconButton
          aria-label="fork"
          style={workspaceLocationRef.current === 'file' ? { marginLeft: 'auto' } : {}}
          onClick={handleClickOpen(pipeline)}
        >
          {pipeline.author !== author ? <ForkIcon /> : <NewIcon />}
        </IconButton>
        {/* TODO Add to the canvas */}
        {/* <IconButton aria-label="share" onClick={handleClickAdd(pipeline)}>
          {pipeline.added ? <TickIcon /> : <AddIcon />}
        </IconButton> */}
        {/* <IconButton aria-label="add to favorites">
          {pipeline.favorite ? <FavoriteIcon /> : <UnfavoriteIcon />}
        </IconButton> */}
      </CardActions>
    </Card>
  );

  const renderSearchBar = () => (
    <Autocomplete
      freeSolo
      disableClearable
      options={topKeywords}
      inputValue={keywords || ''}
      renderInput={params => (
        <TextField
          {...params}
          label="Search keywords"
          margin="normal"
          variant="outlined"
          InputProps={{ ...params.InputProps }}
          value={keywords || ''}
          onChange={handleSearchChange}
        />
      )}
    />
  );

  const renderUpload = () => (
    <ModelLoader color="red" confirm={confirm} alert={alert} />
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Typography variant="h5">Pipelines</Typography>
          <TouchableIconButton
            style={{ width: 33, height: 33, marginLeft: 8 }}
            onClick={handleClickNew}
            disabled={isGuest}>
            <AddIcon />
          </TouchableIconButton>
          <TouchableIconButton
            style={{ width: 33, height: 33, marginLeft: 8 }}
            onClick={handleClickOpenFolder}
            disabled={isGuest}>
            <FilesIcon />
          </TouchableIconButton>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderSearchBar()}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {filteredPipelines}
        {/* <Grid key='new' item xs={4}>
          {renderUpload()}
        </Grid> */}
      </Grid>

    </div>
  );
};

export default WorkspaceForm;
