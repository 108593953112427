import React, { useState, useEffect } from 'react';
import YAML from 'yamljs';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';

import {
  SaveIcon,
  ExpandMoreIcon,
  TestIcon,
  RestoreIcon,
} from '../Blocks/CommonIcons';
import azureRegionList from '../utils/azureRegionList';
import { getAuthDataAsync, getEdgeAiStudioYaml } from '../Blocks/LocalStorage';
import { patchAuth, updateSetting } from '../api/auth.api';
import { ping, pingAzure } from '../api/ping.api';

const SettingsForm2 = props => {
  const { classes, alert, onThemeChange } = props;
  const defaultFormValue = {
    familyName: '',
    givenName: '',
    email: '',
    secondaryEmail: '',
    organization: '',
    department: '',
    contact: '',
    author: '',
    key: '',
    plan: '',
  };
  const defaultSettings = {
    version: 1,
    api: {
      ip: '',
      port: '',
      protocol: 'https',
    },
    neurals: [{
      protocol: 'http',
      ip: '',
      port: '',
    }],
    engine: {
      azureCv: {
        region: 'unknown',
        trainingKey: 'unknown',
      },
    },
  };
  const DEFAULT_NEURAL_IP = '127.0.0.1';
  const DEFAULT_NEURAL_PORT = 8090;
  const DEFAULT_API_IP = 'studio.cnr.ai'; // TODO Need to change base on env
  const DEFAULT_API_PORT = 443; // TODO Need to change base on env
  const PING_PATH = 'api/v1/ping';
  const NEURAL_PING_PATH = 'neural/v1/ping';
  const VERIFIED_IP_LIST = ['studio.cnr.ai', 'studio-uat.cnr.ai'];
  const [expanded, setExpanded] = useState(null);
  const [isGuest, setIsGuest] = useState(false);
  const [planDescription, setPlanDescription] = useState('Unknown Plan');
  const [azureRegionSelected, setAzureRegionSelected] = useState(azureRegionList[0]);
  const [form, setForm] = useState(defaultFormValue);
  const [settingsForm, setSettingsForm] = useState(defaultSettings);
  const [neural, setNeural] = useState(defaultSettings.neurals[0]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isValidAPI, setIsValidAPI] = useState(true);
  const [isValidAzure, setIsValidAzure] = useState(true);
  const [isValidNeural, setIsValidNeural] = useState(true);
  const [isForceHttps, setIsForceHttps] = useState(false);
  const expandMap = {
    name: 'name',
    organization: 'organization',
    account: 'account',
    subscription: 'subscription',
    theme: 'theme',
    api: 'api',
    azure: 'azure',
    neural: 'neural',
  };
  const themeMap = {
    'system-theme': 'Use System Preference',
    light: 'Light',
    dark: 'Dark',
  };

  const changePlanDescription = plan => {
    switch (plan) {
      case 'business':
        setPlanDescription('Studio Business');
        break;
      case 'free':
        setPlanDescription('Studio Community');
        break;
      case 'enterprise':
        setPlanDescription('Studio Enterprise');
        break;
      default:
        setPlanDescription('Unknown Plan');
        break;
    }
  };

  const handleSave = async e => {
    if (!assertRegistration()) return;
    alert('Saving settings ...', 'info');
    try {
      await patchAuth(
        form.email,
        form.givenName, form.familyName, form.secondaryEmail,
        form.organization, form.department, form.contact, form.theme,
      );
      await updateSetting(settingsForm);
      setIsUpdated(false);
      await resolveFormState();
      alert('Saved successfully.', 'success');
    } catch (err) {
      setIsUpdated(false);
      alert('Settings cannot be saved successfully.  Please check error logs.', 'error');
    }
  };

  const assertRegistration = () => {
    if (!isValidAPI) {
      alert('Please test API endpoint before saving.');
      return false;
    }
    if (!isValidAzure) {
      alert('Please test Azure custom vision connectivity before saving.');
      return false;
    }
    if (!isValidNeural) {
      alert('Please test neural engine endpoint before saving.');
      return false;
    }
    if (!form.givenName || form.givenName.trim().length === 0) {
      alert('Please input first name.');
      return false;
    }
    if (!form.familyName || form.familyName.trim().length === 0) {
      alert('Please input last name.');
      return false;
    }
    if (!form.organization || form.organization.trim().length === 0) {
      alert('Please input organization name.');
      return false;
    }
    if (!form.contact || form.contact.trim().length === 0) {
      alert('Please input contact number.');
      return false;
    }
    if (!form.author || form.author.trim().length === 0) {
      alert('Please input author name.');
      return false;
    }
    if (!/^[a-zA-Z0-9]+$/.test(form.author)) {
      alert('Author name can only comprise of alphabets and numbers only. Please try another one.');
      return false;
    }
    return true;
  };

  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleFormChange = e => {
    e.persist();
    setIsUpdated(true);
    setForm(prevState => ({ ...prevState, [e.target.id]: e.target.value }));
  };

  const handleAPIChange = e => {
    e.persist();
    setIsUpdated(true);
    setIsValidAPI(false);
    if (e.target.id === 'api-ip') {
      let prevProtocol = settingsForm.api.protocol;
      if (VERIFIED_IP_LIST.includes(e.target.value)) {
        prevProtocol = 'https';
        setIsForceHttps(true);
      } else {
        setIsForceHttps(false);
      }
      const prevPort = settingsForm.api.port;
      setSettingsForm(preState => ({
        ...preState,
        api: {
          protocol: prevProtocol,
          ip: e.target.value,
          port: prevPort,
        },
      }));
    } else if (e.target.id === 'api-port') {
      const prevProtocol = settingsForm.api.protocol;
      const prevIP = settingsForm.api.ip;
      setSettingsForm(preState => ({
        ...preState,
        api: {
          protocol: prevProtocol,
          ip: prevIP,
          port: e.target.value,
        },
      }));
    } else if (e.target.name === 'api-protocol') {
      const prevIP = settingsForm.api.ip;
      const prevPort = settingsForm.api.port;
      setSettingsForm(preState => ({
        ...preState,
        api: {
          protocol: e.target.value,
          ip: prevIP,
          port: prevPort,
        },
      }));
    }
  };

  const handleAPITest = async e => {
    try {
      alert('Testing API endpoint...', 'info');
      const port = settingsForm.api.port ? `:${settingsForm.api.port}` : '';
      const url = `${settingsForm.api.protocol}://${settingsForm.api.ip}${port}/${PING_PATH}`;
      const res = await ping(url);
      if (res) {
        setIsValidAPI(true);
        alert('API is good to go.', 'success');
      } else {
        setIsValidAPI(false);
        alert('Tested Failed', 'error');
      }
    } catch (error) {
      setIsValidAPI(false);
      alert('Tested Failed', 'error');
    }
  };

  const handleRestoreAPI = e => {
    const defaultAPI = {
      protocol: 'https',
      ip: DEFAULT_API_IP,
      port: DEFAULT_API_PORT,
    };
    setSettingsForm(preState => ({ ...preState, api: defaultAPI }));
  };

  const handleAzureChange = (e, option) => {
    e.persist();
    setIsValidAzure(false);
    setIsUpdated(true);
    if (e.target.id.startsWith('azure-region')) {
      setAzureRegionSelected(option);
      const newAzure = {
        azureCv: {
          region: option.name,
          trainingKey: settingsForm.engine.azureCv.trainingKey,
        },
      };
      setSettingsForm(prevState => ({ ...prevState, engine: newAzure }));
    } else if (e.target.id === 'azure-train-key') {
      const newAzure = {
        azureCv: {
          region: settingsForm.engine.azureCv.region,
          trainingKey: e.target.value,
        },
      };
      setSettingsForm(prevState => ({ ...prevState, engine: newAzure }));
    }
  };

  const handleAzureTest = async e => {
    try {
      alert('Testing Azure custom vision connectivity ...', 'info');
      const res = await pingAzure(
        settingsForm.engine.azureCv.region,
        settingsForm.engine.azureCv.trainingKey,
      );
      if (res.ok) {
        setIsValidAzure(true);
        alert('Azure custom vision connectivity is good to go.', 'success');
      } else {
        alert('Azure custom vision connectivity tested failed', 'error');
        setIsValidAzure(false);
      }
    } catch (error) {
      alert('Azure custom vision connectivity tested failed', 'error');
      setIsValidAzure(false);
    }
  };

  const handleRestoreAzure = e => {
    e.persist();
    setIsValidAzure(true);
    setIsUpdated(true);
    setAzureRegionSelected(azureRegionList[0]);
    const newAzure = {
      azureCv: {
        region: azureRegionList[0].name,
        trainingKey: 'unknown',
      },
    };
    setSettingsForm(prevState => ({ ...prevState, engine: newAzure }));
  };

  const handleRestoreNeural = e => {
    setIsUpdated(true);
    setIsValidNeural(true);
    setSettingsForm(prevState => ({ ...prevState, neurals: [defaultSettings.neurals[0]] }));
    setNeural(defaultSettings.neurals[0]);
    setIsValidNeural(true);
  };

  const handleNeuralChange = e => {
    e.persist();
    setIsUpdated(true);
    setIsValidNeural(false);
    if (e.target.id === 'neural-ip') {
      const prevProtocol = settingsForm.neurals[0].protocol;
      const prevPort = settingsForm.neurals[0].port;
      setSettingsForm(preState => ({
        ...preState,
        neurals: [{
          protocol: prevProtocol,
          ip: e.target.value,
          port: prevPort,
        }],
      }));
      setNeural({
        protocol: prevProtocol,
        ip: e.target.value,
        port: prevPort,
      });
    } else if (e.target.id === 'neural-port') {
      const prevProtocol = settingsForm.neurals[0].protocol;
      const prevIP = settingsForm.neurals[0].ip;
      setSettingsForm(preState => ({
        ...preState,
        neurals: [{
          protocol: prevProtocol,
          ip: prevIP,
          port: e.target.value,
        }],
      }));
      setNeural({
        protocol: prevProtocol,
        ip: prevIP,
        port: e.target.value,
      });
    } else if (e.target.name === 'neural-protocol') {
      const prevIP = settingsForm.neurals[0].ip;
      const prevPort = settingsForm.neurals[0].port;
      setSettingsForm(preState => ({
        ...preState,
        neurals: [{
          protocol: e.target.value,
          ip: prevIP,
          port: prevPort,
        }],
      }));
      setNeural({
        protocol: e.target.value,
        ip: prevIP,
        port: prevPort,
      });
      if (!prevIP && !prevPort && e.target.value === 'http') {
        // * Check if equal to default neurals setting
        setIsValidNeural(true);
      }
    }
  };

  const handleNeuralTest = async e => {
    try {
      alert('Testing neural engine...', 'info');
      const { protocol, ip } = settingsForm.neurals[0];
      const port = settingsForm.neurals[0].port ? `:${settingsForm.neurals[0].port}` : '';
      let pingPath = '';
      if (VERIFIED_IP_LIST.includes(ip) || ip.startsWith('localhost')) {
        pingPath = NEURAL_PING_PATH;
      } else {
        pingPath = PING_PATH;
      }
      const url = `${protocol}://${ip}${port}/${pingPath}`;
      const res = await ping(url);
      if (res) {
        setIsValidNeural(true);
        alert('Neural engine is good to go.', 'success');
      } else {
        setIsValidNeural(false);
        alert('Tested neural Engine failed', 'error');
      }
    } catch (err) {
      console.error(err);
      setIsValidNeural(false);
      alert('Tested neural Engine failed', 'error');
    }
  };

  const resolveFormState = async () => {
    const authData = await getAuthDataAsync();
    const settingsYAML = await getEdgeAiStudioYaml();
    const settingsJSON = YAML.parse(settingsYAML);
    if (
      !settingsJSON.api.protocol || settingsJSON.api.protocol !== 'https' || settingsJSON.api.protocol !== 'http'
    ) {
      settingsJSON.api.protocol = 'https';
    }
    const selectedRegion = azureRegionList.filter(
      option => option.name === settingsJSON.engine.azureCv.region,
    );
    if (settingsJSON.neurals) {
      if (settingsJSON.neurals[0].ip || settingsJSON.neurals[0].port) {
        setSettingsForm({ ...settingsJSON });
        setNeural(settingsJSON.neurals[0]);
      } else {
        setSettingsForm(
          {
            ...settingsJSON,
            neurals: [{
              protocol: 'http',
              ip: '',
              port: '',
            }],
          },
        );
      }
    } else {
      setSettingsForm({ ...settingsJSON, neurals: [defaultSettings.neurals[0]] });
      setNeural(defaultSettings.neurals[0]);
    }
    if (VERIFIED_IP_LIST.includes(settingsJSON.api.ip)) {
      setIsForceHttps(true);
    } else {
      setIsForceHttps(false);
    }
    setForm({
      familyName: authData.familyName,
      givenName: authData.givenName,
      organization: authData.organization,
      department: authData.department,
      contact: authData.contact,
      email: authData.email,
      secondaryEmail: authData.secondaryEmail,
      author: authData.author,
      key: authData.key,
      plan: authData.plan || 'free',
      theme: authData.theme || 'system-theme',
    });
    setIsGuest(false || authData.isGuest);
    changePlanDescription(authData.plan || 'free');
    setAzureRegionSelected(selectedRegion[0]);
  };

  const handleThemeChange = event => {
    const theme = event.target.value;
    if (form.theme === theme) return;
    setForm({
      ...form,
      theme,
    });
    onThemeChange(theme);
    setIsUpdated(true);
  };

  useEffect(() => {
    (async () => {
      await resolveFormState();
    })();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Toolbar className={classes.appBar}>
          <Button
            disabled={!isUpdated}
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={handleSave}
          >
            <SaveIcon className={classes.fixButtonText} />
          </Button>
        </Toolbar>
      </AppBar>
      <Grid className={classes.gridRoot}>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === expandMap.name}
            onChange={handleExpand(expandMap.name)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>Name</Typography>
              <Typography className={classes.secondaryHeading}>
                {form.givenName} {form.familyName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="givenName"
                          label="First name*"
                          variant="outlined"
                          value={form.givenName}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="familyName"
                          label="Last name*"
                          variant="outlined"
                          value={form.familyName}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.organization}
            onChange={handleExpand(expandMap.organization)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>Organization</Typography>
              <Typography className={classes.secondaryHeading}>
                {[form.department, form.organization].filter(r => !!r).join(', ')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="organization"
                          label="Organization name*"
                          variant="outlined"
                          value={form.organization}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="department"
                          label="Department"
                          variant="outlined"
                          value={form.department}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                    <div className="g-flex-break"></div>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="contact"
                          label="Contact number*"
                          variant="outlined"
                          value={form.contact}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.account}
            onChange={handleExpand(expandMap.account)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>Account</Typography>
              <Typography className={classes.secondaryHeading}>{form.email}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled
                          id="email"
                          label="Email"
                          variant="outlined"
                          value={form.email}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="secondaryEmail"
                          label="Secondary email"
                          variant="outlined"
                          value={form.secondaryEmail}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                    <div className="g-flex-break"></div>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled={isGuest}
                          id="author"
                          label="Author name"
                          variant="outlined"
                          value={form.author}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.subscription}
            onChange={handleExpand(expandMap.subscription)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>Subscription</Typography>
              <Typography className={classes.secondaryHeading}>{planDescription}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          disabled
                          label="API Key"
                          variant="outlined"
                          value={form.key}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Please contact&nbsp;
                        <a href="mailto:sales@cnr.ai"
                          target="_blank" rel="noopener noreferrer"
                          className={classes.linkText}
                        >
                          sales@cnr.ai
                        </a>
                        &nbsp;for upgrade.
                      </Typography>
                      <Typography>
                        Compare available subscriptions on
                        <a href="https://docs.cnr.ai/guide/usage-plan.html"
                          target="_blank" rel="noopener noreferrer"
                          className={classes.linkText}
                        >
                          &nbsp;https://docs.cnr.ai
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.theme}
            onChange={handleExpand(expandMap.theme)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>Theme</Typography>
              <Typography className={classes.secondaryHeading}>{themeMap[form.theme]}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl
                        id='theme-control'
                        variant="outlined"
                        fullWidth={true}
                      >
                        <InputLabel id="theme-label">Theme</InputLabel>
                        <Select
                          labelId='theme'
                          name='theme'
                          value={form.theme || ''}
                          onChange={handleThemeChange}
                        >
                          {
                            Object.keys(themeMap).map(key => <MenuItem key={key} value={key}>{themeMap[key]}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.api}
            onChange={handleExpand(expandMap.api)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>API</Typography>
              <Typography className={classes.secondaryHeading}>
                {settingsForm.api.protocol}://{settingsForm.api.ip}{settingsForm.api.port ? `:${settingsForm.api.port}` : ''}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                  <Grid item xs={2}>
                      <FormControl
                        id='api-protocol'
                        variant="outlined"
                        fullWidth={true}
                      >
                        <Select
                          labelId='api-protocol'
                          name='api-protocol'
                          disabled={form.plan === 'free' || isGuest || isForceHttps}
                          value={settingsForm.api.protocol}
                          onChange={handleAPIChange}
                          error={!isValidAPI}
                        >
                          <MenuItem value={'https'}>https</MenuItem>
                          <MenuItem value={'http'}>http</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth={true}
                      >
                        <TextField
                          id='api-ip'
                          label="IP / URL"
                          variant="outlined"
                          value={settingsForm.api.ip}
                          disabled={form.plan === 'free' || isGuest}
                          onChange={handleAPIChange}
                          error={!isValidAPI}
                          helperText={isValidAPI ? '' : 'Please test API endpoint.'}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        variant="outlined"
                        className="nodrag"
                        fullWidth={true}>
                        <TextField
                          id='api-port'
                          type="number"
                          label="Port"
                          variant="outlined"
                          value={settingsForm.api.port}
                          disabled={form.plan === 'free' || isGuest}
                          onChange={handleAPIChange}
                          error={!isValidAPI}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<TestIcon />}
                        disabled={form.plan === 'free' || isGuest}
                        onClick={handleAPITest}
                      >
                        <Typography>Test Endpoint</Typography>
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<RestoreIcon />}
                        className={classes.buttonGap}
                        disabled={form.plan === 'free' || isGuest}
                        onClick={handleRestoreAPI}
                      >
                        <Typography>Restore Default</Typography>
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <p>
                        WARNING:&nbsp;&nbsp;Unless you know and understand what API means,&nbsp;
                        do not change these value.
                      </p>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.azure}
            onChange={handleExpand(expandMap.azure)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Azure Custom Vision</Typography>
              <Typography className={classes.secondaryHeading}>
                {azureRegionSelected.displayName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item className={`${classes.contentWrapper}`}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Autocomplete
                        disabled={isGuest}
                        className="nodrag"
                        options={azureRegionList}
                        getOptionLabel={option => option.displayName}
                        getOptionSelected={option => option.name}
                        id="azure-region"
                        onChange={handleAzureChange}
                        value={azureRegionSelected}
                        inputValue={azureRegionSelected.displayName}
                        renderInput={
                          params => <TextField {...params}
                            variant="outlined"
                            label="Region"
                            fullWidth={true} />
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormControl
                        disabled={isGuest}
                        variant="outlined"
                        className="nodrag"
                        fullWidth={true}>
                        <TextField
                          id="azure-train-key"
                          label="Train Key"
                          variant="outlined"
                          value={settingsForm.engine.azureCv.trainingKey}
                          onChange={handleAzureChange}
                          error={!isValidAzure}
                          helperText={isValidAzure ? '' : 'Please test Azure custom vision connectivity'}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<TestIcon />}
                        disabled={isGuest}
                        onClick={handleAzureTest}
                      >
                        <Typography>Test Azure CV</Typography>
                      </Button>
                      <Button
                          variant="contained"
                          color="primary"
                          startIcon={<RestoreIcon />}
                          className={classes.buttonGap}
                          onClick={handleRestoreAzure}
                        >
                          <Typography>Restore Default</Typography>
                        </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === expandMap.neural}
            onChange={handleExpand(expandMap.neural)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Neural Engine</Typography>
              <Typography className={classes.secondaryHeading}>
                { neural.ip ? `${neural.protocol || 'http'}://${neural.ip}${neural.port ? ':' : ''}${neural.port}` : 'Default' }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justify="flex-end" spacing={3}>
                <Grid item container className={`${classes.contentWrapper}`}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <FormControl
                          id='neural-protocol'
                          variant="outlined"
                          fullWidth={true}
                        >
                          <Select
                            labelId='neural-protocol'
                            name='neural-protocol'
                            disabled={form.plan === 'free' || isGuest}
                            value={neural.protocol || ''}
                            onChange={handleNeuralChange}
                            error={!isValidNeural}
                          >
                            <MenuItem value={'https'}>https</MenuItem>
                            <MenuItem value={'http'}>http</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          disabled={isGuest}
                          variant="outlined"
                          className="nodrag"
                          fullWidth={true}>
                          <TextField
                            id="neural-ip"
                            label="IP / URL"
                            variant="outlined"
                            value={neural.ip}
                            onChange={handleNeuralChange}
                            error={!isValidNeural}
                            helperText={isValidNeural ? '' : 'Please test neural engine.'}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl
                          disabled={isGuest}
                          variant="outlined"
                          className="nodrag"
                          fullWidth={true}>
                          <TextField
                            id="neural-port"
                            type="number"
                            label="Port"
                            variant="outlined"
                            value={neural.port}
                            onChange={handleNeuralChange}
                            error={!isValidNeural}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component={'span'}>
                          *In order to use your local neural engine, you need to make sure :
                          <ol>
                            <li>Chrome or Chromium-based browser.</li>
                            <li>Allow in Insecure content in&nbsp;
                              <a href="https://support.google.com/chrome/answer/114662?hl=en"
                                target="_blank" rel="noopener noreferrer"
                                className={classes.linkText}
                              >
                                site settings
                              </a>.
                            </li>
                          </ol>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<TestIcon />}
                          onClick={handleNeuralTest}
                        >
                          <Typography>Test Neural Engine</Typography>
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<RestoreIcon />}
                          className={classes.buttonGap}
                          disabled={form.plan === 'free' || isGuest}
                          onClick={handleRestoreNeural}
                        >
                          <Typography>Restore Default</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  elements: state.elements,
  dispatch: state.dispatch,
});

const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  gridRoot: {
    padding: theme.spacing(2),
  },
  appBar: {
    paddingLeft: '8px',
    borderBottom: theme.palette.component.borderOutline,
  },
  contentWrapper: {
    flexBasis: '80%',
  },
  content: {
    backgroundColor: theme.palette.primary.main,
  },
  contentBlack: {
    backgroundColor: '#000000',
  },
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '20%',
    flexShrink: 0,
    paddingLeft: '12px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.typo.themeColour,
    paddingLeft: '12px',
  },
  buttonGroup: {
    marginBottom: '2rem',
  },
  saveButton: {
    margin: theme.spacing(1),
  },
  fixButtonIcon: {
    marginTop: '-2.5px',
  },
  buttonGap: {
    marginLeft: '12px',
  },
  linkText: {
    color: theme.hrefLink.textColor,
    'text-decoration': 'underline',
  },
  accordionRoot: {
    backgroundColor: theme.palette.primary.main,
    border: theme.palette.component.borderOutline,
  },
});

export default connect(mapStateToProps)(withStyles(styles)(SettingsForm2));
