/* eslint-disable no-nested-ternary */
import Promise from 'bluebird';
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { Handle } from 'react-flow-renderer';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {
  red,
  green,
  blue,
  purple,
  orange,
} from '@material-ui/core/colors';
import CameraIcon from '@material-ui/icons/CameraAlt';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import {
  ExpandMoreIcon,
  MoreVertIcon,
  TrashIcon,
} from './CommonIcons';
import TouchableIconButton from './TouchableIconButton';
import { checkIsGuest } from '../utils/localStorage';
import { isIOS } from '../utils/browserCheck';
import { messageElement, setElements } from '../store/elementActions';
import { getEdgeAiStudioJs } from './LocalStorage';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  rootTransparent: {
    backgroundColor: 'transparent',
  },
  rootAvatar: {
    backgroundColor: 'transparent',
  },
  optionRoot: {
    backgroundColor: theme.palette.primary.main,
    color: theme.typo.themeColour,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarRed: {
    backgroundColor: red[500],
  },
  avatarGreen: {
    backgroundColor: green[500],
  },
  avatarBlue: {
    backgroundColor: blue[500],
  },
  avatarPurple: {
    backgroundColor: purple[500],
  },
  avatarOrange: {
    backgroundColor: orange[500],
  },
  button: {
    // TODO: this is to avoid flashing
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  test: {
    backgroundColor: 'yellow',
  },
});

const Basic = forwardRef((props, ref) => {
  const {
    classes,
    label,
    subheader,
    onMessageAsync,
    elementsRef,
    id,
    apiRef,
    dispatch,
    api,
    onDelete,
    doNotProvideMoreButton,
    onTouchConnecting,
    doNotProvideTapConnection,
    readOnly,
  } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isConnectingLeft, setIsConnectingLeft] = React.useState(false);
  const [isConnectingRight, setIsConnectingRight] = React.useState(false);
  const apiRef2 = useRef();

  const getMyElement = () => elementsRef?.current?.find(r => r.id === id);
  const ACTION = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
  };
  const POSITION = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
  };

  // debugger; // eslint-disable-line no-debugger
  // useImperativeHandle(apiRef, () => {
  //   console.debug('apiRef useImperativeHandle', props.label);
  //   return {
  //     sendMessage: (targetId, data) => {
  //       // console.debug('sendMessage', targetId, data);
  //       dispatch(messageElement(id, targetId, data));
  //     },
  //     getSourceIds: () => elements.filter(r => r.target === id).map(r => r.source),
  //     getTargetIds: () => elements.filter(r => r.source === id).map(r => r.target),
  //     getMyElement,
  //   };
  // }, []);

  useImperativeHandle(apiRef2, () => ({
    ping: async data => alert(JSON.stringify(data)),
    getSourceIds: () => (elementsRef?.current || []).filter(r => r.target === id).map(r => r.source),
    getTargetIds: () => (elementsRef?.current || []).filter(r => r.source === id).map(r => r.target),
    getSources: () => (elementsRef?.current || []).filter(x => (elementsRef?.current || []).filter(r => r.target === id).map(r => r.source).includes(x.id)),
    getTargets: () => (elementsRef?.current || []).filter(x => (elementsRef?.current || []).filter(r => r.source === id).map(r => r.target).includes(x.id)),
    getMyElement,
    clearTouchState: () => {
      setIsConnectingLeft(false);
      setIsConnectingRight(false);
    },
    ...(api || {}),
  }));

  useEffect(() => {
    if (props.onApiLoad) {
      props.onApiLoad(apiRef2);
    }
  }, [apiRef2]);

  // useEffect(() => {
  //   (async () => {
  //     const me = getMyElement();
  //     // console.debug('Basic useEffect ', me);
  //     if (me && me.messages && me.messages.length > 0) {
  //       await Promise.map(Array(me.messages.length).fill(), async () => {
  //         const message = me.messages.shift();
  //         if (!message) return;
  //         // console.debug('process message', message.id);
  //         await onMessageAsync(message);
  //       }, { concurrency: 1 });

  //       // messages are removed so we update elements
  //       dispatch(setElements(elements));
  //     }
  //   })();
  // }); // trigger for all changes

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickRemove = () => {
    // debugger; // eslint-disable-line no-debugger
    onDelete();
    handleClose();
  };

  const handleClickRemoveSourceConnections = () => {
    onDelete((elementsRef?.current || []).filter(r => apiRef2.current.getSourceIds().includes(r.source)));
    handleClose();
  };

  const handleClickRemoveTargetConnections = () => {
    onDelete((elementsRef?.current || []).filter(r => apiRef2.current.getTargetIds().includes(r.target)));
    handleClose();
  };

  const handleClickShowBlockId = () => {
    // alert(window.edgeAiStudioApiKey, 'info');
    alert(id, 'info');
    handleClose();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLeftHandler = () => {
    if (isConnectingRight) {
      setIsConnectingRight(false);
      onTouchConnecting(buildTouchEvent(ACTION.REMOVE, POSITION.RIGHT));
    }
    if (isConnectingLeft) {
      onTouchConnecting(buildTouchEvent(ACTION.REMOVE, POSITION.LEFT));
      setIsConnectingLeft(false);
    } else {
      onTouchConnecting(buildTouchEvent(ACTION.ADD, POSITION.LEFT));
      setIsConnectingLeft(true);
    }
  };

  const handleClickRightHandler = () => {
    if (isConnectingLeft) {
      setIsConnectingLeft(false);
      onTouchConnecting(buildTouchEvent(ACTION.REMOVE, POSITION.LEFT));
    }
    if (isConnectingRight) {
      onTouchConnecting(buildTouchEvent(ACTION.REMOVE, POSITION.RIGHT));
      setIsConnectingRight(false);
    } else {
      onTouchConnecting(buildTouchEvent(ACTION.ADD, POSITION.RIGHT));
      setIsConnectingRight(true);
    }
  };

  const buildTouchEvent = (action, position) => ({
    action,
    position,
    id,
    element: getMyElement(),
  });

  const avatarClass = {
    red: classes.avatarRed,
    green: classes.avatarGreen,
    blue: classes.avatarBlue,
    purple: classes.avatarPurple,
    orange: classes.avatarOrange,
  }[props.color || 'red'];

  return (
    <>
      { isIOS() && !doNotProvideTapConnection
        ? <Handle
          type="target" position="left"
          className={isConnectingLeft ? 'app-mode-connecting-left' : 'app-mode-handle-left'}
          onClick={handleClickLeftHandler}
        />
        : !doNotProvideTapConnection
          ? <Handle type="target" position="left"/>
          : false
      }
      <Card elevation={0} className={clsx(classes.root, {
        [classes.rootTransparent]: label === 'Markdown',
        [classes.rootAvatar]: label === 'Avatar camera',
      })} ref={apiRef2}>
        {props.icon && <CardHeader
          avatar={
            <Avatar className={avatarClass}>
              {props.icon}
            </Avatar>
          }
          action={
            <>
              {doNotProvideMoreButton || readOnly ? false
                : <TouchableIconButton className={classes.button} aria-label="settings"
                  onClick={handleClick}>
                  <MoreVertIcon />
                </TouchableIconButton>}
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ list: classes.optionRoot }}
              >
                {/* <MenuItem onClick={handleClose}>Duplicate</MenuItem> */}
                <MenuItem onClick={handleClickRemove}>Remove</MenuItem>
                <MenuItem onClick={handleClickRemoveSourceConnections}>
                  Remove source connections
                </MenuItem>
                <MenuItem onClick={handleClickRemoveTargetConnections}>
                  Remove target connections
                </MenuItem>
                <MenuItem onClick={handleClickShowBlockId}>
                  Show block ID
                </MenuItem>
              </Menu>
            </>
          }
          title={label}
          subheader={subheader}
        />}

        {props.renderContent ? props.renderContent() : false}
        {props.children}

        {props.description
          ? <CardContent>
            <Typography variant="body2" color="textSecondary">{props.description}</Typography>
          </CardContent>
          : false
        }

        <CardActions disableSpacing>
          {props.renderButtons && props.renderButtons()}

          {!props.icon && !readOnly
            ? <TouchableIconButton onClick={handleClickRemove}>
              <TrashIcon />
            </TouchableIconButton>
            : false}

          {!props.renderAdvanced || readOnly ? false
            : <TouchableIconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </TouchableIconButton>
          }
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {props.renderAdvanced?.()}
          </CardContent>
        </Collapse>
      </Card>
      { isIOS() && !doNotProvideTapConnection
        ? <Handle
          type="source" position="right"
          className={isConnectingRight ? 'app-mode-connecting-right' : 'app-mode-handle-right'}
          onClick={handleClickRightHandler}
        />
        : !doNotProvideTapConnection
          ? <Handle type="target" position="right"/>
          : false
      }
    </>
  );
});

export default withStyles(styles)(Basic);
