import YAML from 'yamljs';
import { getAuthDataAsync, setAuthDataAsync, setEdgeAiStudioYaml } from '../Blocks/LocalStorage';
import {
  efetch,
  getEndpointUrl,
  getEndpointRootUrl,
} from '../Components/ApiServerInterface';

export const patchAuth = async (
  email,
  givenName,
  familyName,
  secondaryEmail,
  organization,
  department,
  contact,
  theme,
) => {
  try {
    const url = getEndpointUrl();
    const res = await efetch(`${url}/register`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        givenName,
        familyName,
        secondaryEmail,
        organization,
        department,
        contact,
        theme,
      }),
    });
    const result = await res.json();
    if (result.success) {
      let authData = await getAuthDataAsync();
      authData = {
        ...authData,
        familyName,
        givenName,
        organization,
        department,
        secondaryEmail,
        contact,
        theme,
      };
      await setAuthDataAsync(authData);
      return authData;
      // eslint-disable-next-line no-else-return
    } else {
      return Promise.reject(res);
    }
  } catch (err) {
    console.error('[API][PATCH][/register] ', err);
    return Promise.reject(err);
  }
};

export const updateSetting = async settingsJSON => {
  try {
    if (!settingsJSON.neurals[0].ip) {
      delete settingsJSON.neurals;
    }
    const settingsYAML = YAML.stringify(settingsJSON, 4);
    const url = getEndpointUrl();
    const res = await efetch(`${url}/settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ yaml: settingsYAML }),
    });
    const result = await res.json();
    if (result.success) {
      await setEdgeAiStudioYaml(settingsYAML);
      return result;
    }
    return Promise.reject(result);
  } catch (err) {
    console.error('[API][POST][/settings] ', err);
    return Promise.reject(err);
  }
};

export const appSignIn = async appAuthData => {
  try {
    const rootEndpoint = getEndpointRootUrl();
    const url = `${rootEndpoint}/auth/app/signin`;
    const res = await efetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(appAuthData),
    });

    const user = await res.json();
    console.debug(user);
    window.edgeAiAuthCallback({
      op: 'auth-success',
      user,
    });
    return;
  } catch (err) {
    console.error('appSignIn', err);
    throw err;
  }
};
