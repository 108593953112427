/* eslint no-console:0 no-debugger:0 */

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import UnfavoriteIcon from '@material-ui/icons/FavoriteBorder';
import TickIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Star1Icon from '@material-ui/icons/Star';
import Star0Icon from '@material-ui/icons/StarBorder';
import Star05Icon from '@material-ui/icons/StarHalf';
import ApiIcon from '@material-ui/icons/Http';
import SizeIcon from '@material-ui/icons/SdCard';
import PriceIcon from '@material-ui/icons/MonetizationOn';
import PlayIcon from '@material-ui/icons/PlayArrow';
import DownloadIcon from '@material-ui/icons/GetApp';
import fetch from 'node-fetch';

import ModelLoader from '../Blocks/ModelLoader';
import { getEndpointUrl } from './EdgeAiAppInterface';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100%',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'left',
    backgroundColor: '#303030',
    padding: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const modelType = {
  'object-detector': <>
    Object detector
  </>,
  ocr: 'OCR',
};

const EventForm = props => {
  const { confirm, alert } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [topModelNames, setTopModelNames] = React.useState(['object', 'ocr', 'pose']);
  const [models, setModels] = React.useState([]);
  const defaultModels = [
    {
      name: 'Default',
      type: 'object-detector',
      description: <>
        This model detects 64 different objects and returns bounding boxes with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star05Icon /></>} label="4.8" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='250 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'1M - 10M'} />
      </>,
      format: 'CoreML',
      url: null,
      developer: 'C&R Wise AI',
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
        size: '250 MB',
      },
      favorite: true,
      added: true,
      imagePath: '/static/images/default.jpeg',
    },
    {
      name: 'Chinese OCR',
      type: 'ocr',
      description: <>
        This model recognizes text strings and returns bounding boxes with confidence level.
        <br />
        <br />
        <Chip className={classes.chip} icon={<><Star1Icon style={{ marginLeft: 8 }} /><Star1Icon /><Star1Icon /><Star1Icon /><Star1Icon /></>} label="5.0" />
        <Chip className={classes.chip} avatar={<Avatar />} label={'C&R'} />
        <Chip className={classes.chip} icon={<PriceIcon />} label='Free' />
        <Chip className={classes.chip} icon={<SizeIcon />} label='250 MB' />
        <Chip className={classes.chip} icon={<ApiIcon />} label={'10M - 100M'} />
      </>,
      format: 'CoreML',
      url: null,
      metrics: {
        stars: 4.8,
        apis: '1,000,000 - 10,000,000',
        price: 'Free',
      },
      favorite: false,
      added: false,
      parameters: {
        language: 'Support multiple languages',
      },
      imagePath: '/static/images/ocr.jpg',
    },
  ];

  useEffect(() => {
    (async () => {
      const res = await fetch(`${getEndpointUrl()}/model`);
      const json = await res.json();
      console.debug(json);

      setModels(defaultModels);
    })();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderCard = model => (
    <Card>
      <CardMedia
        className={classes.media}
        image={model.imagePath}
        title="model"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {model.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          {model.favorite ? <FavoriteIcon /> : <UnfavoriteIcon />}
        </IconButton>
        <IconButton aria-label="share">
          {model.added ? <TickIcon /> : <AddIcon />}
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card >
  );

  const renderSearchBar = () => (
    <Autocomplete
      freeSolo
      disableClearable
      options={topModelNames}
      renderInput={params => (
        <TextField
          {...params}
          label="Search keywords"
          margin="normal"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  );

  const renderUpload = () => (
    <ModelLoader color="red" confirm={confirm} alert={alert} />
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Monitors</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardMedia
              className={classes.media}
              image="http://localhost:8888/api/v1/stream?pipelineId=5e85ffcd-9888-4ed4-b02b-e71da5debbc8&blockId=0d02dc3a-01e2-49ff-9af9-fc0589f6228c&fps=5"
              title="model"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                CAM1
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardMedia
              className={classes.media}
              image="http://localhost:8888/api/v1/stream?pipelineId=5e85ffcd-9888-4ed4-b02b-e71da5debbc9&blockId=0d02dc3a-01e2-49ff-9af9-fc0589f6228c&fps=5"
              title="model"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                CAM2
              </Typography>
            </CardContent>
          </Card>
        </Grid>

      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <br />
          <Typography variant="h5">Events</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderSearchBar()}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  CAM2
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title="Trepassing"
              subheader="12:12:34 - 12:12:39"
            />
            <CardMedia
              className={classes.media}
              image="static/images/event.jpg"
              title="model"
            />
            <CardActions disableSpacing>
              <IconButton aria-label="share">
                <PlayIcon />
              </IconButton>
              <IconButton aria-label="download">
                <DownloadIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

    </div>
  );
};

EventForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(EventForm);
