import fetch from 'node-fetch';
import { efetch } from '../Components/ApiServerInterface';

// eslint-disable-next-line import/prefer-default-export
export const ping = async testURL => {
  try {
    const res = await efetch(testURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result.success || false;
  } catch (err) {
    console.error(`[API][GET][${testURL}] `, err);
    return false;
  }
};

export const pingAzure = async (region, trainKey) => {
  try {
    const res = await fetch(
      `https://${region}.api.cognitive.microsoft.com/customvision/v3.3/training/projects`,
      {
        headers: {
          'Training-Key': trainKey,
        },
      },
    );
    if (res) {
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    console.error(`[API][AZURE][TEST][${region}][${trainKey}] `, err);
    return Promise.reject(err);
  }
};
