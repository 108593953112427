import * as StackBlur from 'stackblur-canvas';
import { pdfjs } from 'react-pdf';
import { changeDpiDataUrl } from 'changedpi';
import { isChrome } from '../utils/browserCheck';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PDF_PAGES_LIMIT = 300;
const DEFAULT_JPEG_QUALITY = 1;

const convertJpegToPng = dataUrl => new Promise(resolve => {
  if (!dataUrl) {
    resolve(dataUrl);
    return;
  }

  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = i.width;
    canvas.height = i.height;
    canvas.getContext('2d').drawImage(i, 0, 0);
    resolve(canvas.toDataURL('image/png'));
  };
});

const convertToJpeg = dataUrl => new Promise(resolve => {
  if (!dataUrl) {
    resolve(dataUrl);
    return;
  }

  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = i.width;
    canvas.height = i.height;
    canvas.getContext('2d').drawImage(i, 0, 0);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const resize = (dataUrl, width = 320, height = 180) => new Promise(resolve => {
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.imageSmoothingEnabled = true;
    context.imageSmoothingQuality = 'high';
    context.drawImage(i, 0, 0, width, height);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const flip = (dataUrl, direction = 'x') => new Promise(resolve => {
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    const canvas = document.createElement('canvas');
    const { width, height } = i;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    switch (direction) {
      case 'x':
        context.translate(width, 0);
        context.scale(-1, 1);
        break;
      case 'y':
        context.translate(0, height);
        context.scale(1, -1);
        break;
    }
    context.drawImage(i, 0, 0, width, height);
    resolve({
      image: canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY),
      width,
      height,
    });
  };
});

const rotate = (dataUrl, angle = 90) => new Promise((resolve, reject) => {
  const i = new Image();

  i.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (angle % 180 === 0) {
      const newWidth = i.width;
      const newHeight = i.height;
      canvas.width = newWidth;
      canvas.height = newHeight;
      context.translate(newWidth / 2, newHeight / 2);
      context.rotate(angle * Math.PI / 180);
      context.drawImage(i, -newWidth / 2, -newHeight / 2, newWidth, newHeight);
      return resolve({
        image: canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY),
        resolution: {
          width: newWidth,
          height: newHeight,
        },
      });
    }

    const newWidth = i.width;
    const newHeight = i.height;
    canvas.width = newHeight;
    canvas.height = newWidth;
    context.translate(newHeight / 2, newWidth / 2);
    context.rotate(angle * Math.PI / 180);
    context.drawImage(i, -newWidth / 2, -newHeight / 2, newWidth, newHeight);
    return resolve({
      image: canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY),
      resolution: {
        width: newHeight,
        height: newWidth,
      },
    });
  };

  i.onerror = () => {
    reject(new Error('image-error'));
  };

  i.src = dataUrl;
});

const drawText = (
  dataUrl,
  text,
  x = 12,
  y = 12,
  font = '12px Arial',
  style = 'red',
) => new Promise(resolve => {
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    const { width, height } = i;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(i, 0, 0, width, height);
    context.font = font;
    context.fillStyle = style;
    context.fillText(text, x, y);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const drawBoundingBoxesOnly = (bboxes = [], color = 'yellow', width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  bboxes.forEach(bbox => {
    ctx.beginPath();
    ctx.lineWidth = '2';
    ctx.strokeStyle = color;
    ctx.globalAlpha = Math.round(bbox.confidence * 100) / 100;
    ctx.rect(bbox.left, bbox.top, bbox.width, bbox.height);
    ctx.stroke();
  });

  resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
});

const drawBoundingBoxes = (dataUrl, bboxes = [], color = 'yellow', width = 320, height = 240, opacityBasedOnConfidenceLevel = false) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, 0, 0, width, height);
    bboxes.forEach(bbox => {
      ctx.beginPath();
      ctx.lineWidth = '2';
      ctx.strokeStyle = color;
      if (opacityBasedOnConfidenceLevel) {
        ctx.globalAlpha = Math.round(bbox.confidence * 100) / 100;
      }
      else {
        ctx.globalAlpha = 1;
      }
      ctx.rect(bbox.left, bbox.top, bbox.width, bbox.height);
      ctx.stroke();
    });

    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const drawCircle = (ctx, x, y, radius = 2) => new Promise(resolve => {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI);
  ctx.stroke();
});

const drawLine = (ctx, fromX, fromY, toX, toY) => new Promise(resolve => {
  ctx.beginPath();
  ctx.moveTo(fromX, fromY);
  ctx.lineTo(toX, toY);
  ctx.stroke();
});

const drawKeyPointsOnly = (poses = [], color = 'yellow', width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  poses.forEach(pose => {
    ctx.lineWidth = '2';
    ctx.strokeStyle = color;
    // ctx.globalAlpha = Math.round(bbox.confidence * 100) / 100;

    /**
     * HEAD x (5 kps)
     *    nose -> leftEye
     *    leftEye -> leftEar
     *    nose -> rightEye
     *    rightEye -> rightEar
     */


    /**
     * UPPER BODY  x (6 kps)
     *    leftShoulder -> leftElbow
     *    leftElbow -> leftWrist
     *    rightShoulder -> rightElbow
     *    rightElbow -> rightWrist
     */

    /**
     * LOWER BODY x (6 kps)
     *    leftHip -> leftKnee
     *    leftKnee -> leftAnkle
     *    rightHip -> rightKnee
     *    rightKnee -> rightAnkle
     */


    /**
    * Connect upper to lower x (4 kps)
    *    leftShoulder -> leftHip
    *    rightShoulder -> rightHip
    */

    Object.keys(pose).forEach(key => {
      const kp = pose[key];
      drawCircle(ctx, kp.x, kp.y);
    });
  });

  resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
});

const drawKeyPoints = (dataUrl, poses = [], color = 'yellow', width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, 0, 0, width, height);
    ctx.lineWidth = '2';
    ctx.strokeStyle = color;

    poses.forEach(pose => {
      Object.keys(pose).forEach(key => {
        const kp = pose[key];
        drawCircle(ctx, kp.x, kp.y);
      });
    });

    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const midPointBtw = (p1, p2) => [
  p1[0] + (p2[0] - p1[0]) / 2,
  p1[1] + (p2[1] - p1[1]) / 2,
];

const drawTrail = (dataUrl, bboxes = [], color = 'yellow', width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, 0, 0, width, height);
    ctx.lineWidth = '1';
    ctx.strokeStyle = color;

    bboxes.forEach(trackedObject => {
      const { lastPositions = [] } = trackedObject.tracks[0].trackedObject;
      const lastIndex = lastPositions.length - 1;

      if (lastIndex === -1) return;
      if (lastIndex === 0) {
        // draw a dot instead
        drawCircle(ctx, lastPositions[0][0], lastPositions[0][1]);
        return;
      }

      // lastPositions.forEach((p, index) => {
      //   if (index === lastIndex) return;
      //   const p1 = p;
      //   const p2 = lastPositions[index + 1];
      //   drawLine(ctx, p1[0], p1[1], p2[0], p2[1]);
      // });
      drawCircle(ctx, lastPositions[0][0], lastPositions[0][1]);

      let p1 = lastPositions[0];
      let p2 = lastPositions[1];

      ctx.beginPath();
      ctx.moveTo(p1[0], p1[1]);

      for (let i2 = 1; i2 < lastPositions.length; i2 += 1) {
        // Take the intermediate coordinates of lastPositions
        const midPoint = midPointBtw(p1, p2);
        // Draw bezier curves
        ctx.quadraticCurveTo(p1[0], p1[1], midPoint[0], midPoint[1]);
        p1 = lastPositions[i2];
        p2 = lastPositions[i2 + 1];
      }
      // Draw a straight line between the last point and the point immediately before it
      // Draw the continuation of the line when the next point is saved
      ctx.lineTo(p1[0], p1[1]);
      ctx.stroke();
    });

    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {
      tl: radius,
      tr: radius,
      br: radius,
      bl: radius,
    };
  }
  else {
    const defaultRadius = {
      tl: 0, tr: 0, br: 0, bl: 0,
    };
    Object.keys(defaultRadius).forEach(side => {
      radius[side] = radius[side] || defaultRadius[side];
    });
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

const redact = (dataUrl, bboxes = [], blurRatio = 5, width = 320, height = 240, rounded = false) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, 0, 0, width, height);
    if (isChrome()) {
      bboxes.forEach(bbox => {
        ctx.filter = `blur(${blurRatio}px)`;
        if (rounded) {
          ctx.beginPath();
          roundRect(ctx, bbox.left, bbox.top, bbox.width, bbox.height, bbox.width / 2, null, false);
          ctx.closePath();
          ctx.clip();
        }
        ctx.drawImage(i,
          bbox.left, bbox.top,
          bbox.width, bbox.height,
          bbox.left, bbox.top,
          bbox.width, bbox.height);
      });
    }
    else {
      bboxes.forEach(bbox => {
        StackBlur.canvasRGB(
          canvas,
          Math.round(+bbox.left),
          Math.round(+bbox.top),
          Math.round(+bbox.width),
          Math.round(+bbox.height),
          blurRatio * 2,
        );
      });
    }
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const blur = (dataUrl, blurRatio = 5, width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();
  i.src = dataUrl;
  i.onload = () => {
    if (isChrome()) {
      ctx.filter = `blur(${blurRatio}px)`;
      ctx.drawImage(i, 0, 0, width, height);
    }
    else {
      const blurOffset = 2; // * StackBlur ratio is a bit weak, need to offset.
      ctx.drawImage(i, 0, 0, width, height);
      StackBlur.canvasRGB(
        canvas, 0, 0,
        Math.round(+width),
        Math.round(+height),
        blurRatio * blurOffset,
      );
    }
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const cssFilter = (dataUrl, filter, intensity = 0.5, width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();
  i.src = dataUrl;
  i.onload = () => {
    ctx.filter = `${filter}(${intensity})`;
    ctx.drawImage(i, 0, 0, width, height);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const rgb2hsv = (r, g, b) => {
  const v = Math.max(r, g, b);
  const c = v - Math.min(r, g, b);
  const h = c && ((v === r) // eslint-disable-line no-nested-ternary
    ? (g - b) / c
    : ((v === g)
      ? 2 + (b - r) / c
      : 4 + (r - g) / c));
  return [60 * (h < 0 ? h + 6 : h), v && c / v, v];
};

// TODO: Implement HSL model
// in: r,g,b in [0,1], out: h in [0,360) and s,l in [0,1]
const rgb2hsl = (r, g, b) => {
  const v = Math.max(r, g, b);
  const c = v - Math.min(r, g, b);
  const f = (1 - Math.abs(v + v - c - 1));
  const h = c && ((v == r) // eslint-disable-line no-nested-ternary
    ? (g - b) / c
    : ((v === g)
      ? 2 + (b - r) / c
      : 4 + (r - g) / c));
  return [60 * (h < 0 ? h + 6 : h), f ? c / f : 0, (v + v - c) / 2];
};

const filter = (dataUrl, colorModel, components, threshold = false, width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();
  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, 0, 0, width, height);
    const pixels = ctx.getImageData(0, 0, width, height);
    const d = pixels.data;
    for (let x = 0; x < d.length; x += 4) {
      const r = d[x];
      const g = d[x + 1];
      const b = d[x + 2];

      if (colorModel === 'rgb') {
        const [red, green, blue] = components;
        if (r >= red[0]
          && r <= red[1]
          && g >= green[0]
          && g <= green[1]
          && b >= blue[0]
          && b <= blue[1]
        ) {
          d[x] = threshold ? 255 : r;
          d[x + 1] = threshold ? 255 : g;
          d[x + 2] = threshold ? 255 : b;
        }
        else {
          d[x] = 0;
          d[x + 1] = 0;
          d[x + 2] = 0;
        }
      }

      if (colorModel === 'hsv') {
        const [hue, saturation, intensity] = components;
        const [h, s, v] = rgb2hsv(r / 255, g / 255, b / 255);
        if (h >= hue[0]
          && h <= hue[1]
          && s >= saturation[0] / 100
          && s <= saturation[1] / 100
          && v >= intensity[0] / 100
          && v <= intensity[1] / 100
        ) {
          d[x] = threshold ? 255 : r;
          d[x + 1] = threshold ? 255 : g;
          d[x + 2] = threshold ? 255 : b;
        }
        else {
          d[x] = 0;
          d[x + 1] = 0;
          d[x + 2] = 0;
        }
      }
    }
    // debugger; // eslint-disable-line no-debugger
    ctx.putImageData(pixels, 0, 0);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const brightness = (dataUrl, brightnessRatio = 1, width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.filter = `brightness(${Math.round(brightnessRatio * 100)}%)`;
    ctx.drawImage(i, 0, 0, width, height);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

// https://css-tricks.com/almanac/properties/f/filter/

const blackAndWhite = (dataUrl, inverse = false, width = 320, height = 240) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, 0, 0, width, height);

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imgData.data;
    pixels.forEach((_, x) => {
      if (x % 4 !== 0) return;
      let grayscale = pixels[x] * 0.33 + pixels[x + 1] * 0.33 + pixels[x + 2] * 0.34;

      if (inverse) {
        grayscale = 255 - grayscale;
      }

      pixels[x] = grayscale;
      pixels[x + 1] = grayscale;
      pixels[x + 2] = grayscale;
    });

    ctx.putImageData(imgData, 0, 0);

    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const merge = (dataUrl, newDataUrl) => new Promise(resolve => {
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = i.width;
    canvas.height = i.height;
    canvas.getContext('2d').drawImage(i, 0, 0);

    const i2 = new Image();
    i2.src = newDataUrl;
    i2.onload = () => {
      canvas.getContext('2d').drawImage(i2, 0, 0);
      resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
    };
  };
});

const polygonCrop = (imageData, polygons = []) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  const imageWidth = imageData.resolution.width;
  const imageHeight = imageData.resolution.height;
  const imageRef = new Image();
  const ctx = canvas.getContext('2d');
  canvas.width = imageWidth;
  canvas.height = imageHeight;
  imageRef.src = imageData.image;
  imageRef.onload = () => {
    ctx.drawImage(imageRef, 0, 0, imageWidth, imageHeight);
    ctx.clearRect(0, 0, imageWidth, imageHeight);
    ctx.beginPath();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.beginPath();
    polygons.forEach(paths => {
      paths.forEach((point, pIndex) => {
        const { x, y } = point;
        if (pIndex === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });
    });
    ctx.closePath();
    const pattern = ctx.createPattern(imageRef, 'repeat');
    ctx.fillStyle = pattern;
    ctx.fill();
    const croppedImageUrl = canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY);
    resolve(croppedImageUrl);
  };
});

const crop = (dataUrl, bboxes = []) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  const bbox = bboxes[0];
  canvas.width = bbox.width;
  canvas.height = bbox.height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, bbox.left, bbox.top, bbox.width, bbox.height, 0, 0, bbox.width, bbox.height);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const mask = (dataUrl, bbox, width, height) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  canvas.width = width || bbox.width;
  canvas.height = height || bbox.height;
  const ctx = canvas.getContext('2d');
  const i = new Image();

  i.src = dataUrl;
  i.onload = () => {
    ctx.drawImage(i, bbox.left, bbox.top, bbox.width, bbox.height, 0, 0, canvas.width, canvas.height);
    resolve(canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY));
  };
});

const getDimensionAsync = dataUrl => new Promise(resolve => {
  const i = new Image();
  i.src = dataUrl;
  i.onload = () => {
    resolve({ width: i.width, height: i.height });
  };
});

const addx1y1x2y2 = bb1 => {
  bb1.x1 = bb1.left;
  bb1.x2 = bb1.left + bb1.width;
  bb1.y1 = bb1.top;
  bb1.y2 = bb1.top + bb1.height;
  return bb1;
};

const iou = (bb1, bb2) => {
  addx1y1x2y2(bb1);
  addx1y1x2y2(bb2);

  const xLeft = Math.max(bb1.x1, bb2.x1);
  const yTop = Math.max(bb1.y1, bb2.y1);
  const xRight = Math.min(bb1.x2, bb2.x2);
  const yBottom = Math.min(bb1.y2, bb2.y2);

  if (xRight < xLeft || yBottom < yTop) return 0.0;

  const intersectionArea = (xRight - xLeft + 1) * (yBottom - yTop + 1);

  const bb1Area = (bb1.x2 - bb1.x1 + 1) * (bb1.y2 - bb1.y1 + 1);
  const bb2Area = (bb2.x2 - bb2.x1 + 1) * (bb2.y2 - bb2.y1 + 1);

  const iouValue = intersectionArea / (bb1Area + bb2Area - intersectionArea);

  return iouValue;
};

const getImageSize = imageUrl => new Promise(resolve => {
  const img = new Image();
  img.src = imageUrl;
  img.onload = () => {
    resolve({ width: img.width, height: img.height });
  };
});

const getPDFCoverImage = async PDFFileBase64 => {
  const pdf = await pdfjs.getDocument(PDFFileBase64).promise;
  const canvas = document.createElement('canvas');
  const pageItem = await pdf.getPage(1);
  const ctx = canvas.getContext('2d');
  const viewport = pageItem.getViewport({ scale: 1 });
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  await pageItem.render(
    {
      canvasContext: ctx,
      viewport,
    },
  ).promise;
  const imageItem = canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY);
  canvas.remove();
  return imageItem;
};

const convertPDFToImages = async PDFFileBase64 => {
  const pdf = await pdfjs.getDocument(PDFFileBase64).promise;
  if (pdf.numPages > PDF_PAGES_LIMIT) {
    return Promise.reject(new Error(`PDF pages limited: ${PDF_PAGES_LIMIT}`));
  }
  const pagesHolder = Array.from({ length: pdf.numPages }, (v, i) => i);
  const images = await Promise.all(pagesHolder.map(async number => {
    const canvas = document.createElement('canvas');
    const pageItem = await pdf.getPage(number + 1);
    const ctx = canvas.getContext('2d');
    const viewport = pageItem.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await pageItem.render(
      {
        canvasContext: ctx,
        viewport,
      },
    ).promise;
    const imageItem = canvas.toDataURL('image/jpeg', DEFAULT_JPEG_QUALITY);
    canvas.remove();
    return imageItem;
  }));
  return images;
};

const pageNumTextToPages = pageNumText => {
  // * Check Syntax
  if (pageNumText.includes(',')) {
    return pageNumText.split(',').map(p => parseInt(p, 10));
  }
  if (pageNumText.includes('-')) {
    const textArray = pageNumText.split('-');
    const start = parseInt(textArray[0], 10);
    const end = parseInt(textArray[1], 10);
    return Array.from(new Array(end + 1 - start), (x, i) => i + start);
  }
  return [parseInt(pageNumText, 10)];
};

const filterImagesByPageNumText = async (images, pageRangType, pageNumText = '') => {
  // * Page Text: 1,3,6 or 1-5
  const imageSize = await getImageSize(images[0]);
  const result = [];
  const pagesRequired = pageNumTextToPages(pageNumText);
  switch (pageRangType) {
    case 'all':
      images.forEach((img, index) => {
        const item = {
          image: img,
          originIndex: index,
          width: imageSize.width,
          height: imageSize.height,
        };
        result.push(item);
      });
      break;
    case 'odd':
      images.forEach((img, index) => {
        if ((index + 1) % 2 !== 0) {
          const item = {
            image: img,
            originIndex: index,
            width: imageSize.width,
            height: imageSize.height,
          };
          result.push(item);
        }
      });
      break;
    case 'even':
      images.forEach((img, index) => {
        if ((index + 1) % 2 === 0) {
          const item = {
            image: img,
            originIndex: index,
            width: imageSize.width,
            height: imageSize.height,
          };
          result.push(item);
        }
      });
      break;
    case 'cust':
      images.forEach((img, index) => {
        if (pagesRequired.includes(index + 1)) {
          const filteredItem = {
            image: img,
            originIndex: index,
            width: imageSize.width,
            height: imageSize.height,
          };
          result.push(filteredItem);
        }
      });
      break;
    default:
      break;
  }
  return result;
};

const adjustImageDPI = async (base64, dpi) => {
  if (!dpi) {
    dpi = 300;
  }
  // const imageURL = await base64ToImageURL(base64);
  return changeDpiDataUrl(base64, dpi);
};

export {
  adjustImageDPI,
  blackAndWhite,
  blur,
  brightness,
  convertJpegToPng,
  convertPDFToImages,
  convertToJpeg,
  crop,
  cssFilter,
  drawBoundingBoxes,
  drawBoundingBoxesOnly,
  drawKeyPoints,
  drawKeyPointsOnly,
  drawText,
  drawTrail,
  filter,
  filterImagesByPageNumText,
  flip,
  getDimensionAsync,
  getImageSize,
  getPDFCoverImage,
  iou,
  mask,
  merge,
  polygonCrop,
  redact,
  resize,
  rotate,
};
