import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import AccountForm from './AccountForm';

const styles = theme => ({
  content: {
    // padding: theme.spacing(3),
    // height: 'calc(100vh - 64px)',
    backgroundColor: theme.palette.primary.tertiary,
    // width: '100%',
  },
});

const AccountMain = props => {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={clsx(classes.content)}>
        <AccountForm {...props} />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(AccountMain);
