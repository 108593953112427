import Promise from 'bluebird';
import React from 'react';
import { setAuthDataAsync } from '../Blocks/LocalStorage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(this.state);
    console.error(error);

    (async () => {
      await setAuthDataAsync(null);
      await Promise.delay(30000);
      window.location.href = '/';
    })();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>Something went wrong</>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
