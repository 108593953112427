/* eslint no-underscore-dangle:0 */

import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import modelReducer from './modelReducer';
import elementReducer from './elementReducer';
import workerReducer from './workerReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      models: modelReducer,
      elements: elementReducer,
      workers: workerReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
