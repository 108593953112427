import React, {
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';

const PaperComponent = props => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);
const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  typography: {
    color: theme.typo.themeColour,
  },
  loadingBtn: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function StudioDialog(props) {
  const {
    open,
    title,
    message,
    type,
    onClick,
    onClose,
    classes,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = res => async e => {
    if (res === 'yes') {
      setIsLoading(true);
    }
    if (await onClick?.(res) === false) {
      return;
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      classes={{ paper: classes.root }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" classes={{ root: classes.typography }}>{message}</DialogContentText>
      </DialogContent>
      {type === 'yesno'
        ? <DialogActions>
          <Button
            onClick={handleClick('yes')}
            autoFocus
            classes={{ root: classes.typography }}
            disabled={isLoading}
          >
            {
              isLoading
                ? <CircularProgress size={16}
                    className={classes.loadingBtn}
                  >
                  </CircularProgress>
                : 'Yes'
            }
          </Button>
          <Button onClick={handleClick('no')} classes={{ root: classes.typography }}>No</Button>
        </DialogActions>
        : false}
      {type === 'ok'
        ? <DialogActions>
          <Button onClick={handleClick('ok')} autoFocus classes={{ root: classes.typography }}>OK</Button>
        </DialogActions>
        : false}
      {type === 'okcancel'
        ? <DialogActions>
          <Button onClick={handleClick('ok')} autoFocus classes={{ root: classes.typography }}>OK</Button>
          <Button onClick={handleClick('cancel')} classes={{ root: classes.typography }}>Cancel</Button>
        </DialogActions>
        : false}
    </Dialog>
  );
}

export default withStyles(styles)(StudioDialog);
