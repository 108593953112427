/* eslint no-console:0 no-debugger:0 */

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import Promise from 'bluebird';

import {
  StopIcon,
  ResetIcon,
  PlayIcon,
} from '../Blocks/CommonIcons';
import TouchableIconButton from '../Blocks/TouchableIconButton';
import WebRTCStreamerClient from '../utils/webrtc-streamer';
import {
  getEndpointHost,
  getEndpointProtocol,
} from './ApiServerInterface';
import { rotate } from '../Blocks/ImageManipulator';
import config from '../config';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100%',
    textAlign: 'left',
    padding: theme.spacing(3),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardStyle: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
    backgroundColor: theme.palette.primary.main,
    border: theme.palette.component.borderOutline,
  },
}));

const messages = {
  init: 'Click play...',
  loading: 'Loading...',
  connected: '',
};

const RTCStream = props => {
  const { videoUrl, camRotation } = props;
  const [pc, setPc] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(messages.init);
  const [streamerClient, setStreamerClient] = useState(null);
  const rtspVideoRef = useRef();
  const id = 0;

  useEffect(() => {
    console.info('[pc]');
    if (pc && pc !== null && pc.iceConnectionState === 'connected') {
      console.info('pc && pc !== null && pc.iceConnectionState === connected');
      const windowPc = window.pc || {};
      windowPc[id] = pc;
      window.pc = windowPc;
      setIsLoading(false);
      setIsConnected(true);
      setMessage(messages.connected);
      const [stream] = pc.getRemoteStreams();

      if (!stream) return alert('Connected but stream is not found.');
      if (rtspVideoRef?.current) {
        rtspVideoRef.current.srcObject = stream;
        rtspVideoRef.current.controls = false;
        rtspVideoRef.current.play();
      }
    }

    return () => {
      if (!pc || pc === null) return;
      streamerClient.disconnect();
      pc.close();
      streamerClient.pc = null;
      setPc(null);
    };
  }, [pc]);

  const onDisconnect = async () => {
    console.info('onDisconnect');
    setIsConnected(false);
    setIsLoading(false);
    setMessage(messages.init);
    streamerClient.disconnect();

    if (streamerClient.pc && streamerClient.pc !== null) await streamerClient.pc.close();
    if (pc && pc !== null) await pc.close();
    setPc(null);
    setStreamerClient(null);
  };

  const onConnect = () => {
    console.info('onConnect');
    setIsLoading(true);
    setMessage(messages.init);
    const client = new WebRTCStreamerClient(`${getEndpointProtocol()}://${getEndpointHost()}`, videoUrl, setPc, setMessage, onDisconnect);
    client.connect();
    setStreamerClient(client);
    return rtspVideoRef;
  };

  const renderButtons = () => {
    const init = !isLoading && !isConnected;
    const connecting = isLoading && !isConnected;
    const connected = !isLoading && isConnected;

    return (
      <>
        <TouchableIconButton onClick={init ? onConnect : onDisconnect}>
          {init && <PlayIcon />}
          {connecting && <StopIcon />}
          {connected && <ResetIcon />}
        </TouchableIconButton>
      </>
    );
  };

  return <>
    <Typography component="h4">{message}</Typography>
    <video
      ref={rtspVideoRef}
      style={{
        width: 320,
        marginTop: 40,
        marginBottom: 40,
        transform: camRotation,
      }}>
    </video>
    <CardActions disableSpacing>
      {renderButtons()}
    </CardActions>
  </>;
};

const LiveViewForm = props => {
  const {
    confirm,
    alert,
    backdrop,
    onSelect,
  } = props;
  const classes = useStyles();
  const [time, setTime] = useState(Date.now());
  const [cams, setCams] = useState([null, null, null]);

  useEffect(() => {
    (async () => {
      let duration = Date.now();

      setCams(await Promise.map([0, 1, 2], async i => {
        try {
          const angle = i === 2 ? 0 : -90;
          const im = await rotate(`/lrv${i + 1}?&_=${time}`, angle);
          return im.image;
        }
        catch (e) {
          return null;
        }
      }));

      duration = Date.now() - duration;
      setTimeout(() => {
        setTime(() => Date.now());
      }, Math.max(0, 15000 - duration));
    })();
  }, [time]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Typography variant="h5">Live View</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid key="view1" item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.cardStyle}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {config.MTR_LRV.CAMERA.CNR01.NAME}
                </Avatar>
              }
              title={config.MTR_LRV.CAMERA.CNR01.NAME}
            />
            <img src={cams[0]} />
          </Card>
        </Grid>
        <Grid key="view2" item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.cardStyle}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {config.MTR_LRV.CAMERA.CNR02.NAME}
                </Avatar>
              }
              title={config.MTR_LRV.CAMERA.CNR02.NAME}
            />
            <img src={cams[1]} />
          </Card>
        </Grid>
        <Grid key="view3" item xs={12} sm={6} md={4} lg={6}>
          <Card className={classes.cardStyle}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {config.MTR_LRV.CAMERA.CNR03.NAME}
                </Avatar>
              }
              title={config.MTR_LRV.CAMERA.CNR03.NAME}
            />
            <img src={cams[2]} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LiveViewForm;
