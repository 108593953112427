/* eslint no-console:0 no-debugger:0 */

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  LinearProgress,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import Promise from 'bluebird';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import uuid from 'uuid';

import config from '../config';
import {
  AddIcon,
  ApiIcon,
  CloseIcon,
  DownloadIcon,
  ExpandMoreIcon,
  FavoriteIcon,
  ForkIcon,
  IconButton,
  MoreVertIcon,
  NewIcon,
  PlayIcon,
  PriceIcon,
  ShareIcon,
  SizeIcon,
  Star05Icon,
  Star0Icon,
  Star1Icon,
  TickIcon,
  TrashIcon,
  UnfavoriteIcon,
} from '../Blocks/CommonIcons';

import TouchableIconButton from '../Blocks/TouchableIconButton';
import ModelLoader from '../Blocks/ModelLoader';
import {
  getPipelinesAsync,
  removePipelineAsync,
  efetch,
  getEndpointUrl,
} from './ApiServerInterface';
import { getAuthDataAsync, setAuthDataAsync } from '../Blocks/LocalStorage';
import VideoPlayback from '../Blocks/VideoPlayback';
import { rotate } from '../Blocks/ImageManipulator';
import EMPTY_1X1_JPEG from '../Blocks/Empty1x1Jpeg';
import infoWaveFile from './info.wav';
import warnWaveFile from './warn.wav';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    height: '100%',
    textAlign: 'left',
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  media916: {
    height: 0,
    paddingTop: '177.77%', // 9:16
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardStyle: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
  },
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2, // lines to show
    '-webkit-box-orient': 'vertical',
  },
}));

const modelType = {
  'object-detector': <>
    Object detector
  </>,
  ocr: 'OCR',
  pose: 'Pose',
};

const MediaCard = props => {
  const {
    alert,
    confirm,
    backdrop,
    handleRemove,
    isGuest,
    media,
  } = props;
  const [progress, setProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState(media.thumbnail);
  const [detailMedia, setDetailMedia] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    process.nextTick(async () => {
      try {
        const res = await efetch(`${getEndpointUrl()}/media/${media.uuid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // get progress from fetch
        const reader = (await res.body).getReader();
        let bytesRead = 0;
        let totalBytes = +res.headers.get('Content-Length');
        if (totalBytes === 0) totalBytes = 10000000;
        const buffer = [];

        const read = async () => {
          const { done, value } = await reader.read();
          if (done) {
            // console.info(`${(bytesRead / totalBytes) * 100}% downloaded`);
            return;
          }

          // buffer = new Uint8Array([...buffer, ...value]);
          buffer.push(value);
          bytesRead += value.length;
          // console.info(`${(bytesRead / totalBytes) * 100}% downloaded`);

          setProgress((bytesRead / totalBytes) * 100);
          // console.info((bytesRead / totalBytes) * 100);

          await read();
        };

        await read();

        const newBuffer = new Uint8Array(bytesRead);
        buffer.reduce((p, c) => {
          newBuffer.set(c, p);
          return p + c.length;
        }, 0);

        const m2 = JSON.parse((new TextDecoder()).decode(newBuffer));
        if (m2.error) {
          throw new Error(m2.error.message);
        }

        if (m2.cam === 'lrv1' || m2.cam === 'lrv2') {
          m2.images = await Promise.mapSeries(
            m2.images,
            async image => (await rotate(image, -90)).image,
          );
        }

        setDetailMedia(m2);
        setThumbnail(m2.images[0]);

        // if (newM.cam !== 'lrv3' && newM.thumbnail) {
        //   newM.thumbnail = (await rotate(newM.thumbnail, -90)).image;
        // }
        // rotate if necessary

        setProgress(100);
        // setImages(m2.images);
      }
      catch (e) {
        // sometimes we will get 400 error, we should delete the media from the screen
        console.error(e);
        // completeRef.current = true;

        setProgress(100);
        setThumbnail(EMPTY_1X1_JPEG);
        if (e.message === 'Unexpected end of JSON input') {
          handleRemove(); // corrupted file, we remove it from display
        }
      }

      // completeRef.current = true;
    });
  }, [media]);

  const handleClickRemove = id => async event => {
    confirm(
      'Confirmation',
      'Are you sure that you want to remove this media file?',
      'yesno',
      async yesno => {
        if (yesno === 'close') {
          return true;
        }

        if (yesno === 'no') {
          return true;
        }

        // handleClose(event);
        process.nextTick(async () => {
          backdrop(true);
          const res = await efetch(`${getEndpointUrl()}/media/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const r = await res.json();
          if (!r.success) {
            if (r.error.code === 'author-not-matched') {
              alert('You cannot remove the media file owned by other.', 'error');
              backdrop(false);
              return;
            }

            alert('Unable to remove the media file. Please check error logs.', 'error');
          }
          backdrop(false);
          handleRemove();
        });

        return true;
      },
    );
  };

  const handleClickPlay = async event => {
    try {
      backdrop(true);
      const m2 = detailMedia;

      confirm(
        null,
        <>
          <VideoPlayback
            frames={m2.images}
            fps={m2.fps}
            media={m2}
          />
        </>,
        'ok',
        () => { },
      );
    }
    catch (e) {
      console.error(e);
      alert('You cannot open the media file which is owned by other.', 'error');
    }
    finally {
      backdrop(false);
    }
  };

  const handleClickDownload = async event => {
    const m = detailMedia;
    if (!m) {
      alert('You cannot open the media file which is owned by other.', 'error');
      return;
    }
    const zip = new JSZip();
    const originalFolder = zip.folder('original');
    const detectionFolder = zip.folder('detection');
    (m.originalImages || []).forEach((image, i) => {
      originalFolder.file(`${`${i}`.padStart(8, '0')}.jpg`, image.split(',')[1], { base64: true });
    });

    if (config.FEATURES && config.FEATURES.includes('media-form-zip-object')) {
      const objectFolder = zip.folder('object');
      (m.objectImages || []).forEach((image, i) => {
        objectFolder.file(`${uuid.v4()}.jpg`, image.split(',')[1], { base64: true });
      });
    }

    (m.images || []).forEach((image, i) => {
      detectionFolder.file(`${`${i}`.padStart(8, '0')}.jpg`, image.split(',')[1], { base64: true });
    });
    if (m.debug) zip.file('debug.json', JSON.stringify(m.debug, null, 2));
    const content = await zip.generateAsync({ type: 'blob' });

    FileSaver.saveAs(content, 'images.zip');
  };

  const handleClickNegative = async event => {
    const m = detailMedia;
    m.negative = !m.negative;
    const response = await efetch(`${getEndpointUrl()}/media/${m.uuid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        negative: m.negative,
      }),
    });

    // setTime(Date.now());
  };

  return (
    <Card className={classes.cardStyle} style={{
      ...media.level === 'warn' ? { border: '1px solid red' } : {},
    }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {media.cam}
          </Avatar>
        }
        action={
          <>
            <IconButton aria-label="delete" onClick={handleClickRemove(media.uuid)} disabled={isGuest}>
              <TrashIcon />
            </IconButton>
          </>
        }
        title={`${new Date(media.ts)}`}
        subheader={media.author || 'cnr'}
      />
      {progress === 100
        ? null
        : <LinearProgress
          variant="determinate"
          color="secondary"
          value={progress || 0} />}
      {thumbnail ? <CardMedia
        className={media.cam === 'lrv1' || media.cam === 'lrv2' ? classes.media916 : classes.media}
        image={thumbnail}
      /> : <Typography style={{ textAlign: 'center' }}>Loading...</Typography>}
      <CardActions disableSpacing>
        <IconButton
          {...!detailMedia ? { disabled: 'disabled' } : {}}
          aria-label="play"
          style={{ marginLeft: 'auto' }}
          onClick={handleClickPlay}
        >
          <PlayIcon />
        </IconButton>
        <TouchableIconButton {...!detailMedia ? { disabled: 'disabled' } : {}}
          className={classes.button} onClick={handleClickDownload}>
          <DownloadIcon />
        </TouchableIconButton>
        <TouchableIconButton {...!detailMedia ? { disabled: 'disabled' } : {}}
          className={classes.button} onClick={handleClickNegative}>
          {!media.negative ? <TickIcon /> : <CloseIcon />}
        </TouchableIconButton>
      </CardActions>
    </Card>
  );
};

const MediaForm = props => {
  const {
    confirm,
    alert,
    backdrop,
    onSelect,
  } = props;
  const classes = useStyles();
  const [media, setMedia] = useState([]);
  const [keywords, setKeywords] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPipelineId, setCurrentPipelineId] = useState(null);
  const [author, setAuthor] = useState(null);
  const [isGuest, setIsGuest] = useState(false);
  const [time, setTime] = useState(Date.now());
  const lastQueryTime = useRef(0);
  const completeRef = useRef(true);
  const infoAudioRef = useRef();
  const warnAudioRef = useRef();
  const timeoutIdRef = useRef(null);
  const [filter, setFilter] = useState(['warn', 'info']);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [alarmInfo, setAlarmInfo] = useState(true);
  const [alarmWarn, setAlarmWarn] = useState(true);
  const alarmOn = useRef(true);
  const ENABLE_BACKDOOR_ALARM = false;

  useEffect(() => {
    infoAudioRef.current.load();
    warnAudioRef.current.load();
  }, []);

  useEffect(() => {
    process.nextTick(async () => {
      try {
        // backdrop(true);
        const authData = await getAuthDataAsync();
        setAuthor(authData.author);
        setIsGuest(authData.isGuest);
        await loadMedia();
        // backdrop(false);
      }
      catch (e) {
        confirm(
          'Server unavailable',
          'Server is unavailable now. Please try again later.',
          'ok',
          async () => {
            await setAuthDataAsync(null);
            window.location.href = '/';
          },
        );
      }

      if (!timeoutIdRef.current) {
        timeoutIdRef.current = setTimeout(() => {
          timeoutIdRef.current = null;
          setTime(Date.now());
        }, 5000);
      }
    });

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [time]);

  useEffect(() => {
    lastQueryTime.current = 0;
    loadMedia();
  }, [itemsPerPage, alarmInfo, alarmWarn]);

  const waitAndSetUntilComplete = async v => {
    if (completeRef.current) {
      completeRef.current = v;
      return;
    }
    await Promise.delay(1000);
    await waitAndSetUntilComplete();
  };

  const isPipelineMatched = (pipeline, words) => words.split(' ').reduce((p, word) => {
    word = (word || '').trim().toLowerCase();
    let matched = p;
    matched = matched || (pipeline.id.toLowerCase() === word);
    matched = matched || (pipeline.name || '').toLowerCase().includes(word);
    matched = matched || (pipeline.description || '').toLowerCase().includes(word);
    matched = matched || (pipeline.author || '').toLowerCase().includes(word);
    // console.debug(word, pipeline, p);
    return p || matched;
  }, false);

  const loadMedia = async () => {
    const ts = lastQueryTime.current;
    const now = Date.now();
    const res = await efetch(`${getEndpointUrl()}/media?ts=${ts}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const ms = await res.json();
    ms.forEach(r => {
      if (!r.level) r.level = 'info';
      if (!alarmOn.current) r.level = 'info';
    });

    // ms = ms.filter(r => {
    //   if (!r.level) r.level = 'info';
    //   // console.info(alarmWarn, alarmInfo, r.level);
    //   if (r.level === 'warn' && !alarmWarn) return false;
    //   if (r.level === 'info' && !alarmInfo) return false;
    //   return true;
    // });

    if (ts !== 0 && ms.length > 0) {
      if (ms.find(r => r.level === 'warn')) {
        if (alarmWarn) warnAudioRef.current.play();
      }
      if (ms.find(r => r.level === 'info')) {
        // if (alarmInfo) infoAudioRef.current.play();
      }
      alert('Alarm is triggered. New media file is added.', 'error');
    }

    if (ts === 0) {
      setMedia(ms.slice(0, itemsPerPage));
    }
    else {
      // console.info(`New media files: ${ms.length}`);
      setMedia(m => [...ms, ...m].slice(0, itemsPerPage));
    }

    lastQueryTime.current = Math.max(now, ...ms.map(r => r.ts));
  };

  const handleClickBackground = e => {
    if (ENABLE_BACKDOOR_ALARM) {
      alarmOn.current = !alarmOn.current;
    }
  };

  const handleRemove = id => () => {
    setMedia(m => [...m.filter(x => x.uuid !== id)].slice(0, itemsPerPage));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: 'flex' }} onDoubleClick={handleClickBackground}>
          <Typography variant="h5">Media</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <FormGroup className="nodrag">
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={alarmInfo}
                onChange={e => setAlarmInfo(e.target.checked)}
              />}
              label="Info alarm"
              className={classes.formControlLabel}
            />
            <FormControlLabel
              style={{
                marginLeft: 'auto',
              }}
              control={<Checkbox checked={alarmWarn}
                onChange={e => setAlarmWarn(e.target.checked)}
              />}
              label="Warning alarm"
              className={classes.formControlLabel}
            />
            <FormControl variant="outlined" style={{
              width: 100,
            }} className={`${classes.formControl} nodrag`}>
              <InputLabel>Items per page</InputLabel>
              <Select value={itemsPerPage}
                onChange={e => setItemsPerPage(+e.target.value)}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="32">32</MenuItem>
                <MenuItem value="64">64</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </FormGroup>
      </Grid>

      <Grid container spacing={3}>
        {media
          .filter(pipeline => !keywords || keywords.trim() === '' || isPipelineMatched(pipeline, keywords))
          .filter(r => {
            if (r.level === 'warn' && !alarmWarn) return false;
            if (r.level === 'info' && !alarmInfo) return false;
            return true;
          })
          .map((m, i) => (
            <Grid key={`media-${m.uuid}`} item xs={12} sm={6} md={4} lg={3}>
              <MediaCard
                media={m}
                thumbnail={m.thumbnail}
                confirm={confirm}
                backdrop={backdrop}
                alert={alert}
                handleRemove={handleRemove(m.uuid)}
                isGuest={isGuest} />
            </Grid>
          ))}
        {/* <Grid key='new' item xs={4}>
          {renderUpload()}
        </Grid> */}
      </Grid>

      <audio ref={warnAudioRef} src={warnWaveFile}></audio>
      <audio ref={infoAudioRef} src={infoWaveFile}></audio>

      {ENABLE_BACKDOOR_ALARM
        ? <Typography>{alarmOn.current ? '.' : ''}</Typography>
        : false}
    </div>
  );
};

export default MediaForm;
