import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import {
  CopyIcon,
  TickIcon,
} from '../Blocks/CommonIcons';

const styles = theme => ({
  root: {
    width: 480,
  },
  centerItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialIconsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const description = 'Check out what I created using EdgeAI Studio!';
const iconSize = 40;

const ShareDialog = props => {
  const {
    classes,
    shareUrl,
  } = props;
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = text => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  const handleCopyClick = () => {
    copyTextToClipboard(shareUrl);
    setIsCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <div className={classes.root}>
      <div>
        <Grid container spacing={1}>
          <Grid item xs fullWidth className={classes.centerItems}>
            <TextField
              style={{ width: '100%' }}
              variant='outlined'
              label='Url'
              value={shareUrl.replace(/\s/g, '%20')}
              inputProps={{ readOnly: true }}
            />
            <IconButton
              aria-label='copyToClipboard'
              component='div'
              onClick={handleCopyClick}
              style={{ color: 'white' }}
            >
              {isCopied ? <TickIcon /> : <CopyIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <br />
      <Grid container spacing={1}>
        <Grid item xs fullWidth className={classes.socialIconsWrapper}>
          <FacebookShareButton
            url={shareUrl}
            quote={description}
          >
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={description}
          >
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
          >
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
          <TelegramShareButton
            url={shareUrl}
            title={description}
          >
            <TelegramIcon size={iconSize} round />
          </TelegramShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={description}
            separator=" "
          >
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>
          <LineShareButton
            url={shareUrl}
            title={description}
          >
            <LineIcon size={iconSize} round />
          </LineShareButton>
          <RedditShareButton
            url={shareUrl}
            title={description}
            windowWidth={660}
            windowHeight={460}
          >
            <RedditIcon size={iconSize} round />
          </RedditShareButton>
          <EmailShareButton
            url={shareUrl}
            subject={description}
            body="body"
          >
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ShareDialog);
