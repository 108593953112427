import uuid from 'uuid';
import edgeai from 'edgeai';
import { efetch } from './ApiServerInterface';

const getEndpointUrl = () => {
  if (!window.edgeAiStudioConfig.neurals) {
    return '/neural/v1';
  }

  return `http://${getEndpointHost()}/api/v1`;
};

const isEndpointLocal = () => !!window.edgeAiStudioConfig.neurals;

const getEndpointHost = () => {
  if (!window.edgeAiStudioConfig.neurals) {
    return document.location.host;
  }

  const { ip, port } = window.edgeAiStudioConfig.neurals[0];
  return `${ip}:${port}`;
};

const getModelsAsync = async () => {
  const res = await efetch(`${getEndpointUrl()}/model`);
  return (await res.json()).data;
};

const pingAsync = async () => {
  const res = await efetch(`${getEndpointUrl()}/ping`);
  return res.json();
};

const loadModelAsync = async manifest => {
  const res = await efetch(`${getEndpointUrl()}/model/${manifest.model}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(manifest),
  });
  return res.json();
};

const predictAsync = async (image, model) => {
  const filename = `image/${uuid.v4()}.jpg`;

  await uploadDataUrlViaJsonAsync(image, filename);

  const res = await efetch(`${getEndpointUrl()}/model/${model}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      model, filename, removeFile: true,
    }),
  });

  return res.json();
};

const uploadDataUrlViaJsonAsync = async (image, filename) => {
  const res = await efetch(`${getEndpointUrl()}/file`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dataUrl: image, filename,
    }),
  });

  const r = await res.json();

  if (!('success' in r) || !r.success) throw new Error(r);

  return true;
};

export {
  getEndpointUrl,
  getEndpointHost,
  getModelsAsync,
  pingAsync,
  loadModelAsync,
  predictAsync,
  uploadDataUrlViaJsonAsync,
  isEndpointLocal,
};
