const GUEST_KEY = 'GUEST';
const GUEST_VALUE = 'TRUE';
const ENTERPRISE_KEY = 'ENTERPISE';
const ENTERPRISE_VALUE = 'TRUE';
const APP_KEY = 'cnr-edgeai-studio-app-key';
const MOBILEAPP_KEY = 'MOBILEAPP';
const TRUE = 'TRUE';

const setAsGuest = () => {
  localStorage.setItem(GUEST_KEY, GUEST_VALUE);
};

const setAsEnterprise = () => {
  localStorage.setItem(ENTERPRISE_KEY, ENTERPRISE_VALUE);
};

const setAppKey = key => {
  localStorage.setItem(APP_KEY, key);
};

const getAppKey = () => localStorage.getItem(APP_KEY);

const checkIsGuest = () => {
  const stored = localStorage.getItem(GUEST_KEY);
  if (stored) {
    return stored === GUEST_VALUE;
  }
  return false;
};

const setMobileAppMode = isMobileAppMode => {
  if (isMobileAppMode) localStorage.setItem(MOBILEAPP_KEY, TRUE);
  else localStorage.removeItem(MOBILEAPP_KEY);
};

const isMobileAppMode = () => {
  const stored = localStorage.getItem(MOBILEAPP_KEY);
  return stored === TRUE;
};

const checkIsEnterprise = () => {
  const stored = localStorage.getItem(ENTERPRISE_KEY);
  if (stored) {
    return stored === ENTERPRISE_VALUE;
  }
  return false;
};

const clearAsGuest = () => {
  localStorage.removeItem(GUEST_KEY);
};

const clearAsEnterprise = () => {
  localStorage.removeItem(ENTERPRISE_KEY);
};

const clearAppKey = () => {
  localStorage.removeItem(APP_KEY);
};

export {
  setAsGuest,
  setAsEnterprise,
  checkIsGuest,
  checkIsEnterprise,
  clearAsGuest,
  clearAsEnterprise,
  setAppKey,
  clearAppKey,
  getAppKey,
  setMobileAppMode,
  isMobileAppMode,
};
