const Chartist = require('chartist');

// variables used to create animation on charts
const delays = 80;
const durations = 500;

const chartConfiguration = {
  options: {
    showPoint: false,
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    axisX: {
      // We can disable the grid for this axis
      showGrid: false,
    },
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    showArea: true,
    // TODO: high must be fixed for CPU but not memory
    // high: 101, // Use 101 because we want to show 100
    low: 0,
  },
  // for animation
  animation: {
    draw(data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === 'point') {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease',
          },
        });
      }
    },
  },
};

module.exports = {
  chartConfiguration,
};
