import React from 'react';
// @material-ui/icons
import Update from '@material-ui/icons/Update';
// core components
import GridItem from './Grid/GridItem';
import Card from './Card/Card';
import CardHeader from './Card/CardHeader';
import CardIcon from './Card/CardIcon';
import CardFooter from './Card/CardFooter';

const Grids = ({
  md, classes, label, data, color, children, ...rest
}) => (
  <GridItem xs={12} sm={12} md={md}>
    <Card
    // style={{ marginBottom: 0 }}
    >
      <CardHeader {...rest} color={color} stats icon>
        <CardIcon color={color}>
          {children}
        </CardIcon>
        <p className={classes.cardCategory}>{label}</p>
        <h3 className={classes.cardTitle}>
          {data}
        </h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          <Update />
          Just updated
        </div>
      </CardFooter>
    </Card>
  </GridItem>
);

export default Grids;
