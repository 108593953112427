/* eslint no-console: ["error", { allow: ["debug", "info"] }] */

/*
 *  webrtc-adapter is needed for Safari connectivity
 *  Please keep the import in place as it is.
 */
import adapter from 'webrtc-adapter';
import uuid from 'uuid';

/**
  * Interface with WebRTC-streamer API
  * @constructor
  * @param {string} serverUrl -  url of webrtc-streamer (default is current location)
  * @param {string} videoUrl - RTSP Url for video stream
  * @param {string} audioUrl - RTSP Url for audio stream
  * @param {string} options - RTSP options
 */
function WebRTCStreamerClient(serverUrl, videoUrl, setPc, setMessage, onDisconnect, audioUrl = undefined) {
  this.serverUrl = serverUrl;
  this.videoUrl = videoUrl;
  this.audioUrl = audioUrl;
  this.peerId = uuid.v4();
  this.setPc = setPc;
  this.setMessage = setMessage;
  this.onDisconnect = onDisconnect;
  this.pc = null;
  this.options = 'rtptransport=tcp&timeout=60';

  this.mediaConstraints = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
  };

  this.iceServers = null;
  this.earlyCandidates = [];
}

WebRTCStreamerClient.prototype.handleHttpErrors = function (response) {
  if (!response.ok) {
    this.onDisconnect();
    this.setMessage(response.statusText);
    throw Error(response.statusText);
  }
  return response;
};

/**
 * Connect a WebRTC Stream to videoElement
 * @param {string} videourl - id of WebRTC video stream
 * @param {string} audiourl - id of WebRTC audio stream
 * @param {string} options -  options of WebRTC call
 * @param {string} stream  -  local stream to send
*/
WebRTCStreamerClient.prototype.connect = function () {
  this.disconnect();

  // getIceServers is not already received
  if (!this.iceServers) {
    console.info('[RTSP Streamer] Get IceServers');

    fetch(this.getApiUrl('ice-server'))
      .then(this.handleHttpErrors)
      .then(response => response.json())
      .then(response => this.onReceiveGetIceServers.call(this, response))
      .catch(error => this.onError('getIceServers ', error));
  } else {
    this.onReceiveGetIceServers(this.iceServers);
  }
};

/**
 * Disconnect a WebRTC Stream and clear videoElement source
*/
WebRTCStreamerClient.prototype.disconnect = function () {
  if (this.pc) {
    fetch(this.getApiUrl('hang-up'))
      .then(this.handleHttpErrors)
      .catch(error => this.onError('hangup ', error));

    try {
      this.pc.close();
    }
    catch (e) {
      console.info('[RTSP Streamer] Failure close peer connection: ', e);
    }
    this.pc = null;
  }
};

/*
* GetIceServers callback
*/
WebRTCStreamerClient.prototype.onReceiveGetIceServers = async function (iceServers) {
  this.iceServers = iceServers;
  this.pcConfig = iceServers;

  try {
    this.createPeerConnection();

    const callUrl = this.getApiUrl('call');
    // clear early candidates
    this.earlyCandidates.length = 0;

    // create Offer
    const bind = this;
    this.pc.createOffer(this.mediaConstraints)
      .then(sessionDescription => {
        // sessionDescription.sdp = sessionDescription.sdp.replace('127.0.0.1', 'e62f-176-254-118-227.eu.ngrok.io');
        console.info('[RTSP Streamer] Create offer: ', JSON.stringify(sessionDescription));
        bind.pc.setLocalDescription(sessionDescription)
          .then(() => {
            fetch(callUrl, {
              method: 'POST',
              body: JSON.stringify(sessionDescription),
            })
              .then(bind.handleHttpErrors)
              .then(response => response.json())
              .catch(error => bind.onError('call '.concat(error)))
              .then(response => bind.onReceiveCall.call(bind, response))
              .catch(error => bind.onError('call '.concat(error)));
          })
          .catch(error => {
            console.info('[RTSP Streamer] setLocalDescription error:', JSON.stringify(error));
          });
      })
      .catch(error => alert('Create offer error:', JSON.stringify(error)));
  } catch (e) {
    this.disconnect();
    alert('connect error: ', e);
  }
};


WebRTCStreamerClient.prototype.getIceCandidate = function () {
  const bind = this;
  fetch(this.getApiUrl('get-ice-cand'))
    .then(this.handleHttpErrors)
    .then(response => (response.json()))
    .then(response => this.onReceiveCandidate.call(this, response))
    .catch(error => bind.onError('getIceCandidate ', error));
};

/*
* create RTCPeerConnection
*/
WebRTCStreamerClient.prototype.createPeerConnection = function () {
  console.info('[RTSP Streamer] createPeerConnection  config: ', JSON.stringify(this.pcConfig));
  this.pc = new RTCPeerConnection(this.pcConfig);
  this.pc.peerid = this.peerId;

  const bind = this;
  this.pc.onicecandidate = function (evt) {
    bind.onIceCandidate.call(bind, evt);
  };

  this.pc.ontrack = function (evt) {
    bind.onAddStream.call(bind, evt);
  };

  this.pc.oniceconnectionstatechange = function (event) {
    console.info(event);
    console.info('[RTSP Streamer] On Ice Connection State Change: ', bind.pc.iceConnectionState);

    switch (bind.pc.iceConnectionState) {
      case 'connected':
        // The connection has become fully connected
        bind.setPc(bind.pc);
        bind.setMessage('Connected.');
        break;
      case 'disconnected':
        bind.setMessage('Disconnected.');
        break;
      case 'failed':
        // One or more transports has terminated unexpectedly or in an error
        bind.setMessage('Connection failed.');
        break;
      case 'closed':
        // The connection has been closed
        break;
    }
  };

  this.pc.ondatachannel = function (evt) {
    console.info('[RTSP Streamer] remote datachannel created:', JSON.stringify(evt));

    evt.channel.onopen = function () {
      console.info('[RTSP Streamer] remote datachannel open');
      this.send('remote channel openned');
    };
    evt.channel.onmessage = function (event) {
      console.info('[RTSP Streamer] remote datachannel recv:', JSON.stringify(event.data));
    };
  };

  this.pc.onicegatheringstatechange = function () {
    if (bind.pc.iceGatheringState === 'complete') {
      const recvs = bind.pc.getReceivers();

      recvs.forEach(recv => {
        if (recv.track && recv.track.kind === 'video') {
          console.info('[RTSP Streamer] codecs: ', JSON.stringify(recv.getParameters().codecs));
        }
      });
    }
  };

  try {
    const dataChannel = this.pc.createDataChannel('ClientDataChannel');
    dataChannel.onopen = function () {
      console.info('[RTSP Streamer] local datachannel open');
      this.send('local channel openned');
    };
    dataChannel.onmessage = function (evt) {
      console.info('[RTSP Streamer] local datachannel recv:', JSON.stringify(evt.data));
    };
  } catch (e) {
    console.info('[RTSP Streamer] Cannor create datachannel error: ', e);
  }

  console.info('[RTSP Streamer] Created RTCPeerConnnection with config: ', JSON.stringify(this.pcConfig));
  return this.pc;
};


/*
* RTCPeerConnection IceCandidate callback
*/
WebRTCStreamerClient.prototype.onIceCandidate = function (event) {
  if (event.candidate) {
    if (this.pc.currentRemoteDescription) {
      console.info('[RTSP Streamer] Has remote desc: adding candidate to streamer');
      this.addIceCandidate(this.pc.peerid, event.candidate);
    } else {
      console.info('[RTSP Streamer] Remote desc to be added: adding candidate to pending');
      this.earlyCandidates.push(event.candidate);
    }
  }
  else {
    console.info('[RTSP Streamer] End of candidates.');
  }
};

WebRTCStreamerClient.prototype.addIceCandidate = function (peerid, candidate) {
  fetch(this.getApiUrl('add-ice-cand'), { method: 'POST', body: JSON.stringify(candidate) })
    .then(this.handleHttpErrors)
    .then(response => response.json())
    .then(response => console.info('[RTSP Streamer] addIceCandidate ok:', response))
    .catch(error => this.onError('addIceCandidate ', error));
};

/*
* RTCPeerConnection AddTrack callback
*/
WebRTCStreamerClient.prototype.onAddStream = function (event) {
  console.info('[RTSP Streamer] after adding remote description');
  console.info('[RTSP Streamer] Remote track added:', JSON.stringify(event));
};

/*
* AJAX /call callback
*/
WebRTCStreamerClient.prototype.onReceiveCall = function (dataJson) {
  const bind = this;
  console.info('[RTSP Streamer] Remote Answer: ', JSON.stringify(dataJson));
  const descr = new RTCSessionDescription(dataJson);
  console.info('[RTSP Streamer] Before set remote description');
  this.pc.setRemoteDescription(descr)
    .then(() => {
      console.info('[RTSP Streamer] setRemoteDescription ok');
      console.info('[RTSP Streamer] Number of pending candidates: ', bind.earlyCandidates.length);
      while (bind.earlyCandidates.length) {
        const candidate = bind.earlyCandidates.shift();
        bind.addIceCandidate.call(bind, bind.pc.peerid, candidate);
      }

      bind.getIceCandidate.call(bind);
    })
    .catch(error => console.info('[RTSP Streamer] setRemoteDescription error:', JSON.stringify(error)));
};

/*
* AJAX /getIceCandidate callback
*/
WebRTCStreamerClient.prototype.onReceiveCandidate = function (iceCandidates) {
  console.info('[RTSP Streamer] candidate: ', JSON.stringify(iceCandidates));
  if (iceCandidates) {
    for (const iceCandidate of iceCandidates) {
      console.info('[RTSP Streamer] candidate: ', JSON.stringify(iceCandidate));
      iceCandidate.candidate = iceCandidate.candidate.replace('172.17.0.6 3478', '127.0.0.1 3478');

      const candidate = new RTCIceCandidate(iceCandidate);

      console.info('[RTSP Streamer] Adding ICE candidate :', JSON.stringify(candidate));
      this.pc.addIceCandidate(candidate)
        .then(() => console.info('[RTSP Streamer] addIceCandidate OK'))
        .catch(error => console.info('[RTSP Streamer] addIceCandidate error:', JSON.stringify(error)));
    }
  }
};

/*
* AJAX callback for Error
*/
WebRTCStreamerClient.prototype.onError = function (status) {
  console.info('[RTSP Streamer] onError:', status);
};

/*
* Actions
* 1. ice-server
* 2. call
* 3. hang-up
* 4. get-ice-cand
* 5. add-ice-cand
*/
WebRTCStreamerClient.prototype.getApiUrl = function (action) {
  // this.serverUrl = 'https://e62f-176-254-118-227.eu.ngrok.io/rtsp-streamer';
  this.serverUrl = '/rtsp-streamer';
  // if (!this.serverUrl.includes('localhost')) {
  //   this.serverUrl = this.serverUrl.concat('/rtsp-streamer');
  // } else {
  //   this.serverUrl = 'http://localhost:3000/rtsp-streamer';
  // }

  let url;
  switch (action) {
    case 'ice-server':
      return this.serverUrl.concat('/api/getIceServers');
    case 'call':
      url = this.serverUrl.concat('/api/call?peerid=', this.peerId, '&url=', encodeURIComponent(this.videoUrl));
      if (this.audioUrl && this.audioUrl !== null) {
        url = url.concat('&audiourl=', encodeURIComponent(this.audioUrl));
      }
      if (this.options && this.options !== null) {
        url = url.concat('&options=', encodeURIComponent(this.options));
      }
      return url;
    case 'hang-up':
      return this.serverUrl.concat('/api/hangup?peerid=', this.peerId);
    case 'get-ice-cand':
      return this.serverUrl.concat('/api/getIceCandidate?peerid=', this.peerId);
    case 'add-ice-cand':
      return this.serverUrl.concat('/api/addIceCandidate?peerid=', this.peerId);
    default:
      return '';
  }
};

export default WebRTCStreamerClient;
