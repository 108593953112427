import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Model2Form from './Model2Form';

const styles = theme => ({
  content: {
    // padding: theme.spacing(3),
    height: 'calc(100vh - 64px)',
    backgroundColor: '#424242',
    width: '100%',
  },
});

const Model2Main = props => {
  const { classes, confirm, alert } = props;

  return (
    <React.Fragment>
      <div className={clsx(classes.content)}>
        <Model2Form confirm={confirm} alert={alert} />
      </div>
    </React.Fragment>
  );
};

export default connect()(
  withStyles(styles)(Model2Main),
);
