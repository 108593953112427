import Promise, { reject } from 'bluebird';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import DatasetIcon from '@material-ui/icons/FormatListNumbered';
import ResetIcon from '@material-ui/icons/Replay';
import Dropzone from 'react-dropzone';
import JSZip from 'jszip';
import fetch from 'node-fetch';

import edgeai from 'edgeai';
import Basic from './Basic';
import TouchableIconButton from './TouchableIconButton';
import { getEndpointUrl } from '../Components/EdgeAiAppInterface';
import { efetch } from '../Components/ApiServerInterface';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    margin: theme.spacing(-1) / 2,
  },
  button: {
    // TODO: this is to avoid flashing
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
});

function ModelLoader(props) {
  const {
    classes,
    id,
    metadata,
    onMetadataChange,
    sendMessage,
    elements,
    confirm,
    alert,
    onTouchConnecting,
  } = props;
  const api = useRef();
  const [apiRef, setApiRef] = useState(null);
  const [image, setImage] = useState(metadata?.image);
  const [saveAsPng, setSaveAsPng] = useState(true);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    onMetadataChange?.({ image });
  }, [image]);

  useEffect(() => {
    // assume is zip
    (async () => {
      if (files?.[0]) {
        // debugger; // eslint-disable-line no-debugger
        const context = await edgeai.init({ hostname: '127.0.0.1', port: 8090 });
        const zip = new JSZip();
        let manifest = null;

        const content = await zip.loadAsync(files[0].split(',')[1], { base64: true });
        await Promise.map(Object.keys(content.files), async filename => {
          if (filename.startsWith('__MACOSX')) return;
          if (filename === 'manifest.json') {
            manifest = JSON.parse(await zip.file(filename).async('string'));
          }

          const fileContent = await zip.file(filename).async('nodebuffer');

          const fullPath = `/model/${filename}`;
          // try { await edgeai.removeFile(context, fullPath); }
          // catch (e) {
          //   console.error(e);
          // }

          const formData = new FormData();
          formData.append('files[]', new Blob([fileContent]), filename);
          formData.append('path', '/model');

          const url = !window.edgeAiStudioConfig.neurals
            ? '/neural/file/upload'
            : (() => {
              const { ip, port } = window.edgeAiStudioConfig.neurals[0];
              return `http://${ip}:${port}/file/upload`;
            })();

          await efetch(url, {
            method: 'POST',
            body: formData,
            mode: 'no-cors',
          });
        });

        try {
          const res = await fetch(`${getEndpointUrl()}/model/${manifest.model}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(manifest),
          });
          const json = await res.json();
          if (json.success) {
            alert('The model is loaded successfully.', 'success');
            return;
          }
          alert('The model cannot be loaded.  Please try again with other model files.', 'error');
        }
        catch (e) {
          console.error(e);
          alert('The model cannot be loaded.  Please try again with other model files.', 'error');
        }
      }
    })();
  }, [files]);

  const addFiles = (prevFiles, file) => ([
    ...prevFiles,
    file,
  ]);

  const requestSnapshot = useCallback(async (data, options) => {
    console.debug('imagefile request-snapshot');
    sendMessage(options.senderId, 'reply-snapshot', {
      image,
      ...data && data.metadata ? { metadata: data.metadata } : {},
    });
  }, [image]);

  const apis = {
    'request-snapshot': requestSnapshot,
  };

  const handleDrop = async acceptedFiles => {
    alert('The model is now being uploaded.', 'info');
    await Promise.map(Array(acceptedFiles.length).fill(), (_, i) => new Promise(resolve => {
      const file = acceptedFiles.shift();
      const reader = new FileReader();

      reader.onabort = () => console.error('File reading was aborted');
      reader.onerror = () => console.error('File reading has failed');
      reader.onload = () => {
        setFiles(prevFiles => addFiles(prevFiles, reader.result));
        resolve();
      };
      reader.readAsDataURL(file);
    }), { concurrency: 1 });
  };

  const handleClickReset = () => {
    setImage(null);
  };

  const renderContent = () => (
    <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)} multiple={true}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps(image
            ? { onClick: event => event.stopPropagation() }
            : undefined)}>
            <input {...getInputProps()} />
            {image
              ? <div style={{ position: 'relative', textAlign: 'left' }}>
                <img src={image} style={{ minWidth: 320 }} />
              </div>
              : <Typography style={{
                backgroundColor: 'darkgray',
                color: 'black',
                textAlign: 'center',
                padding: 8 * 2, // TODO: Use theme.spacing
              }}>Drag 'n' drop a file here, or click to select a file</Typography>
            }
          </div>
        </section>
      )}
    </Dropzone>
  );

  const renderButtons = () => (
    <>
      {/* <TouchableIconButton onClick={handleClickReset}>
        <ResetIcon />
      </TouchableIconButton> */}
    </>
  );

  const renderAdvanced = () => (
    <FormGroup>
      {/* <FormControlLabel
        control={<Checkbox checked={saveAsPng}
          onChange={e => setSaveAsPng(e.target.checked)}
        />}
        label="Save as PNG"
        className={classes.formControlLabel}
      /> */}
    </FormGroup>
  );

  const onApiLoad = ref => {
    setApiRef(ref);
    props.onApiLoad?.(ref);
  };

  return (
    <Basic
      doNotProvideMoreButton
      label="ABC"
      apiRef={api}
      id={id}
      elements={elements}
      label={props.label}
      // subheader="abc"
      // description={description}
      color="red"
      icon={<DatasetIcon />}
      renderContent={renderContent}
      renderButtons={renderButtons}
      renderAdvanced={renderAdvanced}
      onDelete={props.onDelete}
      onApiLoad={onApiLoad}
      api={apis}
      onTouchConnecting={onTouchConnecting}
      readOnly={props.readOnly}
    />
  );
}

ModelLoader.propTypes = {
  // classes: PropTypes.object.isRequired,
  // onDelete: PropTypes.func.isRequired,
  // source: PropTypes.object,
};

export default withStyles(styles)(ModelLoader);
